import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router"
import { Layout, Table, Input, Tooltip, Select } from "antd";
import { Header, FunctionTitle, CommonMessage, Footer, ContentEx as Content } from "../../core/CorePageContents";
import * as Module from "../../../modules/prime/workerAttendance/workerAttendanceModule";
import { CriteriaForm, AuthButton, MonthPicker, Checkbox } from "../../core/CoreForm";
import styles from "./WorkerAttendanceSummary.module.css";
import { Store } from "antd/lib/form/interface";
import { initMessages } from "../../../modules/core/commonMessageModule";
import { SortOrder } from "antd/lib/table/interface";
import * as Model from "../../../modules/prime/workerAttendance/workerAttendanceModel";
import * as CONSTANTS from "../../../constants/constants";
import * as messagesUtils from "../../../utils/messagesUtils";
import * as formatUtils from "../../../utils/formatUtils";
import * as Utils from '../../../utils/utils';
import { Moment } from "moment";
import { initApiResult, storedApiResult } from "../../../modules/core/bffApiModule";

const { Option } = Select;

interface titleInterface {
  title: string;
}

export const WorkersAttendanceSummaryList = (props: titleInterface) => {

  const dispatch = useDispatch();
  let dataList = useSelector(Module.storedDataList);
  let managementGroupList = useSelector(Module.storedManagementGroupList);
  let total = useSelector(Module.storedTotal);
  let criteria = useSelector(Module.storedCriteria);
  let history = useHistory();
  const apiResult = useSelector(storedApiResult);

  useEffect(() => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    dispatch(Module.getDataListAsync(criteria));
  },
    [criteria]
  );

  const moveToDetail = (record: Model.WorkRecordWorkerSummaryRow) => {
    dispatch(Module.prepareMoveToDetail(record));
    history.push(CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_DETAIL);
  }

  /**
  * 一次協力会社名の一覧の整形
  * @param text
  * @param row
  */
  const renderfirstPartnerCompanyName = (text: string, row: Model.WorkRecordWorkerSummaryRow) => {

    let firstPartnerCompanyNames: Array<any> = [];
    let promptTextList: Array<any> = [];

    let index = 0;
    row.firstPartnerCompanyList.forEach((partnerRow: Model.FirstPartnerCompanyRow) => {
      if (row.firstPartnerCompanyList.length >= 4 && index === 2) {
        // 一次協力会社が4つ以上ある場合、3件目以降は「...」で表示する
        firstPartnerCompanyNames.push(<div className={styles.columnAlignLeft}>...</div>);
      } else if (row.firstPartnerCompanyList.length < 4 || index < 3) {
        firstPartnerCompanyNames.push(
          <div key={row.belongCompanyId + index} className={styles.columnAlignLeft}>{partnerRow.firstPartnerCompanyName}</div>
        );
      }

      // Tooltipに表示するメッセージの作成
      promptTextList.push(<div>{partnerRow.firstPartnerCompanyName}</div>);
      index++;
    });

    if (row.firstPartnerCompanyList.length >= 4) {
      return {
        children: (<Tooltip placement="topLeft" title={promptTextList}><div>{firstPartnerCompanyNames}</div></Tooltip>)
      }
    } else {
      return {
        children: firstPartnerCompanyNames
      }
    }
  };

  const columns = [
    {
      title: "管理グループ",
      key: "1",
      width: 200,
      align: "center" as "center",
      sortOrder: (criteria.sortItem !== "1") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "managementGroupName",
    },
    {
      title: (
        <>
          <span>全プロジェクト</span><br />
          <span>一次協力会社</span><br />
        </>
      ),
      key: "6",
      width: 400,
      sortOrder: (criteria.sortItem !== "6") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
      render: renderfirstPartnerCompanyName
    },
    {
      title: "所属会社",
      key: "2",
      width: 200,
      dataIndex: "belongCompanyName",
      sortOrder: (criteria.sortItem !== "2") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
    },
    {
      title: "元請認定番号",
      key: "3",
      width: 200,
      dataIndex: "primeCertificationNo",
      sortOrder: (criteria.sortItem !== "3") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
    },
    {
      title: "氏名",
      key: "4",
      width: 200,
      dataIndex: "name",
      sortOrder: (criteria.sortItem !== "4") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordWorkerSummaryRow) => {
        return (
          <>
            {<div >{row.name}</div>}
          </>
        )
      }
    },
    {
      title: "勤怠年月",
      key: "5",
      width: 200,
      sortOrder: (criteria.sortItem !== "5") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordWorkerSummaryRow) => {
        return (
          <>
            {<div >{formatUtils.formatMonthChar(row.attendanceYearMonth)}</div>}
          </>
        )
      }
    },
  ];

  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: Model.WorkRecordWorkerSummaryCriteria = {
      managementGroupId: criteria.managementGroupId,
      belongCompanyName: criteria.belongCompanyName,
      belongCompanyNameKana: criteria.belongCompanyNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      lastName: criteria.lastName,
      firstName: criteria.firstName,
      lastNameKana: criteria.lastNameKana,
      firstNameKana: criteria.firstNameKana,
      fromYYYYMM: criteria.fromYYYYMM,
      toYYYYMM: criteria.toYYYYMM,
      hasWorkTime: criteria.hasWorkTime,
      sortItem: criteria.sortItem,
      sortOrder: criteria.sortOrder,
      offset: criteria.offset,
      limit: criteria.limit,
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(Module.setCriteria(newCriteria));
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PRIME_WORKER_SUMMARY === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (criteria.offset > 0 && total > 0 && (dataList.length === 0 || dataList[0].key === '')) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(total, criteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }


  // 検索ボタン押下時アクション
  const pushSerchButton = (value: Store) => {

    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "managementGroupId", value: value.managementGroupId }
      , { key: "belongCompanyName", value: value.belongCompanyName }
      , { key: "belongCompanyNameKana", value: value.belongCompanyNameKana }
      , { key: "firstPartnerCompanyName", value: value.firstPartnerCompanyName }
      , { key: "firstPartnerCompanyNameKana", value: value.firstPartnerCompanyNameKana }
      , { key: "lastName", value: value.lastName }
      , { key: "firstName", value: value.firstName }
      , { key: "lastNameKana", value: value.lastNameKana }
      , { key: "firstNameKana", value: value.firstNameKana }
      , { key: "fromYYYYMM", value: value.fromYYYYMM === null ? "" : formatUtils.formatMonthNonSlash(value.fromYYYYMM) }
      , { key: "toYYYYMM", value: value.toYYYYMM === null ? "" : formatUtils.formatMonthNonSlash(value.toYYYYMM) }
      , { key: "hasWorkTime", value: value.hasWorkTime === true ? "1" : "0" }
      , { key: "offset", value: 0 }
    ]);
  }

  // Fromの日付を計算
  const setFromDate = () => {
    if (criteria.fromYYYYMM === "") {
      return null;
    }

    return formatUtils.getMonthMomentOf(formatUtils.formatMonthSlash(criteria.fromYYYYMM));
  }

  // toの日付を計算
  const setToDate = () => {
    if (criteria.toYYYYMM === "") {
      return null;
    }

    return formatUtils.getMonthMomentOf(formatUtils.formatMonthSlash(criteria.toYYYYMM));
  }

  // すべての権限保有者の管理グループの重複排除したもの
  const CriteriamanagementGroup = () => {
    let list: Array<Model.ManagementGroupListRow> = [];
    let hoge: boolean = false;
    list.push({
      managementGroupId: "",
      managementGroupName: "(すべて)"
    })
    managementGroupList.forEach((dataRow: Model.ManagementGroupListRow) => {
      list.forEach((row: Model.ManagementGroupListRow) => {
        if (dataRow.managementGroupId === row.managementGroupId) {
          hoge = true;
        }
      })
      if (!hoge) {
        list.push(dataRow);
      }
      hoge = false;
    })
    return list
  }


  // 検索フォーム
  const AttendanceCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={pushSerchButton}
        initialValues={{
          "managementGroupId": criteria.managementGroupId
          , "belongCompanyName": criteria.belongCompanyName
          , "belongCompanyNameKana": criteria.belongCompanyNameKana
          , "firstPartnerCompanyName": criteria.firstPartnerCompanyName
          , "firstPartnerCompanyNameKana": criteria.firstPartnerCompanyNameKana
          , "lastName": criteria.lastName
          , "firstName": criteria.firstName
          , "lastNameKana": criteria.lastNameKana
          , "firstNameKana": criteria.firstNameKana
          , "projectName": criteria.projectName
          , "fromYYYYMM": setFromDate()
          , "toYYYYMM": setToDate()
          , "hasWorkTime": criteria.hasWorkTime === "1" ? true : false
        }}
      >
        <div style={{ float: "right" }}>
          <div className={styles.criteriaSearchFieldLeftBox}>
            <CriteriaForm.Item
              name="managementGroupId">
              <Select style={{ marginRight: "5px", textAlign: "left" }}>
                {CriteriamanagementGroup().map((rec: Model.ManagementGroupListRow) => (
                  <Option key={rec.managementGroupId} value={rec.managementGroupId}>{rec.managementGroupName}</Option>
                ))}
              </Select>
            </CriteriaForm.Item>
            <CriteriaForm.Item name="belongCompanyName">
              <Input type="text"
                placeholder="所属会社名"
                maxLength={CONSTANTS.PROJECT_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="belongCompanyNameKana">
              <Input type="text"
                placeholder="所属会社名カナ"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="firstPartnerCompanyName">
              <Input type="text"
                placeholder="一次協力会社名"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="firstPartnerCompanyNameKana">
              <Input type="text"
                placeholder="一次協力会社名カナ"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="lastName">
              <Input type="text"
                placeholder="氏名（姓）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="firstName">
              <Input type="text"
                placeholder="氏名（名）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="lastNameKana">
              <Input type="text"
                placeholder="氏名カナ（姓）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="firstNameKana">
              <Input type="text"
                placeholder="氏名カナ（名）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
          </div>
          <div className={styles.duration}>
            <div >
              <CriteriaForm.Item name="fromYYYYMM"
                rules={[
                  ({ getFieldValue, validateFields }) => ({
                    validator(rule, value) {
                      if (getFieldValue("toYYYYMM") !== null) {
                        validateFields(["toYYYYMM"]);
                      }
                      return Promise.resolve();
                    }
                  })
                ]}>
                <MonthPicker placeholder="勤怠年月(From)" format="YYYY/MM"
                  style={{ width: "140px" }} />
              </CriteriaForm.Item>
              <div className={styles.durationSpan}>～</div>
              <CriteriaForm.Item name="toYYYYMM"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value === null || getFieldValue("fromYYYYMM") === null) {
                        return Promise.resolve();
                      }
                      if (getFieldValue("fromYYYYMM").isAfter(value, 'month')) {
                        return Promise.reject(messagesUtils.getMessage("ERROR_FROM_TO_DATE_RANGE", ["勤怠年月(From)", "勤怠年月(To)"]));
                      }

                      // To - From が半年以内でなければエラー
                      if ((value as Moment).diff(getFieldValue("fromYYYYMM"), "month") > 5) {
                        return Promise.reject(messagesUtils.getMessage("ERROR_FROM_TO_DATE_PERIOD"));
                      }
                      return Promise.resolve();
                    }
                  })

                ]}>
                <MonthPicker placeholder="勤怠年月(To)" format="YYYY/MM"
                  style={{ width: "140px" }} />
              </CriteriaForm.Item>
            </div>
            <div className={styles.calendarAnnotation}>※未入力の場合、今月と前月の2ヵ月分の検索となります</div>
          </div>
          <div className={styles.duration}>
            <CriteriaForm.Item name="hasWorkTime" valuePropName="checked">
              <Checkbox>
                各月の合計労働時間が0時間の作業員を除く
              </Checkbox>
            </CriteriaForm.Item>
          </div>

          <div className={styles.criteriaSearchFieldLeft}>
            <AuthButton
              name={"検索"}
              shape={"round"}
              htmlType="submit"
            />
          </div>
        </div>
      </CriteriaForm>
    )
  }

  /** 一覧部 */
  const AttendanceContent = () => {
    return (
      total > 0 && dataList.length > 0 && dataList[0].key !== '' ?
        <Table
          size="small"
          scroll={{ x: 1000 }}
          columns={columns}
          dataSource={dataList}
          pagination={{
            total: total
            , defaultPageSize: 10
            , current: criteria.offset / criteria.limit + 1
            , pageSize: criteria.limit
            , pageSizeOptions: ["5", "10", "20", "50", "100"]
            , showSizeChanger: true
            , showTotal: (total, range) => `${total}件中、${criteria.offset + 1}件目から${total <= criteria.offset + criteria.limit ? total : criteria.offset + criteria.limit}件目を表示`
            , itemRender: pageItemRender
            , locale: { items_per_page: "件/ページ" }
          }}
          onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                moveToDetail(record)
              }
            };
          }}
        >
        </Table>
        : <></>
    );
  }

  /**
 * ページングボタンを描画
 * @param page 
 * @param type 
 * @param originalElement 
 */
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === "prev") {
      return <AuthButton name="＜" size="small" />;
    }
    if (type === "next") {
      return <AuthButton name="＞" size="small" />;
    }
    return originalElement
  }

  /**
 * テーブル検索条件変更時イベント（ページ、表示件数）
 * @param pagination 
 * @param filters 
 */
  const handleTableChange = (pagination: any, sorter: any) => {

    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let offsetReset: boolean = false;

    let order = "";
    if (sorter.order === "ascend") {
      order = "0";
    } else if (sorter.order === "descend") {
      order = "1";
    }
    let sortItem = sorter.columnKey
    if (typeof sorter.column === "undefined") {
      sortItem = "";
    }

    if (criteria.limit !== pagination.pageSize ||
      criteria.sortItem !== sortItem ||
      criteria.sortOrder !== order) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "sortItem", value: sortItem }
      , { key: "sortOrder", value: order }
      , { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
    ])
  }

  return (
    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <AttendanceCriteria />
        <CommonMessage searchNoDataName="作業員" />
        <AttendanceContent />
      </Content>
      <Footer />
    </Layout>
  );
}
