// コード定義の型定義
interface baseInterface {
  [propertyName: string]: any
}

/** メッセージ一覧 */
export const MESSAGE_LIST: baseInterface = {
  EA9999000: '条件に当てはまる%sは０件です。'
  , EA9999400: '不正なパラメータが送信されました。'
  , EA9999409: '他のユーザに更新されています。詳細画面に戻って確認してください。'
  , EA1002001: '入力した独自資格番号は既に登録されています。異なる独自資格番号を入力してください'
  , EA1003001: '保有者が存在する独自資格は削除できません。'
  , EA1003409: '他のユーザに更新されています。一覧画面に戻って確認してください。'
  , EA2003001: '入力した元請認定番号は既に登録されています。異なる元請認定番号を入力してください'
  , EA2003002: '選択した従業員は既に資格保有者として登録されています。'
  , EA2004001: '認定状態を変更する権限がありません。操作が必要な場合は、管理者権限を持つ貴社のご担当者にご相談ください。'
  , EA2005001: '同姓同名・同生年月日の作業員が複数存在します。システム管理者に問い合わせてください。'
  , EA2005002: '検索した作業員は退職しています。'
  , EA2005003: 'データが見つかりませんでした。'
  , EA2006001: '保有資格情報を表示できません。'
  , EA3006001: "作業場を通して合計労働時間は24時間未満となるようにしてください。"
  , EA3003001: "メールの送信に失敗しました。"
  , EA8004001: "データが見つかりませんでした。"
  , EA5000001: "%s権限がないために処理を中止しました。操作が必要な場合は、管理者権限を持つ貴社のご担当者にご相談ください。"
  , EA5000002: "勤怠の締切日を過ぎているため、%sできませんでした。"
  , EA5000003: "%sを行いまいしたが、メールの送信に失敗しました。"
  , EA5000004: "操作中に他のユーザが状態の変更を実施したため、処理を中止しました。最新の勤怠情報を確認してください。"
  , EA9008001: "選択した協力会社は既に登録済みです。"
  , EA9007001: "プロジェクトをスキルマップサイトの利用対象として設定いただく必要があります。"
  , EA9007002: "スキルマップサイトで勤怠情報を申請いただく必要がある、元請会社から資格認定された作業員が存在しません。"
  , EA5000002_PRIME: "勤怠の処理期限が過ぎています。スキルマップサイトの管理者権限を持つ貴社のスキルマップサイトの管理担当者にご連絡ください。"
  , EA5000002_PRIME_FORCE_APPROVAL: "勤怠の処理期限が変更されています。スキルマップサイトの管理者権限を持つ貴社のスキルマップサイトの管理担当者にご連絡ください。"
  , EA5000002_PARTNER: "勤怠の処理期限が過ぎています。勤怠情報の提出対象となっている現場の元請会社のご担当者にご連絡ください。"

  , ERROR_VALIDATION: "入力エラーが発生しました。"
  , ERROR_REQUIRED_INPUT: "%sを入力してください。"
  , ERROR_REQUIRED_SELECT: "%sを選択してください。"
  , ERROR_MAX_LENGTH: "%sは%s文字以内で入力してください。"
  , ERROR_LENGTH: "%sは%s文字で入力してください。"
  , ERROR_ALPHANUMERIC: "%sは半角英数字で入力してください。"
  , ERROR_ALLOWANCE_DAYS_RANGE: "月手当必要日数は数値(1~31)で入力してください。"
  , ERROR_ALLOWANCE_TIME_RANGE: "手当必要時間は数値(1~24)で入力してください。"
  , ERROR_ALLOWANCE_AMOUNT_RANGE: "手当金額は数値(0~999999)で入力してください。"
  , ERROR_CEILING_DAILY_ALLOWANCE_INSPECTION_DAYS_RANGE: "月手当に切り替わる条件は数値(1~31)で入力してください。"
  , ERROR_CEILING_DAILY_ALLOWANCE_AMOUNT: "月手当に切り替わった際の手当金額の上限は数値(0~999999)で入力してください。"
  , ERROR_DEADLINE_RANGE_START_DATE: "有効期限開始日は有効期限終了日より前の日付を入力してください。"
  , ERROR_DEADLINE_RANGE_END_DATE: "有効期限終了日は有効期限開始日より後の日付を入力してください。"
  , ERROR_SET_MANAGEMENT_GROUP_: "管理グループを設定してください。"
  , ERROR_ATTENDANCE_PROCESS: "%sを行う場合は、%sの勤怠のみ選択してください。"
  , ERROR_INVALID_WORKING_TIME: "入力された労働時間が不正です。"
  , ERROR_FROM_TO_DATE_RANGE: "%sは%s以前の月を設定してください。"
  , ERROR_FROM_TO_DATE_PERIOD: "勤怠年月の期間は6か月以下で選択してください。"
  , ERROR_CERTIFICATION_DATE_RANGE: "%sは%sより後の日付を入力してください"
  , ERROR_CERTIFICATION_DATE: "%sは資格の有効期限内に設定してください。"
  , ERROR_NO_REGISTRATION_QUALIFICATION:"独自資格は1件以上登録してください。"
  , ERROR_DUPLICATE_QUALIFICATION:"同じ資格に対して、重複した認定期間（認定日～認定取消日）は登録できません。"
  , ERROR_DEADLINE_PASSED_PRIME: "勤怠の処理期限が過ぎています。スキルマップサイトの管理者権限を持つ貴社のスキルマップサイトの管理担当者にご連絡ください。"
  , ERROR_DEADLINE_PASSED_PARTNER: "勤怠の処理期限が過ぎています。勤怠情報の提出対象となっている現場の元請会社のご担当者にご連絡ください。"
  , ERROR_CLOSING_DATE_RELATIVE: "%sは%sと同一、または%sより前となるように設定してください。"
  , ERROR_CLOSING_DATE_SETTING: "%sの月、日は両方とも未入力または両方とも入力してください。"
  , ERROR_INCCORECT_PARAMETAER: "入力されたURLが間違っているので勤怠一覧画面に移動しました。"
  , ERROR_CEILING_DAILY_ALLOWANCE_SETTING: "月額に切り替わる条件は、両方とも未入力または両方とも入力してください。"
  , ERROR_TOP_HIERARCHY_LEVEL_RANGE: "次数は数値(1~5)で入力してください。"
  , ERROR_FROM_TO_NUMBER_RANGE: "%sは%s以下の数値を入力してください。"

  , SUCCESS_ADD: "%sを登録しました。"
  , SUCCESS_UPDATE: "%sを更新しました。"
  , SUCCESS_DELETE: "%sを削除しました。"
  , SUCCESS_ATTENDANCE_PROCESS: "%sを行いました。"
  , SUCCESS_ATTENDANCE_SEND_MAIL_PROCESS: "承認依頼メールを送信しました。"

  , CONFIRM_ADD: "%sを登録します。よろしいですか？"
  , CONFIRM_UPDATE: "%sを更新します。よろしいですか？"
  , CONFIRM_DELETE: "%sを削除します。よろしいですか？"
  , CONFIRM_PROCESS: "%sを行います。よろしいですか？"
  , CONFIRM_BACK: "入力した内容をキャンセルします。よろしいですか？"
  , CONFIRM_ATTENDANCE_PROCESS: "選択された作業員の勤怠の%sを行います。よろしいですか？"
  , CONFIRM_ATTENDANCE_SEND_MAIL: "選択されたプロジェクトの勤怠承認者に対して承認依頼メールを送信します。よろしいですか。"
}

