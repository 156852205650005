import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router'
import { Layout, Input, Switch, Form, Select } from 'antd';
import { DetailForm, AuthButton, Radio, DatePicker } from '../../core/CoreForm';
import { Header, ContentEx as Content, Footer, FunctionTitle, CommonMessage, FooterButtonArea } from '../../core/CorePageContents';
import styles from './QualificationEdit.module.css';
import { setMessages, message } from '../../../modules/core/commonMessageModule';
import AuthButtonStyles from '../../core/css/AuthButton.module.css';
import { storedApiResult, initApiResult } from '../../../modules/core/bffApiModule';
import * as Module from '../../../modules/prime/qualification/qualificationModule';
import * as Model from '../../../modules/prime/qualification/qualificationModel';
import * as CONSTANTS from '../../../constants/constants';
import * as constantsUtils from '../../../utils/constantsUtils';
import * as messagesUtils from '../../../utils/messagesUtils';
import * as formatUtils from '../../../utils/formatUtils';
import * as validationRules from '../../../validation/coreRules';
import { initMessages } from '../../../modules/core/commonMessageModule';
import moment from 'moment';
import { isNullOrEmpty } from '../../../utils/utils';
const { TextArea } = Input;

interface titleInterface {
  title: string;
}

export const QualificationEdit = (props: titleInterface) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [form] = Form.useForm();
  const dataRow = useSelector(Module.storedDataRow);
  const apiResult = useSelector(storedApiResult);
  const processType = window.location.pathname === CONSTANTS.PATH_PRIME_QUALIFICATIONS_ADD ? CONSTANTS.PROCESS_TYPE_ADD : CONSTANTS.PROCESS_TYPE_EDIT

  const isNullOrNan = (val: any): boolean => {
    return val == null || isNaN(val);
  }

  useEffect(() => {
    if (dataRow.originalQualificationId === "" && processType === CONSTANTS.PROCESS_TYPE_EDIT) {
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_DETAIL);
    }

    // 更新API実行後、APIエラーメッセージが設定されていなければ画面遷移します。
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod) {

      if (processType === CONSTANTS.PROCESS_TYPE_ADD) {
        // 成功メッセージを設定します。
        const setMessage: message = {
          message: messagesUtils.getMessage("SUCCESS_ADD", ["独自資格"])
          , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
        }
        dispatch(setMessages([setMessage]));
        history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST);
      } else {
        // 成功メッセージを設定します。
        const setMessage: message = {
          message: messagesUtils.getMessage("SUCCESS_UPDATE", ["独自資格"])
          , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
        }
        dispatch(setMessages([setMessage]));
        history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_DETAIL);
      }
    }
  },
    [apiResult]
  );

  /**
   * 画面アクション
   * 
   */
  // 確定ボタン押下時アクション（入力エラー無）
  const onSubmit = (values: any) => {

    const validateResult: boolean = validate(values);
    if (!validateResult) {
      scrollToTop();
      return ;
    }

    let messageCode = "";
    if (processType === CONSTANTS.PROCESS_TYPE_ADD) {
      messageCode = "CONFIRM_ADD";
    } else {
      messageCode = "CONFIRM_UPDATE";
    }
    if (!window.confirm(messagesUtils.getMessage(messageCode, ["独自資格"]))) {
      return;
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());

    // 手当条件の値を指定
    let inspectionTime: number;
    let monthlyInspectionDays: number;
    if (values.allowanceType === CONSTANTS.ALLOWANCE_TYPE.NONE.code) {
      inspectionTime = NaN;
      monthlyInspectionDays = NaN;
    } else {
      if (values.allowanceInspectionType == CONSTANTS.ALLOWANCE_INSPECTION_TYPE.EXIST.code) {
        if (values.allowanceType === CONSTANTS.ALLOWANCE_TYPE.MONTHLY.code) {
          inspectionTime = parseInt(values.allowanceInspectionTime);
          monthlyInspectionDays = parseInt(values.monthlyAllowanceInspectionDays);
        } else {
          inspectionTime = parseInt(values.allowanceInspectionTime);
          monthlyInspectionDays = NaN;
        }
      } else {
        if (values.allowanceType === CONSTANTS.ALLOWANCE_TYPE.MONTHLY.code) {
          inspectionTime = NaN;
          monthlyInspectionDays = 1;
        } else {
          inspectionTime = NaN;
          monthlyInspectionDays = NaN;
        }
      }
    }

    // 日当から月手当に変わる値を指定
    let ceilingDailyAllowanceInspectionDays: number;
    let ceilingDailyAllowanceAmount: number;
    if (values.allowanceType === CONSTANTS.ALLOWANCE_TYPE.DAILY.code) {
      ceilingDailyAllowanceInspectionDays = values.ceilingDailyAllowanceInspectionDays === "" ? NaN : parseInt(values.ceilingDailyAllowanceInspectionDays);
      ceilingDailyAllowanceAmount = values.ceilingDailyAllowanceAmount === "" ? NaN : parseInt(values.ceilingDailyAllowanceAmount);
    } else {
      ceilingDailyAllowanceInspectionDays = NaN;
      ceilingDailyAllowanceAmount = NaN;
    }

    let sendRequest: Model.putDataList.Request = {
      processType: processType
      , originalQualificationId: dataRow.originalQualificationId
      , originalQualificationNo: processType === CONSTANTS.PROCESS_TYPE_ADD ? values.originalQualificationNo : dataRow.originalQualificationNo
      , originalQualificationName: values.originalQualificationName
      , allowanceType: values.allowanceType
      , allowanceInspectionType: values.allowanceInspectionType
      , allowanceInspectionTime: inspectionTime
      , monthlyAllowanceInspectionDays: monthlyInspectionDays
      , allowanceAmount: values.allowanceType === CONSTANTS.ALLOWANCE_TYPE.NONE.code ? NaN : parseInt(values.allowanceAmount)
      , ceilingDailyAllowanceInspectionDays: ceilingDailyAllowanceInspectionDays
      , ceilingDailyAllowanceAmount: ceilingDailyAllowanceAmount
      , deadlineExist: values.deadlineExist === true ? CONSTANTS.EXIST_DEADLINE.EXIST.code : CONSTANTS.EXIST_DEADLINE.NONE.code
      , deadlineStartDate: values.deadlineExist === true ? formatUtils.formatDateHyphen(values.deadlineStartDate) : ""
      , deadlineEndDate: values.deadlineExist === true ? formatUtils.formatDateHyphen(values.deadlineEndDate) : ""
      , levelRangeTo: Number(values.levelRangeTo)
      , remarks1: values.remarks1
      , remarks2: values.remarks2
      , updateDatetime: dataRow.updateDatetime
    }
    dispatch(Module.putDataRowAsync(sendRequest))
  }

  // 確定ボタン押下時アクション（入力エラー有）
  const onSubmitFailed = (errorInfo: any) => {
    if (errorInfo.errorFields.length > 0) {
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_VALIDATION")
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
    }
  }

  /**
   * 上部へスクロールする（エラーメッセージを見せるため）
   */
  const scrollToTop = () => {
    const target = document.getElementById("contenttop");
    if (target !== null) {
      target.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  /**
   * 入力値チェック（相関関係）
   * @returns 
   */
  const validate = (values: any): boolean => {
    let checked: boolean = true;
    let messages: message[] = [];

    if ((isNullOrEmpty(values.ceilingDailyAllowanceInspectionDays) && !isNullOrEmpty(values.ceilingDailyAllowanceAmount))
      ||(!isNullOrEmpty(values.ceilingDailyAllowanceInspectionDays) && isNullOrEmpty(values.ceilingDailyAllowanceAmount))) {
        messages.push({
          message: messagesUtils.getMessage("ERROR_CEILING_DAILY_ALLOWANCE_SETTING")
          , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
        });
      
        checked = false;
      }

      if (!checked) {
        dispatch(setMessages(messages));
      }
  
      return checked;
  }


  // 戻るボタン押下時アクション
  const backClicked = () => {
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_BACK"))) {
      return;
    }
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    if (processType === CONSTANTS.PROCESS_TYPE_ADD) {
      // 登録画面へ遷移します。
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST);
    } else {
      // 詳細画面へ遷移します。
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_DETAIL);
    }
  }

  /**
   * 描画用メソッド
   * 
   */
  // 独自資格番号部品
  const OriginalQualificationNo = () => {
    if (processType === CONSTANTS.PROCESS_TYPE_ADD) {
      return (
        <DetailForm.Item
          name="originalQualificationNo"
          label="独自資格番号"
          validateFirst={true}
          rules={[
            {
              required: true,
              message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["独自資格番号"])
            },
            {
              max: CONSTANTS.QUALIFICATION_NO_MAX_LENGTH,
              message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["独自資格番号", CONSTANTS.QUALIFICATION_NO_MAX_LENGTH.toString()])
            },
            {
              pattern: validationRules.alphanumeric,
              message: messagesUtils.getMessage("ERROR_ALPHANUMERIC", ["独自資格番号"]),
            },
          ]}
        >
          <Input className={styles.originalQualificationNo} maxLength={CONSTANTS.QUALIFICATION_NO_MAX_LENGTH} />
        </DetailForm.Item>
      )
    }
    return (
      <DetailForm.Item label="独自資格番号">
        <span>{dataRow.originalQualificationNo}</span>
      </DetailForm.Item>
    );
  }
  const AllowanceInspectiontypeRadio = () => {
    return (
      <DetailForm.Item
        required={true}
        name="allowanceInspectionType"
        validateFirst={true}
        dependencies={["allowanceType"]}
        rules={[
          {
            required: true,
            message: messagesUtils.getMessage("ERROR_REQUIRED_SELECT", ["手当集計区分"])
          }
        ]}
      >
        <Radio.Group buttonStyle={"solid"}>
          {constantsUtils.getCodeLabelListOf(CONSTANTS.ALLOWANCE_INSPECTION_TYPE).map((rec: any) => (
            <Radio key={rec.code} value={rec.code}>{rec.label}</Radio>
          ))}
        </Radio.Group>
      </DetailForm.Item>
    )
  }
  const AllowanceInspectionTime = () => {
    return (
      <DetailForm.Item
        required={true}
        name="allowanceInspectionTime"
        validateFirst={true}
        dependencies={["allowanceType"]}
        rules={[
          {
            required: true,
            message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["手当必要時間"])
          },
          {
            pattern: validationRules.seisu,
            message: messagesUtils.getMessage("ERROR_ALLOWANCE_TIME_RANGE")
          },
          () => ({
            validator(rule, value) {
              if (value.length > 1 && value.slice(0, 1) === "0") {
                return Promise.reject(messagesUtils.getMessage("ERROR_ALLOWANCE_TIME_RANGE"));
              }
              let input = parseInt(value, 10);
              if (input < CONSTANTS.DAILY_ALLOWANCE_TIME_MIN_RANGE || input > CONSTANTS.DAILY_ALLOWANCE_TIME_MAX_RANGE) {
                return Promise.reject(messagesUtils.getMessage("ERROR_ALLOWANCE_TIME_RANGE"));
              }
              return Promise.resolve();
            },
          })
        ]}
      >
        <Input className={styles.allowanceTime} addonBefore="１日" addonAfter="時間以上" maxLength={CONSTANTS.DAILY_ALLOWANCE_TIME_MAX_LENGTH} />
      </DetailForm.Item>
    );
  }

  // 月手当必要日数部品
  const MonthlyAllowanceInspectionDays = () => {
    return (
      <DetailForm.Item
        required={true}
        name="monthlyAllowanceInspectionDays"
        validateFirst={true}
        dependencies={["allowanceType"]}
        rules={[
          {
            required: true,
            message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["月手当必要日数"])
          },
          {
            pattern: validationRules.seisu,
            message: messagesUtils.getMessage("ERROR_ALLOWANCE_DAYS_RANGE")
          },
          () => ({
            validator(rule, value) {
              if (value.length > 1 && value.slice(0, 1) === "0") {
                return Promise.reject(messagesUtils.getMessage("ERROR_ALLOWANCE_DAYS_RANGE"));
              }
              let input = parseInt(value, 10);
              if (input >= CONSTANTS.MONTHLY_ALLOWANCE_DAYS_MIN_RANGE && input <= CONSTANTS.MONTHLY_ALLOWANCE_DAYS_MAX_RANGE) {
                return Promise.resolve();
              }
              return Promise.reject(messagesUtils.getMessage("ERROR_ALLOWANCE_DAYS_RANGE"));
            },
          })
        ]}
      >
        <Input addonBefore="月" addonAfter="日以上" maxLength={CONSTANTS.MONTHLY_ALLOWANCE_DAYS_MAX_LENGTH} className={styles.allowanceDays} />
      </DetailForm.Item>
    )
  }

  // 日当から月手当に変わる条件
  const CeilingDailyAlloanceAmount = () => {
    return (
      <DetailForm.Item label="月手当への切り替え">
      <div className={styles.ceilingDailyAllowance}>
        <DetailForm.Item
          name="ceilingDailyAllowanceInspectionDays"
          dependencies={["allowanceType"]}
          validateFirst={true}
          rules={[
            {
              pattern: validationRules.seisu,
              message: messagesUtils.getMessage("ERROR_CEILING_DAILY_ALLOWANCE_INSPECTION_DAYS_RANGE")
            },
            () => ({
              validator(rule, value) {
                if (value != null) {
                  if (value.length > 1 && value.slice(0, 1) === "0") {
                    return Promise.reject(messagesUtils.getMessage("ERROR_CEILING_DAILY_ALLOWANCE_INSPECTION_DAYS_RANGE"));
                  }
                  let input = parseInt(value, 10);
                  if (input < CONSTANTS.MONTHLY_ALLOWANCE_DAYS_MIN_RANGE || CONSTANTS.MONTHLY_ALLOWANCE_DAYS_MAX_RANGE < input) {
                    return Promise.reject(messagesUtils.getMessage("ERROR_CEILING_DAILY_ALLOWANCE_INSPECTION_DAYS_RANGE"));
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input className={styles.ceilingDailyAllowanceInspectionDays} addonBefore="労働日数が" addonAfter="日" maxLength={CONSTANTS.MONTHLY_ALLOWANCE_DAYS_MAX_LENGTH} />
        </DetailForm.Item>
        <span>以上の場合は</span>
        <DetailForm.Item
          name="ceilingDailyAllowanceAmount"
          dependencies={["allowanceType"]}
          validateFirst={true}
          rules={[
            {
              pattern: validationRules.seisu,
              message: messagesUtils.getMessage("ERROR_ALLOWANCE_AMOUNT_RANGE")
            },
            () => ({
              validator(rule, value) {
                if (value != null) {
                  if (value.length > 1 && value.slice(0, 1) === "0") {
                    return Promise.reject(messagesUtils.getMessage("ERROR_ALLOWANCE_AMOUNT_RANGE"));
                  }
                  let input = parseInt(value, 10);
                  if (input < CONSTANTS.ALLOWANCE_AMOUNT_MIN || input > CONSTANTS.ALLOWANCE_AMOUNT_MAX) {
                    return Promise.reject(messagesUtils.getMessage("ERROR_ALLOWANCE_AMOUNT_RANGE"));
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input className={styles.ceilingDailyAllowanceAmount} addonBefore="定額" addonAfter="円" maxLength={CONSTANTS.ALLOWANCE_AMOUNT_MAX_LENGTH} />
        </DetailForm.Item>
        <span>となります</span>
      </div>
      <div className={styles.note}>
        手当金額を労働日数に応じて日額から月額（定額）に切り替えたい場合はこちらの項目を設定してください。
      </div>
      </DetailForm.Item>
    ) 
  }

  // 編集Formを描画します。
  const EditForm = () => {
    return (
      <>
        <DetailForm
          form={form}
          className={styles.editform}
          initialValues={{
            'originalQualificationNo': dataRow.originalQualificationNo
            , 'originalQualificationName': dataRow.originalQualificationName
            , 'allowanceType': dataRow.allowanceType
            , 'allowanceInspectionType': dataRow.allowanceInspectionType
            , 'allowanceInspectionTime': dataRow.allowanceInspectionTime === null ? 1 : dataRow.allowanceInspectionTime
            , 'monthlyAllowanceInspectionDays': dataRow.monthlyAllowanceInspectionDays === null ? 0 : dataRow.monthlyAllowanceInspectionDays
            , 'allowanceAmount': dataRow.allowanceAmount === null ? 0 : dataRow.allowanceAmount
            , 'ceilingDailyAllowanceInspectionDays': isNullOrNan(dataRow.ceilingDailyAllowanceInspectionDays) ? "": dataRow.ceilingDailyAllowanceInspectionDays
            , 'ceilingDailyAllowanceAmount': isNullOrNan(dataRow.ceilingDailyAllowanceAmount) ? "": dataRow.ceilingDailyAllowanceAmount
            , 'deadlineExist': dataRow.deadlineExist === CONSTANTS.EXIST_DEADLINE.EXIST.code ? true : false
            , 'deadlineStartDate': isNullOrEmpty(dataRow.deadlineStartDate) ? null : moment(dataRow.deadlineStartDate)
            , 'deadlineEndDate': isNullOrEmpty(dataRow.deadlineEndDate) ? null : moment(dataRow.deadlineEndDate)
            , 'levelRangeTo': constantsUtils.getCodeLabelListOf(CONSTANTS.LEVEL_RANGE_TO).map(codelabel => codelabel.code).includes(String(dataRow.levelRangeTo)) ? String(dataRow.levelRangeTo) : ""
            , 'remarks1': dataRow.remarks1 !== null ? dataRow.remarks1 : ""
            , 'remarks2': dataRow.remarks2 !== null ? dataRow.remarks2 : ""
          }}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <OriginalQualificationNo />

          <DetailForm.Item
            name="originalQualificationName"
            label="独自資格名"
            validateFirst={true}

            rules={[
              {
                required: true,
                message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["独自資格名"])
              },
              {
                max: CONSTANTS.QUALIFICATION_NAME_MAX_LENGTH,
                message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["独自資格名", CONSTANTS.QUALIFICATION_NAME_MAX_LENGTH.toString()])
              }
            ]}
          >
            <Input className={styles.originalQualificationName} maxLength={CONSTANTS.QUALIFICATION_NAME_MAX_LENGTH} />
          </DetailForm.Item>

          <DetailForm.Item
            name="allowanceType"
            label="手当区分"

            validateFirst={true}
            rules={[
              {
                required: true,
                message: messagesUtils.getMessage("ERROR_REQUIRED_SELECT", ["手当区分"])
              }
            ]}
          >
            <Radio.Group buttonStyle={"solid"}>
              {constantsUtils.getCodeLabelListOf(CONSTANTS.ALLOWANCE_TYPE).map((rec: any) => (
                <Radio key={rec.code} value={rec.code}>{rec.label}</Radio>
              ))}
            </Radio.Group>
          </DetailForm.Item>
          <DetailForm.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.allowanceType !== currentValues.allowanceType || prevValues.allowanceInspectionType  !== currentValues.allowanceInspectionType}
          >
            {({ getFieldValue }) => {
               if (getFieldValue('allowanceInspectionType') === CONSTANTS.ALLOWANCE_INSPECTION_TYPE.NONE.code) {
                if (getFieldValue('allowanceType') === CONSTANTS.ALLOWANCE_TYPE.MONTHLY.code) {
                  return (
                    <DetailForm.Item
                      label="集計条件"
                      required
                    >
                      <AllowanceInspectiontypeRadio />
                      <span>※1分以上の労働時間の入力がある作業日があれば集計対象とする</span>
                    </DetailForm.Item>);
                } else if (getFieldValue('allowanceType') === CONSTANTS.ALLOWANCE_TYPE.DAILY.code) {
                  return (
                    <DetailForm.Item
                      label="集計条件"
                      required
                    >
                      <AllowanceInspectiontypeRadio />
                      <span>※1分以上の労働時間の入力がある作業日を集計対象とする</span>
                    </DetailForm.Item>);
                } else {
                  return (<></>);
                }

              } else {
                if (getFieldValue('allowanceType') === CONSTANTS.ALLOWANCE_TYPE.MONTHLY.code) {
                  return (
                    
                    <DetailForm.Item
                      label="集計条件"
                      required
                    >
                      <AllowanceInspectiontypeRadio />
                      <AllowanceInspectionTime />
                      <MonthlyAllowanceInspectionDays />
                    </DetailForm.Item>);
                } else if (getFieldValue('allowanceType') === CONSTANTS.ALLOWANCE_TYPE.DAILY.code) {
                  return (
                    <DetailForm.Item
                      label="集計条件"
                      required
                    >
                      <AllowanceInspectiontypeRadio />
                      <AllowanceInspectionTime />
                    </DetailForm.Item>);
                } else {
                  return (<></>);
                }
              }
            }}
          </DetailForm.Item>
          <DetailForm.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.allowanceType !== currentValues.allowanceType}
          >
            {({ getFieldValue }) => {
              if (getFieldValue('allowanceType') !== CONSTANTS.ALLOWANCE_TYPE.NONE.code) {
                return (
                  <>
                  <DetailForm.Item
                    name="allowanceAmount"
                    label="手当金額"
                    dependencies={["allowanceType"]}
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["手当金額"])
                      },
                      {
                        pattern: validationRules.seisu,
                        message: messagesUtils.getMessage("ERROR_ALLOWANCE_AMOUNT_RANGE")
                      },
                      () => ({
                        validator(rule, value) {
                          if (value.length > 1 && value.slice(0, 1) === "0") {
                            return Promise.reject(messagesUtils.getMessage("ERROR_ALLOWANCE_AMOUNT_RANGE"));
                          }
                          let input = parseInt(value, 10);
                          if (input < CONSTANTS.ALLOWANCE_AMOUNT_MIN || input > CONSTANTS.ALLOWANCE_AMOUNT_MAX) {
                            return Promise.reject(messagesUtils.getMessage("ERROR_ALLOWANCE_AMOUNT_RANGE"));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input className={styles.allowanceAmount} addonAfter="円" maxLength={CONSTANTS.ALLOWANCE_AMOUNT_MAX_LENGTH} />
                  </DetailForm.Item>
                  </>
                );
              } else {
                return (<></>);
              }
            }}
          </DetailForm.Item>

          <DetailForm.Item
            name="levelRangeTo"
            label="対象次数"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["対象次数"])
              },
            ]}
          >
            <Select className={styles.authoritySelect}>
              <Select.Option value={""}>以下からお選びください　▼</Select.Option>
              {constantsUtils.getCodeLabelListOf(CONSTANTS.LEVEL_RANGE_TO).map((codelabel) => (
                <Select.Option key={codelabel.code} value={codelabel.code}>{codelabel.label}</Select.Option>
              ))}
            </Select>
          </DetailForm.Item>

          <DetailForm.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.allowanceType !== currentValues.allowanceType}
          >
            {({ getFieldValue }) => {
              return getFieldValue('allowanceType') === CONSTANTS.ALLOWANCE_TYPE.DAILY.code ? (
                <CeilingDailyAlloanceAmount />
              ) : (<></>);
            }}
          </DetailForm.Item>

          <DetailForm.Item label="有効期限" >
            <div className={styles.deadLineDuration}>
              <span className={styles.label}>なし</span>
              <DetailForm.Item name="deadlineExist" valuePropName="checked" style={{ display: "inline" }}>
                <Switch
                  defaultChecked={dataRow.deadlineExist === CONSTANTS.EXIST_DEADLINE.EXIST.code ? true : false}
                  className={styles.deadlineExist}
                />
              </DetailForm.Item>
              <span className={styles.label}>あり</span>
            </div>
            <DetailForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.deadlineExist !== currentValues.deadlineExist}
            >
              {({ getFieldValue }) => {
                return getFieldValue('deadlineExist') === true ? (

                  <div className={styles.deadLineDuration}>
                    <DetailForm.Item
                      name="deadlineStartDate"
                      validateFirst={true}
                      // dependencies={['deadlineEndDate']}
                      className={styles.startDate}
                      rules={[
                        ({ getFieldValue, setFields, isFieldValidating }) => ({
                          validator(rule, value) {
                            if (value === null) {
                              if (getFieldValue('deadlineEndDate') !== null) {
                                setFields([{ name: 'deadlineEndDate', errors: [] }]);
                              }
                              return Promise.reject(messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["有効期限開始日"]));
                            }
                            if (getFieldValue('deadlineEndDate') === null) {
                              return Promise.resolve();
                            }
                            if (getFieldValue('deadlineEndDate').isSameOrBefore(value, 'day')) {
                              return Promise.reject(messagesUtils.getMessage("ERROR_DEADLINE_RANGE_START_DATE"));
                            }
                            if (getFieldValue('deadlineEndDate') !== null && !isFieldValidating("deadlineEndDate")) {
                              setFields([{ name: 'deadlineEndDate', errors: [] }]);
                            }
                            return Promise.resolve();
                          }
                        })
                      ]}
                    >
                      <DatePicker />
                    </DetailForm.Item>
                    <span>～</span >
                    <DetailForm.Item
                      name="deadlineEndDate"
                      validateFirst={true}
                      className={styles.endDate}
                      rules={[
                        ({ getFieldValue, validateFields, setFields, isFieldValidating }) => ({
                          validator(rule, value) {
                            if (value === null) {
                              if (getFieldValue('deadlineStartDate') !== null) {
                                setFields([{ name: 'deadlineStartDate', errors: [] }]);
                              }
                              return Promise.reject(messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["有効期限終了日"]));
                            }
                            if (getFieldValue('deadlineStartDate') === null && !isFieldValidating("deadlineStartDate")) {
                              validateFields(["deadlineStartDate"]);
                              return Promise.resolve();
                            }
                            if (getFieldValue('deadlineStartDate') !== null && getFieldValue('deadlineStartDate').isSameOrAfter(value, 'day')) {
                              return Promise.reject(messagesUtils.getMessage("ERROR_DEADLINE_RANGE_END_DATE"));
                            }
                            if (getFieldValue('deadlineStartDate') !== null && !isFieldValidating("deadlineStartDate")) {
                              setFields([{ name: 'deadlineStartDate', errors: [] }]);
                            }
                            return Promise.resolve();
                          }
                        })

                      ]
                      }
                    >
                      <DatePicker />
                    </DetailForm.Item >
                  </div>
                ) : null;
              }}
            </DetailForm.Item>
          </DetailForm.Item>

          <DetailForm.Item
            name="remarks1"
            label="備考１"
            validateFirst={true}
            rules={[
              () => ({
                validator(rule, value) {
                  if (value.replace(/\n/, "").length > CONSTANTS.REMARKS1_MAX_LENGTH) {
                    return Promise.reject(messagesUtils.getMessage("ERROR_MAX_LENGTH", ["備考１", CONSTANTS.REMARKS1_MAX_LENGTH.toString()]));
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <TextArea rows={4} maxLength={CONSTANTS.REMARKS1_MAX_LENGTH} />
          </DetailForm.Item>

          <DetailForm.Item
            name="remarks2"
            label="備考２"
            validateFirst={true}
            rules={[
              () => ({
                validator(rule, value) {
                  if (value.replace(/\n/, "").length > CONSTANTS.REMARKS2_MAX_LENGTH) {
                    return Promise.reject(messagesUtils.getMessage("ERROR_MAX_LENGTH", ["備考２", CONSTANTS.REMARKS2_MAX_LENGTH.toString()]));
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <TextArea rows={4} maxLength={CONSTANTS.REMARKS2_MAX_LENGTH} />
          </DetailForm.Item>
          <div className={styles.fixed}>
            <div className={styles.float}>
              <AuthButton
                name={"戻る"}
                size={"large"}
                shape={"round"}
                onClick={backClicked}
                className={AuthButtonStyles.backButtonFixed}
              />
              <AuthButton
                name={"確定"}
                htmlType="submit"
                size={"large"}
                shape={"round"}
                className={AuthButtonStyles.authButtonFixed}
              />
            </div>
          </div>
        </DetailForm>
        <br /><br /><br />
      </>
    )
  }


  return (
    <Layout>
      <Header />
      <Content hasapicall={"false"}>
        <FunctionTitle title={props.title} />
        <div id="contenttop" />
        <CommonMessage />
        <EditForm />
      </Content>
      <FooterButtonArea hasapicall={"false"} />
      <Footer />
    </Layout >
  );
}

