import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../../store/store";
import { getRequest, apiSuccessHandler, apiErrorHandler, setApiResult, download, } from "../../core/bffApiModule";
import * as CONSTANTS from "../../../constants/constants";
import * as Model from "./reportModel";

// state初期化
const initialState: Model.State = {
  managementGroupList: [],
  managementGroupRow: {
    managementGroupId: "",
    managementGroupName: "",
  },
  selectRadio: "1",
  disabled: false
};

// スライサー （state更新アクションの実装）
export const reportSlice = createSlice({
  name: "primeReport",
  initialState,
  reducers: {
    setManagementGroupList: (
      state,
      action: PayloadAction<Model.getCompaniesList.Response>
    ) => {
      let managementGroupList: Array<Model.managementGroup> = [];

      action.payload.managementGroupList.forEach(
        (apiDataRow: Model.managementGroup) => {
          const managementGroup: Model.managementGroup = {
            managementGroupId: apiDataRow.managementGroupId,
            managementGroupName: apiDataRow.managementGroupName,
          };
          managementGroupList.push(managementGroup);
        }
      );
      state.managementGroupList = managementGroupList;
    },
    selectRadio: (state, action: PayloadAction<string>) => {
      state.selectRadio = action.payload;
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.disabled = action.payload;
    }
  },
});

export const {
  setManagementGroupList,
  selectRadio,
  setDisabled
} = reportSlice.actions;

//　一覧検索
export const getManagementGroupListAsync = (): AppThunk => async (dispatch) => {
  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PRIME_MANAGEMENT_GROUP_LIST);
    // 結果をstateに設定します。
    dispatch(setManagementGroupList(response.data.data));
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　支払実績ダウンロード
export const downloadPaymentRecord = (request: Model.downloadPaymentRecord.Request): AppThunk => async (dispatch) => {
  try {
    // GET-APIを呼び出します。
    // ダウンロードします
    const response = await download(CONSTANTS.URL_REPORT_PRIME_PAYMENT, 
      request, 
      { responseType: "blob" }
    );
    // // 共通成功時処理を呼び出します。
    // dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
    if (response.status === CONSTANTS.API_STATUS_200) {
      dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
    }
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　資格保有者帳票ダウンロード
export const downloadQualificationHolder = (request: Model.downloadQualificationHolder.Request): AppThunk => async (dispatch) => {
  try {
    // GET-APIを呼び出します。
    // ダウンロードします
    const response = await download(CONSTANTS.URL_REPORT_PRIME_QUALIFICATIONS_HOLDERS, 
      request,
      { responseType: "blob"}
    );
    // // 共通成功時処理を呼び出します。
    // dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
    if (response.status === CONSTANTS.API_STATUS_200) {
      dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
    }
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　プロジェクト別支払実績ダウンロード
export const downloadProjectPaymentRecord = (request: Model.downloadProjectPaymentRecord.Request): AppThunk => async (dispatch) => {
  try {
    // GET-APIを呼び出します。
    // ダウンロードします
    const response = await download(CONSTANTS.URL_REPORT_PRIME_PROJECT_PAYMENT, 
      request,
      {responseType: "blob"}
    );
    // // 共通成功時処理を呼び出します。
    // dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
    if (response.status === CONSTANTS.API_STATUS_200) {
      dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
    }
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedManagementGroupList = (state: RootState) => state.primeReport.managementGroupList;
export const storedSelectRadio = (state: RootState) => state.primeReport.selectRadio;
export const storedDisabled = (state: RootState) => state.primeReport.disabled;

export default reportSlice.reducer;
