import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRequest, apiSuccessHandler, apiErrorHandler, putRequest, setApiResult } from '../../core/bffApiModule'
import { setMessages, message } from '../../core/commonMessageModule';
import * as messagesUtils from '../../../utils/messagesUtils';
import { AppThunk, RootState } from '../../../store/store';
import * as CONSTANTS from '../../../constants/constants';
import * as formatUtils from '../../../utils/formatUtils';
import * as Utils from '../../../utils/utils';
import * as Model from './attendanceModel';
import { Moment } from 'moment';
import moment from 'moment';

export interface changeCheckTransfer {
  targetDataRow: Model.WorkRecordDetailRow
  value: boolean
}

// 初期設定の日付を計算
const setDate = (num: number) => {
  const nowMoment: Moment | undefined = moment(new Date(), moment.ISO_8601);
  return nowMoment === undefined ? "" : nowMoment.add(num, "month").format("YYYYMM");
}

// 元請勤怠実績データ初期化
const initialState: Model.WorkRecordPartnerState = {
  dataList: [],
  qualificationList: [],
  summaryTotal: 0
  , workRecordSummaryCriteria: {
    primeCompanyName: "",
    primeCompanyNameKana: "",
    projectName: "",
    belongCompanyName: "",
    belongCompanyNameKana: "",
    firstPartnerCompanyName: "",
    firstPartnerCompanyNameKana: "",
    fromYYYYMMDD: setDate(-1),
    toYYYYMMDD: setDate(0),
    notSubmitted: "1",
    submitted: "1",
    confirmed: "1",
    approved: "0",
    lastName: "",
    firstName: "",
    sortItem: "",
    sortOrder: "",
    start: 0,
    count: 10
  }
  , primeCompanyId: ""
  , primeCompanyName: ""
  , projectId: ""
  , projectName: ""
  , belongCompanyId: ""
  , belongCompanyName: ""
  , firstPartnerCompanyId: ""
  , detailDataList: []
  , detailTotal: 0
  , workRecordDetailCriteria: {
    attendanceYearMonth: ""
    , primeCertificationNo: ""
    , workerLastName: ""
    , workerLastNameKana: ""
    , workerFirstName: ""
    , workerFirstNameKana: ""
    , firstPartnerCompanyName: ""
    , firstPartnerCompanyNameKana: ""
    , fromTopHierarchyLevel: ""
    , toTopHierarchyLevel: ""
    , offset: 0
    , limit: 10
    , approved: false
    , submitted: false
    , remand: true
    , noSubmitted: true
    , hasInOutWorkTime: false
  }
  , allSelect: false
  , existSubmittedChecked: false
  , existNotSubmittedChecked: false
  , existNotSubmitted: false
  , modalVisibleState: false
  , qualificationModalVisibleState: false
  , selectedAttendanceInfo: {
    workRecordSubmissionHistoryId: ""
    , primeCompanyId: ""
    , belongCompanyId: ""
    , workerId: ""
    , projectId: ""
    , firstPartnerCompanyId: ""
    , dailyWorkStatus: {
      date: ""
      , firstInTime: ""
      , lastOutTime: ""
      , workingTime: ""
      , editWorkingTimeFlag: false
      , meetQualificationRequirementType: ""
      , updateDatetime: ""
    }
  }
  , editAvailableFlag: 1
  , existAvailableRow: false
  , isLoading: false
  , worktimeConfirmModalVisibleState: false
  , worktimeConfirmText: ""
  , worktimeConfirmTextWorker: []
  , worktimeConfirmTextDate: []
  , isContainInvalidInOutTime: false
};

export const partnerAttendanceSlice = createSlice({
  name: 'partnerAttendanceSlice',
  initialState,
  reducers: {
    // -------------
    // 勤怠一覧
    // -------------

    prepareMoveToDetail: (state, action: PayloadAction<Model.WorkRecordPartnerSummaryRow>) => {
      state.primeCompanyId = action.payload.primeCompanyId;
      state.projectId = action.payload.projectId;
      state.belongCompanyId = action.payload.belongCompanyId;
      state.firstPartnerCompanyId = action.payload.firstPartnerCompanyId;
      state.workRecordDetailCriteria.attendanceYearMonth = formatUtils.formatMonthNonSlash(action.payload.attendanceYearMonth)
      state.modalVisibleState = false;
      state.qualificationModalVisibleState = false;
      state.isLoading = false;
      state.worktimeConfirmModalVisibleState = false;
      state.worktimeConfirmText = "";
      state.worktimeConfirmTextWorker = [];
      state.worktimeConfirmTextDate = [];
    },

    prepareMoveToDetailCriteria: (state, action: PayloadAction<Model.WorkRecordSummaryCriteria>) => {
      let workRecordDetailCriteria = {
        attendanceYearMonth: state.workRecordDetailCriteria.attendanceYearMonth
        , primeCertificationNo: ""
        , workerLastName: action.payload.lastName
        , workerLastNameKana: ""
        , workerFirstName: action.payload.firstName
        , workerFirstNameKana: ""
        , firstPartnerCompanyName: action.payload.firstPartnerCompanyName
        , firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana
        , fromTopHierarchyLevel: ""
        , toTopHierarchyLevel: ""
        , offset: 0
        , limit: 10
        , approved: action.payload.approved === "1" ? true : false
        , submitted: action.payload.submitted === "1" || action.payload.confirmed === "1" ? true : false
        , remand: action.payload.notSubmitted === "1" ? true : false
        , noSubmitted: action.payload.notSubmitted === "1" ? true : false
        , hasInOutWorkTime: false
      }

      state.workRecordDetailCriteria = workRecordDetailCriteria;
    },

    initDataList: (state) => {
      state.dataList = initialState.dataList;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setDataListOfWorkRecordPartner: (state, action: PayloadAction<Model.AttendanceRecordApiData>) => {

      state.dataList = initialState.dataList;

      const responseAttendanceRecordList: Model.WorkRecordPartnerSummaryRow[] = action.payload.attendanceRecordList || [];
      let attendanceRecordList: Array<Model.WorkRecordPartnerSummaryRow> = [];
      responseAttendanceRecordList.forEach((attnendanceRecord: Model.WorkRecordPartnerSummaryRow) => {

        const rec: Model.WorkRecordPartnerSummaryRow = {
          key: attnendanceRecord.projectId + attnendanceRecord.attendanceYearMonth
          , primeCompanyId: attnendanceRecord.primeCompanyId
          , primeCompanyName: attnendanceRecord.primeCompanyName
          , projectId: attnendanceRecord.projectId
          , projectName: attnendanceRecord.projectName
          , firstPartnerCompanyId: attnendanceRecord.firstPartnerCompanyId
          , firstPartnerCompanyName: attnendanceRecord.firstPartnerCompanyName
          , belongCompanyId: attnendanceRecord.belongCompanyId
          , belongCompanyName: attnendanceRecord.belongCompanyName
          , notSubmitted: attnendanceRecord.notSubmitted
          , submitted: attnendanceRecord.submitted
          , confirmed: attnendanceRecord.confirmed
          , approved: attnendanceRecord.approved
          , attendanceYearMonth: attnendanceRecord.attendanceYearMonth
        };
        attendanceRecordList.push(rec);
      });

      state.dataList = attendanceRecordList;
      state.summaryTotal = action.payload.total
    },

    /**
     * 検索条件のstate更新
     */
    setSummaryCriteria: (state, action: PayloadAction<Model.WorkRecordSummaryCriteria>) => {
      let criteria: Model.WorkRecordSummaryCriteria = {
        primeCompanyName: action.payload.primeCompanyName,
        primeCompanyNameKana: action.payload.primeCompanyNameKana,
        projectName: action.payload.projectName,
        belongCompanyName: action.payload.belongCompanyName,
        belongCompanyNameKana: action.payload.belongCompanyNameKana,
        firstPartnerCompanyName: action.payload.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana,
        fromYYYYMMDD: action.payload.fromYYYYMMDD,
        toYYYYMMDD: action.payload.toYYYYMMDD,
        notSubmitted: action.payload.notSubmitted,
        submitted: action.payload.submitted,
        confirmed: action.payload.confirmed,
        approved: action.payload.approved,
        lastName: action.payload.lastName,
        firstName: action.payload.firstName,
        sortItem: action.payload.sortItem,
        sortOrder: action.payload.sortOrder,
        start: action.payload.start,
        count: action.payload.count
      }
      return Object.assign({}, state, { workRecordSummaryCriteria: criteria })
    },

    // -------------
    // 勤怠明細一覧
    // -------------

    /**
     * URLに設定されているパラメータをstateに反映
     */
    setUrlParameterValue: (state, action: PayloadAction<Model.UrlParameter>) => {
      let criteria: Model.WorkRecordDetailCriteria = {
        attendanceYearMonth: action.payload.attendanceYearMonth,
        primeCertificationNo: state.workRecordDetailCriteria.primeCertificationNo,
        workerLastName: state.workRecordDetailCriteria.workerLastName,
        workerFirstName: state.workRecordDetailCriteria.workerFirstName,
        workerLastNameKana: state.workRecordDetailCriteria.workerLastNameKana,
        workerFirstNameKana: state.workRecordDetailCriteria.workerFirstNameKana,
        firstPartnerCompanyName: state.workRecordDetailCriteria.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: state.workRecordDetailCriteria.firstPartnerCompanyNameKana,
        fromTopHierarchyLevel: state.workRecordDetailCriteria.fromTopHierarchyLevel,
        toTopHierarchyLevel: state.workRecordDetailCriteria.toTopHierarchyLevel,
        offset: state.workRecordDetailCriteria.offset,
        limit: state.workRecordDetailCriteria.limit,
        approved: state.workRecordDetailCriteria.approved,
        submitted: state.workRecordDetailCriteria.submitted,
        remand: state.workRecordDetailCriteria.remand,
        noSubmitted: state.workRecordDetailCriteria.noSubmitted,
        hasInOutWorkTime: state.workRecordDetailCriteria.hasInOutWorkTime
      }

      return Object.assign(
        {}, state,
        { workRecordDetailCriteria: criteria },
        { projectId: action.payload.projectId },
        { belongCompanyId: action.payload.belongCompanyId }
      );
    },

    /**
     * 検索条件の初期化
     */
    initCriteria: (state) => {
      let workRecordDetailCriteria = {
        attendanceYearMonth: state.workRecordDetailCriteria.attendanceYearMonth
        , primeCertificationNo: ""
        , workerLastName: ""
        , workerLastNameKana: ""
        , workerFirstName: ""
        , workerFirstNameKana: ""
        , firstPartnerCompanyName: ""
        , firstPartnerCompanyNameKana: ""
        , offset: 0
        , limit: 10
        , approved: false
        , submitted: false
        , remand: true
        , noSubmitted: true
        , hasInOutWorkTime: false
      }

      let workRecordSummaryCriteria = {
        primeCompanyName: ""
        , primeCompanyNameKana: ""
        , projectName: ""
        , belongCompanyName: ""
        , belongCompanyNameKana: ""
        , firstPartnerCompanyName: ""
        , firstPartnerCompanyNameKana: ""
        , lastName: ""
        , firstName: ""
        , fromYYYYMMDD: setDate(-1)
        , toYYYYMMDD: setDate(0)
        , notSubmitted: "1"
        , submitted: "1"
        , confirmed: "1"
        , approved: "0"
        , sortItem: ""
        , sortOrder: ""
        , start: 0
        , count: 10
      }

      return Object.assign({}, state, { workRecordDetailCriteria: workRecordDetailCriteria }, { workRecordSummaryCriteria: workRecordSummaryCriteria });
    },

    /**
     * 検索条件のstate更新
     */
    setCriteria: (state, action: PayloadAction<Model.WorkRecordDetailCriteria>) => {
      let criteria: Model.WorkRecordDetailCriteria = {
        attendanceYearMonth: action.payload.attendanceYearMonth,
        primeCertificationNo: action.payload.primeCertificationNo,
        workerLastName: action.payload.workerLastName,
        workerFirstName: action.payload.workerFirstName,
        workerLastNameKana: action.payload.workerLastNameKana,
        workerFirstNameKana: action.payload.workerFirstNameKana,
        firstPartnerCompanyName: action.payload.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana,
        fromTopHierarchyLevel: action.payload.fromTopHierarchyLevel,
        toTopHierarchyLevel: action.payload.toTopHierarchyLevel,
        offset: action.payload.offset,
        limit: action.payload.limit,
        approved: action.payload.approved,
        submitted: action.payload.submitted,
        remand: action.payload.remand,
        noSubmitted: action.payload.noSubmitted,
        hasInOutWorkTime: action.payload.hasInOutWorkTime
      }
      return Object.assign({}, state, { workRecordDetailCriteria: criteria })
    },

    /**
     * 一覧の初期化
     */
    initDetailDataList: (state) => {
      return Object.assign({}, state, { detailDataList: initialState.detailDataList })
    },

    /**
     * 一覧レコードのセット
     */
    setDetailDataListOfWorkRecordPartner: (state, action: PayloadAction<Model.getDetailDataList.Response>) => {
      let userWorkStatusList: Array<Model.WorkRecordDetailRow> = [];

      let total = action.payload.total;
      let primeCompanyId = action.payload.primeCompanyId;
      let primeCompanyName = action.payload.primeCompanyName;
      let projectId = action.payload.projectId;
      let projectName = action.payload.projectName;
      let belongCompanyId = action.payload.belongCompanyId;
      let belongCompanyName = action.payload.belongCompanyName;
      let editAvailableFlag = action.payload.editAvailableFlag;
      let existAvailableRow: boolean = false;
      let existNotSubmitted: boolean = false;

      action.payload.userWorkStatusList.forEach((userWorkStatus: Model.getDetailDataList.UserWorkStatusList) => {
        // 労働日数、労働時間の算出
        let days = 0;
        let totalWorkingTime = 0;
        let totalWorkingTimeStr = "";

        userWorkStatus.dailyWorkStatusList.forEach(dailyWorkStatus => {
          let workingTime = formatUtils.formatTime(dailyWorkStatus.workingTime);

          if (workingTime === "" || workingTime === "0:00") {
            // 労働時間が設定されていない場合は処理しない
            return;
          }

          if (dailyWorkStatus.meetQualificationRequirementType === CONSTANTS.QUALIFICATION_REQUIREMENT_TYPE.OUTSIDE_VALID_PERIOD.code) {
            // 資格要件に該当していない日付は処理しない
            return;
          }

          // 労働日数を加算
          days++;

          // 労働時間を加算
          let hour = workingTime.split(":")[0];
          let minutes = workingTime.split(":")[1];

          totalWorkingTime += ((parseInt(hour) * 60 * 60) + (parseInt(minutes) * 60));
        });

        // 時
        let hourStr = Math.floor(totalWorkingTime / 3600);
        // 分
        let minutesStr = Math.ceil((totalWorkingTime - hourStr * 3600) / 60);

        totalWorkingTimeStr = hourStr + ":" + ("0" + minutesStr).slice(-2);

        const rec: Model.WorkRecordDetailRow = {
          key: userWorkStatus.workRecordSubmissionHistoryId + '_' + userWorkStatus.workerId
          , workRecordSubmissionHistoryId: userWorkStatus.workRecordSubmissionHistoryId
          , selectFlag: false
          , approvalStatus: userWorkStatus.approvalStatus
          , forceApprovalFlag: userWorkStatus.forceApprovalFlag
          , primeCertificationNo: userWorkStatus.primeCertificationNo
          , workerId: userWorkStatus.workerId
          , name: userWorkStatus.name
          , firstPartnerCompanyId: userWorkStatus.firstPartnerCompanyId
          , firstPartnerCompanyName: userWorkStatus.firstPartnerCompanyName
          , topHierarchyLevel: userWorkStatus.topHierarchyLevel
          , dailyWorkStatusList: userWorkStatus.dailyWorkStatusList
          , updateDatetime: userWorkStatus.updateDatetime
          , days: days
          , totalWorkingTime: totalWorkingTimeStr
          , abolishCompanyFlag: userWorkStatus.abolishCompanyFlag
        };

        userWorkStatusList.push(rec);

        if (isAvailableRowDetail(rec, editAvailableFlag)) {
          // 処理可能な行があるので、全選択チェックボックスを表示する
          existAvailableRow = true;
        }

        if (isNotSubmitted(rec) || isRemand(rec)) {
          // 未提出の行がある
          existNotSubmitted = true;
        }
      });

      // 表示している中に入退場時間が逆転して労働時間が未設定の勤怠が存在するかチェック
      let isContainInvalidInOutTime = action.payload.userWorkStatusList.some((ws) => {
        return ws.dailyWorkStatusList.some((s) => {
          return Utils.isInversionInOutTime(s.firstInTime, s.lastOutTime);
        });
      });

      return Object.assign(
        {}, state,
        { detailTotal: total },
        { primeCompanyId: primeCompanyId },
        { primeCompanyName: primeCompanyName },
        { projectId: projectId },
        { projectName: projectName },
        { belongCompanyId: belongCompanyId },
        { belongCompanyName: belongCompanyName },
        { detailDataList: userWorkStatusList },
        { allSelect: false },
        { existSubmittedChecked: false },
        { existNotSubmittedChecked: false },
        { existNotSubmitted: existNotSubmitted },
        { editAvailableFlag: editAvailableFlag },
        { existAvailableRow: existAvailableRow },
        { isContainInvalidInOutTime: isContainInvalidInOutTime},
      )
    },

    /**
     * 独自資格一覧レコードのセット
     */
    setQualificationsHoldersPrimeQualificationList: (state, action: PayloadAction<Model.getOriginalQualificationList.Response>) => {
      // レスポンスデータ
      const data: Model.getOriginalQualificationList.Response = action.payload;

      // 資格一覧
      const qualificationList: Array<Model.QualificationRow>= new Array<Model.QualificationRow>();

      data.qualificationList.forEach((rowData:Model.QualificationRow) => {
        qualificationList.push({
          originalQualificationName: rowData.originalQualificationName,
          certificationDate: rowData.certificationDate,
          deadlineStartDate: rowData.deadlineStartDate,
          deadlineEndDate: rowData.deadlineEndDate,
          levelRangeTo: rowData.levelRangeTo,
        });
      });

      return Object.assign({}, state, {
        qualificationList: qualificationList
      });
    },

    /**
     * 全選択チェックボックス変更
     */
    onChangeAllSelect: (state, e: any) => {
      let userWorkStatusList: Array<Model.WorkRecordDetailRow> = [];

      let existSubmittedChecked = false;
      let existNotSubmittedChecked = false;

      // 一覧のチェック状態を全て変更
      state.detailDataList.forEach((detailData: Model.WorkRecordDetailRow) => {
        const rec: Model.WorkRecordDetailRow = {
          key: detailData.workRecordSubmissionHistoryId + '_' + detailData.workerId
          , workRecordSubmissionHistoryId: detailData.workRecordSubmissionHistoryId
          , selectFlag: e.payload.target.checked
          , approvalStatus: detailData.approvalStatus
          , forceApprovalFlag: detailData.forceApprovalFlag
          , primeCertificationNo: detailData.primeCertificationNo
          , workerId: detailData.workerId
          , name: detailData.name
          , firstPartnerCompanyId: detailData.firstPartnerCompanyId
          , firstPartnerCompanyName: detailData.firstPartnerCompanyName
          , topHierarchyLevel: detailData.topHierarchyLevel
          , dailyWorkStatusList: detailData.dailyWorkStatusList
          , updateDatetime: detailData.updateDatetime
          , days: detailData.days
          , totalWorkingTime: detailData.totalWorkingTime
          , abolishCompanyFlag: detailData.abolishCompanyFlag
        };

        if (!isApproved(rec) && !isForceApproved(rec)) {
          if (rec.selectFlag) {
            // 「承認済」「承認済＊」以外 　かつ　 選択されている
            if (isUnApproved(rec) || isConfirmed(rec)) {
              // 「提出済」または「確認済」
              existSubmittedChecked = true;
            } else if (isNotSubmitted(rec) || isRemand(rec)) {
              // 「未提出」または「差戻」
              existNotSubmittedChecked = true;
            }
          }
        }

        userWorkStatusList.push(rec);
      });

      return Object.assign(
        {}, state,
        { allSelect: e.payload.target.checked ? true : false },
        { detailDataList: userWorkStatusList },
        { existSubmittedChecked: existSubmittedChecked },
        { existNotSubmittedChecked: existNotSubmittedChecked },
      );
    },

    /**
     * 各行のチェックボックス変更
     */
    onChangeRowSelect: (state, action: PayloadAction<changeCheckTransfer>) => {
      let userWorkStatusList: Array<Model.WorkRecordDetailRow> = [];
      let existOffFlag = false;
      let existSubmittedOnFlag = false;
      let existNotSubmittedOnFlag = false;

      state.detailDataList.forEach((detailData: Model.WorkRecordDetailRow) => {
        const rec: Model.WorkRecordDetailRow = {
          key: detailData.workRecordSubmissionHistoryId + '_' + detailData.workerId
          , workRecordSubmissionHistoryId: detailData.workRecordSubmissionHistoryId
          , selectFlag: action.payload.targetDataRow.key === detailData.key ? action.payload.value : detailData.selectFlag
          , approvalStatus: detailData.approvalStatus
          , forceApprovalFlag: detailData.forceApprovalFlag
          , primeCertificationNo: detailData.primeCertificationNo
          , workerId: detailData.workerId
          , name: detailData.name
          , firstPartnerCompanyId: detailData.firstPartnerCompanyId
          , firstPartnerCompanyName: detailData.firstPartnerCompanyName
          , topHierarchyLevel: detailData.topHierarchyLevel
          , dailyWorkStatusList: detailData.dailyWorkStatusList
          , updateDatetime: detailData.updateDatetime
          , days: detailData.days
          , totalWorkingTime: detailData.totalWorkingTime
          , abolishCompanyFlag: detailData.abolishCompanyFlag
        };

        userWorkStatusList.push(rec);

        if (!isApproved(rec) && !isForceApproved(rec)) {
          if (rec.selectFlag) {
            // 「承認済」「承認済＊」以外 　かつ　 選択されている
            if (isUnApproved(rec) || isConfirmed(rec)) {
              // 「提出済」または「確認済」
              existSubmittedOnFlag = true;
            } else if (isNotSubmitted(rec) || isRemand(rec)) {
              // 「未提出」または「差戻」
              existNotSubmittedOnFlag = true;
            }
          } else {
            // 「承認済」「承認済＊」以外 　かつ　 選択されていない
            existOffFlag = true;
          }
        }
      });

      let allSelect = existOffFlag ? false : true;

      return Object.assign(
        {}, state,
        { allSelect: allSelect },
        { detailDataList: userWorkStatusList },
        { existSubmittedChecked: existSubmittedOnFlag },
        { existNotSubmittedChecked: existNotSubmittedOnFlag },
      );
    },

    /**
     * モーダルの表示/非表示切り替え
     */
    reflectModalState: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { modalVisibleState: action.payload });
    },

    /**
     * モーダルで使用するstateの更新
     */
    setModalState: (state, action: PayloadAction<Model.SelectedAttendanceInfo>) => {
      return Object.assign({}, state, { selectedAttendanceInfo: action.payload });
    },

    /**
     * ローディンを表示するかどうかを設定
     */
    setLoading: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { isLoading: action.payload });
    },

    /**
     * 独自資格一覧モーダルの表示/非表示切り替え
     */
    reflectQualificationModalState: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { qualificationModalVisibleState: action.payload });
    },

    reflectWorktimeConfirmModalVisibleState: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { worktimeConfirmModalVisibleState: action.payload });
    },

    setWorktimeConfirmText: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, { worktimeConfirmText: action.payload });
    },

    setWorktimeConfirmTextWorker: (state, action: PayloadAction<string[]>) => {
      return Object.assign({}, state, { worktimeConfirmTextWorker: action.payload });
    },

    setWorktimeConfirmTextDate: (state, action: PayloadAction<string[]>) => {
      return Object.assign({}, state, { worktimeConfirmTextDate: action.payload });
    },
  },
});

export const {
  prepareMoveToDetail
  , initDataList
  , setDataListOfWorkRecordPartner
  , setUrlParameterValue
  , initCriteria
  , setCriteria
  , initDetailDataList
  , setDetailDataListOfWorkRecordPartner
  , setQualificationsHoldersPrimeQualificationList
  , onChangeAllSelect
  , onChangeRowSelect
  , reflectModalState
  , reflectQualificationModalState
  , setModalState
  , prepareMoveToDetailCriteria
  , setSummaryCriteria
  , setLoading
  , reflectWorktimeConfirmModalVisibleState
  , setWorktimeConfirmText
  , setWorktimeConfirmTextWorker
  , setWorktimeConfirmTextDate
} = partnerAttendanceSlice.actions;

/**
 * 承認済＊かどうかチェック
 */
function isForceApproved(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_ON;
}

/**
 * 承認済かどうかチェック
 */
function isApproved(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_APPROVED;
}

/**
 * 確認済かどうかチェック
 */
function isConfirmed(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_CONFIRMED;
}

/**
 * 提出済かどうかチェック
 */
function isUnApproved(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED;
}

/**
 * 差戻かどうかチェック
 */
function isRemand(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_REMAND;
}

/**
 * 未提出かどうかチェック
 */
function isNotSubmitted(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED;
}

/**
 * 提出か提出取消可能な行か判定する（勤怠明細画面用）
 * @param row
 * @returns
 */
function isAvailableRowDetail(row: Model.WorkRecordDetailRow, editAvailableFlag: number): boolean {
  // 提出期限内、かつ、「未提出」「差戻」「未承認」「確認」のいずれかの場合、提出か提出取消可能
  if (editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE) {
    if (isNotSubmitted(row) || isRemand(row) || isUnApproved(row) || isConfirmed(row)) {
      return true;
    }
  }

  // 上記以外の場合、可能な処理はない
  return false;
}

/**
 * 勤怠実績集計取得_協力API呼び出し
 * @param params 
 * @returns 
 */
export const getDataListAsync = (params: Model.WorkRecordSummaryCriteria): AppThunk => async dispatch => {
  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PARTNER_SUMMARY, {
      params: params
    });
    // 結果をstateに設定します。
    dispatch(setDataListOfWorkRecordPartner(response.data.data));

    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

/**
 * 個人の有効独自資格取得API呼び出し
 * @param params 
 * @returns 
 */
export const getQualificationsHoldersPrimeQualificationListAsync = (params: Model.getOriginalQualificationList.Request): AppThunk => async dispatch => {
  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PARTNER_QUALIFICATION_LIST, {
      params: params
    });
    // 結果を確認
    // 権限なし
    if (response.data.cmn.errorCode === "EA2006001") {
      dispatch(setApiResult({ status: response.status, errorCode: response.data.cmn.errorCode, requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
      return;
    }

    // 結果をstateに設定します。
    dispatch(setQualificationsHoldersPrimeQualificationList(response.data.data));

    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}

/**
 * 勤怠明細取得_協力API呼び出し
 * @param params 
 */
export const getDetailDataListAsync = (params: Model.getDetailDataList.Request): AppThunk => async dispatch => {
  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PARTNER_DETAIL, {
      params: params
    });
    // 結果をstateに設定します。
    dispatch(setDetailDataListOfWorkRecordPartner(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}

/**
 * 勤怠実績ステータス変更API呼び出し
 * TODO 元請側にも同じ実装があるため、元請側と協力側で共通化したい。
 * @param putData 
 */
export const putAttendanceStatusAsync = (putData: Model.putStatus.Request): AppThunk => async dispatch => {
  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_ATTENDANCE_CMN_STATUS, putData);

    // APIが返却したエラーコードがいずれかの場合、セッションストレージに保管されている処理成功メッセージを書き換える
    // ・認可チェック失敗
    // ・勤怠締め切りによる失敗
    // ・メール送信失敗
    // ・更新処理0件
    let message = "";
    let processName = "";
    switch (response.data.cmn.errorCode) {
      case "EA5000001":
          processName = getAeendanceProcessType(putData.processType);
          message = messagesUtils.getMessage(response.data.cmn.errorCode, [processName]);
          messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
          break;
      case "EA5000002":
          processName = getAeendanceProcessType(putData.processType);
          message = messagesUtils.getMessage("EA5000002_PARTNER", [processName, processName]);
          messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
          break;
      case "EA5000004":
          message = messagesUtils.getMessage(response.data.cmn.errorCode);
          messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
          break;
      case "EA5000003":
          // メール送信失敗の場合はエラーメッセージは表示させない
          break;
    }
    // 共通成功時処理を呼び出します
    dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_PUT, url: response.config.url }));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
}

/**
 * 勤怠編集API呼び出し
 * @param putData 
 */
export const putEditAttendanceAsync = (putData: Model.editWorkingTime.Request): AppThunk => async dispatch => {
  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_ATTENDANCE_PARTNER_DAILY, putData);
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
}

/**
 * 実行メッセージ取得
 * @param processType 
 */
 const getAeendanceProcessType = (processType: string) : string => {
  // 処理タイプ毎にメッセージを取得
  let args = "";
  switch (processType) {
    case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT:
      args = "提出";
      break;
    case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT_CANCEL:
      args = "提出取消";
      break;
  }
  return args;
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.workRecordPartner.dataList;
export const storedDetailDataList = (state: RootState) => state.workRecordPartner.detailDataList;
export const storedAllSelect = (state: RootState) => state.workRecordPartner.allSelect;
export const storedCriteria = (state: RootState) => state.workRecordPartner.workRecordDetailCriteria;
export const storedDetailTotal = (state: RootState) => state.workRecordPartner.detailTotal;
export const storedPrimeCompanyId = (state: RootState) => state.workRecordPartner.primeCompanyId;
export const storedPrimeCompanyName = (state: RootState) => state.workRecordPartner.primeCompanyName;
export const storedProjectId = (state: RootState) => state.workRecordPartner.projectId;
export const storedProjectName = (state: RootState) => state.workRecordPartner.projectName;
export const storedBelongCompanyId = (state: RootState) => state.workRecordPartner.belongCompanyId;
export const storedBelongCompanyName = (state: RootState) => state.workRecordPartner.belongCompanyName;
export const storedFirstPartnerCompanyId = (state: RootState) => state.workRecordPartner.firstPartnerCompanyId;
export const storedExistSubmittedChecked = (state: RootState) => state.workRecordPartner.existSubmittedChecked;
export const storedExistNotSubmittedChecked = (state: RootState) => state.workRecordPartner.existNotSubmittedChecked;
export const storedExistNotSubmitted = (state: RootState) => state.workRecordPartner.existNotSubmitted;
export const storedModalVisibleState = (state: RootState) => state.workRecordPartner.modalVisibleState;
export const storedQualificationModalVisibleState = (state: RootState) => state.workRecordPartner.qualificationModalVisibleState;
export const storedSelectedAttendanceInfo = (state: RootState) => state.workRecordPartner.selectedAttendanceInfo;
export const storedEditAvailableFlag = (state: RootState) => state.workRecordPartner.editAvailableFlag;
export const storedExistAvailableRow = (state: RootState) => state.workRecordPartner.existAvailableRow;
export const storedSummaryTotal = (state: RootState) => state.workRecordPartner.summaryTotal;
export const storedSummaryCriteria = (state: RootState) => state.workRecordPartner.workRecordSummaryCriteria;
export const storedLoading = (state: RootState) => state.workRecordPartner.isLoading;
export const storedWorktimeConfirmModalVisibleState = (state: RootState) => state.workRecordPartner.worktimeConfirmModalVisibleState;
export const storedWorktimeConfirmText = (state: RootState) => state.workRecordPartner.worktimeConfirmText;
export const storedWorktimeConfirmTextWorker = (state: RootState) => state.workRecordPartner.worktimeConfirmTextWorker;
export const storedWorktimeConfirmTextDate = (state: RootState) => state.workRecordPartner.worktimeConfirmTextDate;
export const storedIsContainInvalidInOutTime = (state: RootState) => state.workRecordPartner.isContainInvalidInOutTime
export const storedQualificationList = (state: RootState) => state.workRecordPartner.qualificationList;

export default partnerAttendanceSlice.reducer;
