import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router'
import { Store } from 'antd/lib/form/interface';
import { Layout, Table, Input, Tooltip, Modal, Typography } from 'antd';
import { CriteriaForm, AuthButton, AuthButtonAuthorities, Checkbox } from '../../core/CoreForm'
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from '../../core/CorePageContents'
import styles from './AttendanceDetail.module.css';
import * as Module from '../../../modules/prime/attendance/attendanceModule';
import * as Model from '../../../modules/prime/attendance/attendanceModel';
import { initMessages, setMessages, message } from '../../../modules/core/commonMessageModule';
import * as formatUtils from '../../../utils/formatUtils';
import * as CONSTANTS from '../../../constants/constants';
import * as validationRules from '../../../validation/coreRules';
import * as messagesUtils from '../../../utils/messagesUtils';
import * as Utils from '../../../utils/utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import AuthButtonStyles from '../../core/css/AuthButton.module.css';
import { initApiResult, storedApiResult } from '../../../modules/core/bffApiModule';
import { RightSquareFilled, LeftSquareFilled, CodeFilled } from '@ant-design/icons';
import { storedLoginUserInfo } from '../../../modules/core/authModule';
import { Comment } from '../comment/Comment';
import * as CommentModule from '../../../modules/prime/comment/commentModule'
import { AbolishCompanyListButton } from '../../core/parts/AbolishCompanyListButton'
import { MergeCompanyAnnotations } from '../../core/parts/MergeCompanyAnnotations'
import { calcWorkTime } from '../../../utils/CalculateUtils'
import moment from "moment";
const { Title } = Typography;

interface titleInterface {
  title: string;
}

export const AttendanceDetail = (props: titleInterface) => {

  const dispatch = useDispatch();
  let dataList = useSelector(Module.storedDetailDataList);
  let allSelect: boolean = useSelector(Module.storedAllSelect);
  let criteria = useSelector(Module.storedCriteria);
  let total = useSelector(Module.storedDetailTotal);
  let projectId = useSelector(Module.storedProjectId);
  let projectName = useSelector(Module.storedProjectName);
  let existAvailableRow = useSelector(Module.storedExsitAvailabletRow);
  let existApprovalRow = useSelector(Module.storedExsitApprovalRow);
  let existUnapprovedRow = useSelector(Module.storedExsitUnapprovedRow);
  let existConfirmedRow = useSelector(Module.storedConfirmedRow);
  let existNotSubmittedRow = useSelector(Module.storedNotSubmittedRow);
  let editAvailableFlag = useSelector(Module.storedEditAvailableFlag);
  let approveAvailableFlag = useSelector(Module.storedApproveAvailableFlag);
  let refferenceRoleFlag = useSelector(Module.storedRefferenceRoleFlag);
  let approvalRoleFlag = useSelector(Module.storedApprovalRoleFlag);
  let approvalFlag = useSelector(Module.storedApprovalFlag);
  let modalVisibleState = useSelector(Module.storedModalVisibleState);
  let modalRemandExpiredVisibleState = useSelector(Module.storedModalRemandExpiredVisibleState);
  let modalForceApprovalVisibleState = useSelector(Module.storedModalForceApprovalVisibleState);
  let loginUserInfo = useSelector(storedLoginUserInfo);
  const apiResult = useSelector(storedApiResult);
  let history = useHistory();
  let comment = useSelector(CommentModule.storedComment);
  let checkedAttendancesCount = useSelector(Module.storedCheckedAttendancesCount);
  const selectForceApprovalChecked = useSelector(Module.storedForceApprovalChecked);

  useEffect(() => {
    callGetDetailDataListApi();
  },
    [criteria]
  );

  /**
   * 一次協力会社名の一覧の整形
   * @param text 
   * @param row 
   */
  const renderfirstPartnerCompanyName = (text: string, row: Model.WorkRecordDetailRow) => {

    let firstPartnerCompanyNames: Array<any> = [];
    let promptTextList: Array<any> = [];

    let index = 0;
    row.firstPartnerCompanyList.forEach((partnerRow: Model.FirstPartnerCompanyRow) => {
      if (row.firstPartnerCompanyList.length >= 4 && index === 2) {
        // 一次協力会社が4つ以上ある場合、3件目以降は「...」で表示する
        firstPartnerCompanyNames.push(<div key={row.key + index} className={styles.contentLeft}>...</div>);
      } else if (row.firstPartnerCompanyList.length < 4 || index < 3) {
        firstPartnerCompanyNames.push(
          <div key={row.key + index} className={styles.contentLeft}>{partnerRow.firstPartnerCompanyName}</div>
        );
      }

      // Tooltipに表示するメッセージの作成
      promptTextList.push(<div>{partnerRow.firstPartnerCompanyName}</div>);
      index++;
    });

    if (row.firstPartnerCompanyList.length >= 4) {
      return {
        children: (<Tooltip placement="topLeft" title={promptTextList}><div>{firstPartnerCompanyNames}</div></Tooltip>)
      }
    } else {
      return {
        children: firstPartnerCompanyNames
      }
    }
  };

  /**
   * 月末の日付の取得
   * @param attendanceYearMonth 
   */
  const getLastDay = (attendanceYearMonth: string) => {
    // 年
    let year = parseInt(attendanceYearMonth.substring(0, 4));

    // 月
    let month = parseInt(attendanceYearMonth.substring(4, 6));

    return new Date(year, month, 0).getDate();
  }

  /**
   * 日毎の勤務実績部分の作成
   * @param attendanceYearMonth 
   */
  const getDaily = (attendanceYearMonth: string) => {
    let daysColumn = [];

    // 年
    let year = parseInt(attendanceYearMonth.substring(0, 4));

    // 月
    let month = parseInt(attendanceYearMonth.substring(4, 6));

    // 月の最終日の取得
    let lastDay = getLastDay(attendanceYearMonth);

    // 曜日文字列定義
    const weekDayChars = ['日', '月', '火', '水', '木', '金', '土']

    for (let i = 1; i <= lastDay; i++) {
      // 日付オブジェクトの作成
      let day = new Date(year, month - 1, i);
      let name = ""

      if (day.getDay() === 0 || day.getDay() === 6) {
        // 土日の場合は背景色をピンクにする
        name = styles.holiday;
      } else {
        name = styles.day;
      }

      daysColumn.push(
        {
          title: i,
          dataIndex: 'day' + i,
          key: 'day' + i,
          className: name,
          align: 'center' as 'center',
          children: [{
            title: weekDayChars[day.getDay()],
            className: name,
            align: 'center' as 'center',
            render: (test: string, row: Model.WorkRecordDetailRow) => {
              if (isNotSubmitted(row) || isStatusForceApprovedFromNotSubmitted(row)) {
                return (
                  {
                    props: {
                      colSpan: 0
                    }
                  }
                )
              } else {
                const firstInTime = row.dailyWorkStatusList[i - 1].firstInTime;
                const lastOutTime = row.dailyWorkStatusList[i - 1].lastOutTime;
                const isValid = isValidPeriod(row.dailyWorkStatusList[i - 1].meetQualificationRequirementType);
                const isTimeEmptyOrZero = (time: string) => {
                  return time === "" || time === "0:00" || time === "00:00";
                }
                let workingTime = formatUtils.formatTime(row.dailyWorkStatusList[i - 1].workingTime);

                let className = "";
                let classNameOutside = "";
                let inOutClassName = Utils.isInversionInOutTime(firstInTime, lastOutTime) ? styles.invalidInOutTime :styles.inOutTIme;
                if (isValid) {
                  if (row.dailyWorkStatusList[i - 1].editWorkingTimeFlag) {
                    // 労働時間が編集されている
                    className = styles.diffWorking;
                  } else {
                    className = styles.working;
                  }
                } else {
                  className = styles.invalidQualification;
                  classNameOutside = styles.invalidQualificationOutside;
                  inOutClassName = styles.invalidQualificationInOutTime;
                }

                if (isTimeEmptyOrZero(workingTime)
                  && (Utils.isNullOrEmpty(firstInTime)
                  || Utils.isNullOrEmpty(lastOutTime)
                  || Utils.isInversionInOutTime(firstInTime, lastOutTime))) {
                  // (入退場の少なくとも一方が空 または 入退場時間が逆転している) かつ 労働時間がゼロならば空にする
                  workingTime = "";
                }

                let firstInTimeTag = Utils.isNullOrEmpty(firstInTime) ? <>&nbsp;</> : formatUtils.formatDateToTime(firstInTime);
                let lastOutTimeTag = Utils.isNullOrEmpty(lastOutTime) ? <>&nbsp;</> : formatUtils.formatDateToTime(lastOutTime);
                let workingTimeTag: any = "";
                if (isValid) {
                  workingTimeTag = workingTime === "" ? <>&nbsp;</> : workingTime;
                } else {
                  // 有効期間外であれば労働時間を「対象外」を表示
                  workingTimeTag = "対象外";
                }

                return (
                  <>
                    <div className={inOutClassName}>{firstInTimeTag}</div>
                    <div className={inOutClassName}>{lastOutTimeTag}</div>
                    <div className={className}><span className={classNameOutside}>{workingTimeTag}</span></div>
                  </>
                )
              }
            }
          }]
        }
      );
    }
    return daysColumn;
  }

  /** 有効期間内かどうか */
  const isValidPeriod = (type: string) => {
    return type !== CONSTANTS.QUALIFICATION_REQUIREMENT_TYPE.OUTSIDE_VALID_PERIOD.code;
  }

  /** 未提出かどうかチェック */
  const isNotSubmitted = (row: Model.WorkRecordDetailRow) => {
    return isStatusNotSubmitted(row) || isStatusRemand(row);
  }

  /** 承認済＊（未提出、差戻から承認）かどうかチェック */
  const isStatusForceApprovedFromNotSubmitted = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_ON && (row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED || row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_REMAND);
  }

  /** 承認済＊かどうかチェック */
  const isStatusForceApproved = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_ON;
  }

  /** 承認済かどうかチェック */
  const isStatusApproved = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_APPROVED;
  }

  /** 確認済かどうかチェック */
  const isStatusConfirmed = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_CONFIRMED;
  }

  /** 提出済かどうかチェック */
  const isStatusUnApproved = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED;
  }

  /** 差戻かどうかチェック */
  const isStatusRemand = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_REMAND;
  }

  /** 未提出かどうかチェック */
  const isStatusNotSubmitted = (row: Model.WorkRecordDetailRow): boolean => {
    return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED;
  }

  /**
   * 全選択チェックボックスを表示するか
   */
  const isDisplayCheckBox = () => {
    if (approvalRoleFlag === 0
      && refferenceRoleFlag === 0
      && approvalFlag === 0) {
      // 勤怠承認者権限、勤怠承認役割、勤怠参照役割のいずれも保持していない
      return false;
    }

    // 処理できる行がある場合に表示
    return dataList.find((data: Model.WorkRecordDetailRow) => {
      return isDisplayRowCheckBox(data);
    });
  }

  /**
   * 選択チェックボックスを表示するか（行単位）
   * Module側(isAvailableRowDetail)と同じ処理をする
   * @param row 
   */
  const isDisplayRowCheckBox = (row: Model.WorkRecordDetailRow) => {
    // 未提出、差戻は、提出期限内の場合、可能な処理はない
    if (isStatusNotSubmitted(row) || isStatusRemand(row)) {
      if (editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE) {
        return false;
      }
    }

    // 承認済は、承認期限が過ぎている場合、可能な処理はない
    if (isStatusApproved(row)) {
      if (approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_NOT_AVAILABLE) {
        return false;
      }
    }

    // 承認権限（管理グループ範囲外）
    if (!hasManagementGroup(row)) {
      if (editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_NOT_AVAILABLE) {
        // 管理グループ外の場合、未提出、差戻は提出期限外になると可能な処理はない
        if (isStatusNotSubmitted(row) || isStatusRemand(row)) {
          return false;
        }
      }
      if (approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_AVAILABLE) {
        // 承認期限内の場合、確認権限で「確認」「差戻」のみ実行可→状態が承認済、承認済＊の場合、可能な処理はない
        if (isStatusApproved(row) || isStatusForceApproved(row)) {
          return false;
        }
      } else {
        // 承認期限外の場合、可能な処理はない
        return false;
      }
    }

    // 上記以外の場合、何らかの処理（承認など）が可能
    return true;
  }

  /**
   * 条件付き承認ボタンを表示するか
   * 権限はauthoritiesで判定するので、他の判定を行う
   */
  const isDisplayForceApproveButton = () => {
    // 提出期限内の場合、非表示
    if (editAvailableFlag) {
      return false;
    }

    if (approveAvailableFlag) {
      // 承認期限内の場合、「未提出」「差戻」の勤怠を表示している場合のみ、表示
      if (existNotSubmittedRow) {
        const targetRows = dataList.filter((data) => {
          return isStatusNotSubmitted(data) || isStatusRemand(data);
        });
        if (existsMatchManagementGroup(targetRows)) {
          // 管理グループが合っていれば処理できるので表示
          return true;
        }
      }
    } else {
      // 承認期限を過ぎている場合は、「未提出」「差戻」「未承認」「確認済」の勤怠を表示している場合のみ、表示
      if (existNotSubmittedRow || existUnapprovedRow || existConfirmedRow) {
        const targetRows = dataList.filter((data) => {
          return isStatusNotSubmitted(data) || isStatusRemand(data) || isStatusUnApproved(data) || isStatusConfirmed(data);
        });
        if (existsMatchManagementGroup(targetRows)) {
          // 管理グループが合っていれば処理できるので表示
          return true;
        }
      }
    }

    // 条件付き承認出来る行がない
    return false;
  }

  /**
   * 承認ボタンを押せるかどうか
   * @returns 
   */
  const isEnableSubmitButton = (): boolean => {

    // 承認期限を過ぎている場合、押下不可
    if (approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_NOT_AVAILABLE) {
      return false;
    }

    // 管理グループ不一致の行が含まれている場合、押下不可
    if (!checkedAllMatchManagementGroup()) {
      return false;
    }

    // ワークフロー機能がONの場合、チェック対象が確認済以外のものが含まれている場合は押下不可
    if (loginUserInfo.workflowFlg == CONSTANTS.WORKFLOW_FLAG.ON.code) {
      return checkedAttendancesCount.approved == 0
        && checkedAttendancesCount.confirmed > 0
        && checkedAttendancesCount.unapproved == 0
        && checkedAttendancesCount.remand == 0
        && checkedAttendancesCount.notSubmitted == 0
        && checkedAttendancesCount.forceApproved == 0
    } else {
      return checkedAttendancesCount.approved == 0
        && checkedAttendancesCount.confirmed + checkedAttendancesCount.unapproved > 0
        && checkedAttendancesCount.remand == 0
        && checkedAttendancesCount.notSubmitted == 0
        && checkedAttendancesCount.forceApproved == 0
    }
  }

  /**
   * 承認取消ボタンが押せるかどうか
   * @returns 
   */
  const isEnableApproveCancelButton = (): boolean => {

    // 管理グループ不一致の行をチェックされている場合、押下不可
    if (!checkedAllMatchManagementGroup()) {
      return false;
    }

    if (approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_AVAILABLE) {
      // 承認期限内は、承認済、承認済＊が押下可
      return checkedAttendancesCount.approved + checkedAttendancesCount.forceApproved > 0
        && checkedAttendancesCount.confirmed == 0
        && checkedAttendancesCount.unapproved == 0
        && checkedAttendancesCount.remand == 0
        && checkedAttendancesCount.notSubmitted == 0
    } else {
      // 承認期限を過ぎている場合、承認済＊が押下可
      return checkedAttendancesCount.approved == 0
        && checkedAttendancesCount.confirmed == 0
        && checkedAttendancesCount.unapproved == 0
        && checkedAttendancesCount.remand == 0
        && checkedAttendancesCount.notSubmitted == 0
        && checkedAttendancesCount.forceApproved > 0
    }
  }

  /**
   * 確認ボタンが押せるかどうか
   * @returns 
   */
  const isEnableConfirmButton = (): boolean => {
    // 承認期限を過ぎている場合、押下不可
    if (approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_NOT_AVAILABLE) {
      return false;
    }

    return checkedAttendancesCount.approved == 0
      && checkedAttendancesCount.confirmed == 0
      && checkedAttendancesCount.unapproved > 0
      && checkedAttendancesCount.remand == 0
      && checkedAttendancesCount.notSubmitted == 0
      && checkedAttendancesCount.forceApproved == 0
  }

  /**
   * 差戻ボタンが押せるかどうか
   * @returns 
   */
  const isEnableRemandButton = (): boolean => {
    // 承認期限を過ぎている場合、押下不可
    if (approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_NOT_AVAILABLE) {
      return false;
    }

    return checkedAttendancesCount.approved == 0
      && checkedAttendancesCount.confirmed + checkedAttendancesCount.unapproved > 0
      && checkedAttendancesCount.remand == 0
      && checkedAttendancesCount.notSubmitted == 0
      && checkedAttendancesCount.forceApproved == 0
  }

  /**
   * 条件付き承認ボタンを押せるかどうか
   * @returns 
   */
  const isEnableForceApproveButton = (): boolean => {

    // 提出期限内は押下不可
    if (editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE) {
      return false;
    }

    // 管理グループ不一致の行をチェックされている場合、押下不可
    if (!checkedAllMatchManagementGroup()) {
      return false;
    }

    if (approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_AVAILABLE) {
      // 承認期限内は未提出、差戻は可
      return checkedAttendancesCount.approved == 0
        && checkedAttendancesCount.confirmed == 0
        && checkedAttendancesCount.unapproved == 0
        && checkedAttendancesCount.remand + checkedAttendancesCount.notSubmitted > 0
        && checkedAttendancesCount.forceApproved == 0
    } else {
      // 承認期限も過ぎている場合、承認済、承認済＊以外は可
      return checkedAttendancesCount.approved == 0
        && checkedAttendancesCount.confirmed + checkedAttendancesCount.unapproved + checkedAttendancesCount.remand + checkedAttendancesCount.notSubmitted > 0
        && checkedAttendancesCount.forceApproved == 0
    }
  }

  /**
   * チェックされた行の管理グループがすべて一致するか
   * @returns 
   */
  const checkedAllMatchManagementGroup = (): boolean => {
    // チェックされた行が全て一致したらtrue
    const rows = dataList.filter(data => data.selectFlag);
    return rows.every((data: Model.WorkRecordDetailRow) => {
      return hasManagementGroup(data);
    });
  }

  /**
   * 指定された行の管理グループに一致するものがあるか
   * @returns 
   */
  const existsMatchManagementGroup = (rows: Model.WorkRecordDetailRow[]): boolean => {
    // 一致の行が一つでもあったらtrue
    return rows.some((data: Model.WorkRecordDetailRow) => {
      return hasManagementGroup(data);
    });
  }

  /**
   * 承認権限の管理グループが勤怠の管理グループを持っているか
   * 承認権限の全グループや承認役割はすべての勤怠を処理できるのでtrue
   * Module側と同じ処理をする
   * @returns 
   */
  const hasManagementGroup = (data: Model.WorkRecordDetailRow): boolean => {

    if (approvalRoleFlag === 1) {
      // 承認役割
      return true;
    }
    if (approvalFlag === 1) {
      if (loginUserInfo.referenceRange === CONSTANTS.REFERENCE_RANGE_ALL) {
        // 承認権限の全グループ
        return true;
      }

      if (loginUserInfo.referenceRange === CONSTANTS.REFERENCE_RANGE_POINT) {
        // 承認権限が管理グループ指定

        // 勤怠の管理グループを含むこと
        if (loginUserInfo.managementGroupList.map((o) => o.managementGroupId).includes(data.managementGroupId)) {
          // 含む場合、処理できる行
          return true;
        }
      }
    }
    return false;
  }

  /**
   * CriteriaをStoreに保持
   * @param keyValueList 
   */
  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: Model.WorkRecordDetailCriteria = {
      attendanceYearMonth: criteria.attendanceYearMonth,
      belongCompanyName: criteria.belongCompanyName,
      belongCompanyNameKana: criteria.belongCompanyNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      primeCertificationNo: criteria.primeCertificationNo,
      workerLastName: criteria.workerLastName,
      workerFirstName: criteria.workerFirstName,
      workerLastNameKana: criteria.workerLastNameKana,
      workerFirstNameKana: criteria.workerFirstNameKana,
      fromTopHierarchyLevel: criteria.fromTopHierarchyLevel,
      toTopHierarchyLevel: criteria.toTopHierarchyLevel,
      offset: criteria.offset,
      limit: criteria.limit,
      approved: criteria.approved,
      confirmed: criteria.confirmed,
      unApproved: criteria.unApproved,
      remand: criteria.remand,
      noSubmitted: criteria.noSubmitted,
      hasWorkTime: criteria.hasWorkTime
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(Module.setCriteria(newCriteria));
  }

  if (window.location.search !== "" && projectId === "" && criteria.attendanceYearMonth === "") {
    // URLパラメータが設定されている場合はURLパラメータの値を使用する
    let urlParamStr = window.location.search.substring(1);

    let workProjectId = "";
    let workAttendanceYearMonth = "";

    urlParamStr.split('&').forEach(param => {
      const temp = param.split('=');

      if (temp[0] === "projectId") {
        workProjectId = temp[1];
      } else if (temp[0] === "attendanceYearMonth") {
        workAttendanceYearMonth = temp[1];
      }
    });

    if (Utils.isNullOrEmpty(workProjectId) || Utils.isNullOrEmpty(workAttendanceYearMonth)) {
      // 1つでも設定されていないパラメータが存在する場合
      history.push(CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY);
    } else if (!moment(workAttendanceYearMonth + '01', 'YYYYMMDD', true).isValid()) {
      // URLに設定されているパラメータがおかしい場合、エラーメッセージをセットして勤怠一覧画面に遷移する。
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_INCCORECT_PARAMETAER")
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
      history.push({
        pathname: CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY
        , state: {
          message: setMessage.message
          , messageType: setMessage.messageType
        }
      });
    }

    let urlParamValues: Model.UrlParameter = {
      projectId: workProjectId
      , attendanceYearMonth: workAttendanceYearMonth
    }

    dispatch(Module.setUrlParameterValue(urlParamValues));
  } else if (window.location.search === ""
    && criteria.attendanceYearMonth === ""
    && projectId === "") {
    history.push(CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY);
  }

  let messageAry = [];
  // 承認期限を過ぎている、かつ、未承認、確認済の勤怠を表示している場合、締切日を過ぎている旨赤字でメッセージを表示する。
  if ("" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PRIME_DETAIL === apiResult.url
    && approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_NOT_AVAILABLE) {
    if (existUnapprovedRow || existConfirmedRow) {
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_DEADLINE_PASSED_PRIME"),
        messageType: CONSTANTS.MESSAGE_TYPE_ERROR,
      }
      messageAry.push(setMessage);
    }
  }
  if (messageAry.length > 0) {
    dispatch(setMessages(messageAry));
  }

  const SelectCheckbox = (row: Model.WorkRecordDetailRow) => {
    return (
      <Checkbox
        checked={row.selectFlag}
        onChange={(e) => onChangeCheck(e, row)}
      >
      </Checkbox>
    )
  }

  /** 一覧部 */
  let columns = [
    {
      title: (
        <>
          <span>選択</span><br />
          <Checkbox
            className={styles.checkBox}
            checked={allSelect}
            onChange={(e: any) => dispatch(Module.onChangeAllSelect(e))}
            style={{ display: `${!isDisplayCheckBox() ? "none" : "inline"}` }}
          >
          </Checkbox>
        </>
      ),
      dataIndex: 'selectFlag',
      key: 'selectFlag',
      className: styles.select,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        if (approvalRoleFlag === 0
          && refferenceRoleFlag === 0
          && approvalFlag === 0) {
          return <></>
        } else if (isDisplayRowCheckBox(row)) {
          return SelectCheckbox(row);
        } else {
          return <></>
        }
      }
    },
    {
      title: '状態',
      className: styles.status,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        let label = "";
        if (isStatusForceApproved(row)) {
          label = "承認済＊";
        } else {
          switch (row.approvalStatus) {
            case CONSTANTS.ATTENDANCE_STATUS_APPROVED:
              label = "承認済";
              break;
            case CONSTANTS.ATTENDANCE_STATUS_CONFIRMED:
              label = "確認済";
              break;
            case CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED:
              label = "未承認";
              break;
            case CONSTANTS.ATTENDANCE_STATUS_REMAND:
              label = "差戻";
              break;
            case CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED:
              label = "未提出";
              break;
          }
        }
        if (isStatusConfirmed(row)) {
          // 確認済みの場合、確認日、確認者を表示
          return (
            <>
              <span>{label}</span>
              <AbolishCompanyListButton historyId={row.workRecordSubmissionHistoryId} abolishCompanyFlag={row.companyMergedAttendanceFlag} /><br />
              <span>({formatUtils.formatDateSlash(row.confirmDate)})</span><br />
              <span>{row.confirmName}</span>
            </>)
        } else if (isStatusApproved(row) || isStatusForceApproved(row)) {
          // 承認済、承認済＊の場合、承認日、承認者を表示
          return (
            <>
              <span>{label}</span>
              <AbolishCompanyListButton historyId={row.workRecordSubmissionHistoryId} abolishCompanyFlag={row.companyMergedAttendanceFlag} /><br />
              <span>({formatUtils.formatDateSlash(row.approvalDate)})</span><br />
              <span>{row.approvalName}</span>
            </>)
        } else {
          return (
            <>
              <span>{label}</span>
              <AbolishCompanyListButton historyId={row.workRecordSubmissionHistoryId} abolishCompanyFlag={row.companyMergedAttendanceFlag} />
            </>)
        }
      }

    },
    {
      title: (
        <>
          <span>提出者所属企業</span><br />
          <span>提出者氏名</span>
        </>
      ),
      className: styles.submitted,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        if (isNotSubmitted(row) || isStatusForceApprovedFromNotSubmitted(row)) {
          // 未提出、差戻、承認済＊（未提出、差戻から承認）の場合は表示しない
          return <></>
        } else {
          return (
            <div className={styles.contentLeft}>
              <div>{row.submissionCompanyName}</div>
              <div>{row.submissionName}</div>
            </div>
          );
        }
      }
    },
    {
      title: (
        <>
          <span>所属会社</span><br />
          <span>元請認定番号</span><br />
          <span>氏名</span>
        </>
      ),
      className: styles.worker,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) =>
        <>
          <div className={styles.contentLeft}>
            <span>{row.belongCompanyName}</span><br />
            <span>{row.primeCertificationNo}</span><br />
            <span>{row.name}</span>
          </div>
        </>
    },
    {
      title: '一次協力会社',
      className: styles.firstPartner,
      align: 'center' as 'center',
      render: renderfirstPartnerCompanyName
    },
    {
      title: '作業次数',
      className: styles.topHierarchyLevel,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) =>
        <>
          <div className={styles.contentRight}>
            <span>{row.topHierarchyLevel}</span>
          </div>
        </>
    },
    {
      title: '項目',
      className: styles.item,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        if (isNotSubmitted(row)) {
          return (
            {
              props: {
                colSpan: getLastDay(criteria.attendanceYearMonth) + 2,
                align: "center",
              },
              children: (<div>未提出</div>)
            }
          )
        } else if (isStatusForceApprovedFromNotSubmitted(row)) {
          return (
            {
              props: {
                colSpan: getLastDay(criteria.attendanceYearMonth) + 2,
                align: "center",
              },
              children: (<div>未提出の勤怠を条件付き承認しました。</div>)
            }
          )
        } else {
          return {
            children: (<>
              <div className={styles.totalDays}>入退場履歴</div>
              <div className={styles.totalWorking}>労働時間</div>
            </>)
          }
        }
      }
    },
    {
      title: (
        <>
          <span>日数</span><br /><br />
          <span>合計</span>
        </>
      ),
      className: styles.total,
      align: 'center' as 'center',
      render: (text: string, row: Model.WorkRecordDetailRow) => {
        if (isNotSubmitted(row) || isStatusForceApprovedFromNotSubmitted(row)) {
          return (
            {
              props: {
                colSpan: 0
              }
            }
          )
        } else {
          return (
            <>
              <div className={styles.totalDaysValue}>{row.days}</div>
              <div className={styles.totalWorkingValue}>{row.totalWorkingTime}</div>
            </>
          )
        }
      }

    },
    {
      title: '労働者出勤実績',
      className: styles.workingRecordTitle,
      align: 'center' as 'center',
      children: getDaily(criteria.attendanceYearMonth)
    }
  ];

  const callGetDetailDataListApi = () => {
    // パラメータが存在しない場合、または、パラメータの値が不正の場合はデータを取得しない
    if (criteria.attendanceYearMonth === ""
      || projectId === "") {
      return;
    } else if (!moment(criteria.attendanceYearMonth + '01', 'YYYYMMDD', true).isValid()) {
      return;
    }

    let statusList: Array<string> = [];
    if (criteria.approved) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_APPROVED);
    }
    if (criteria.confirmed) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_CONFIRMED);
    }
    if (criteria.unApproved) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED);
    }
    if (criteria.remand) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_REMAND);
    }
    if (criteria.noSubmitted) {
      statusList.push(CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED);
    }
    let params: Model.getDetailDataList.Request = {
      processType: CONSTANTS.DETAIL_PROCESS_TYPE_ATTENDANCE,
      attendanceYearMonth: criteria.attendanceYearMonth,
      projectId: projectId,
      workerId: 0,
      belongCompanyId: "",
      belongCompanyName: criteria.belongCompanyName,
      belongCompanyNameKana: criteria.belongCompanyNameKana,
      primeCertificationNo: criteria.primeCertificationNo,
      lastName: criteria.workerLastName,
      firstName: criteria.workerFirstName,
      lastNameKana: criteria.workerLastNameKana,
      firstNameKana: criteria.workerFirstNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      fromTopHierarchyLevel: criteria.fromTopHierarchyLevel,
      toTopHierarchyLevel: criteria.toTopHierarchyLevel,
      status: statusList.toString(),
      hasWorkTime: criteria.hasWorkTime === true ? "1" : "0",
      offset: criteria.offset,
      limit: criteria.limit
    }
    dispatch(Module.setManagementGroupInfo(loginUserInfo)); // 明細行の処理に必要なので設定
    dispatch(Module.getDetailDataListAsync(params));
  }

  // 勤怠実績ステータス変更APIが正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_CMN_STATUS === apiResult.url) {

    const messageText = messagesUtils.getMessageTextFromSession();
    const messageType = messagesUtils.getMessageTypeFromSession() || CONSTANTS.MESSAGE_TYPE_SUCCESS;
    if (messageText !== null) {
      const setMessage: message = {
        message: messageText
        , messageType: messageType
      }
      dispatch(setMessages([setMessage]));

      messagesUtils.clearSessionMessage();

      // 承認、確認、差戻処理完了の旨を伝えるダイアログを表示
      window.alert(messageText);
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());

    callGetDetailDataListApi();
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PRIME_DETAIL === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (criteria.offset > 0 && total > 0 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(total, criteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }

  /**
   * 勤怠年月変更日イベント
   * @param addValue 
   */
  const onChangeMonth = (addValue: number) => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let year = parseInt(criteria.attendanceYearMonth.substring(0, 4));
    let month = parseInt(criteria.attendanceYearMonth.substring(4, 6));

    // 変更後の日付インスタンスの作成
    let newDate = new Date(year, (month - 1) + addValue, 1);

    year = newDate.getFullYear();
    month = newDate.getMonth() + 1;

    criteriaDataTransfer([
      { key: "attendanceYearMonth", value: year.toString() + ("0" + month).slice(-2) }
    ]);
  }

  const searchDataList = (value: Store) => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "belongCompanyName", value: value.belongCompanyName }
      , { key: "belongCompanyNameKana", value: value.belongCompanyNameKana }
      , { key: "firstPartnerCompanyName", value: value.firstPartnerCompanyName }
      , { key: "firstPartnerCompanyNameKana", value: value.firstPartnerCompanyNameKana }
      , { key: "primeCertificationNo", value: value.primeCertificationNo }
      , { key: "workerLastName", value: value.workerLastName }
      , { key: "workerFirstName", value: value.workerFirstName }
      , { key: "workerLastNameKana", value: value.workerLastNameKana }
      , { key: "workerFirstNameKana", value: value.workerFirstNameKana }
      , { key: "fromTopHierarchyLevel", value: value.fromTopHierarchyLevel }
      , { key: "toTopHierarchyLevel", value: value.toTopHierarchyLevel }
      , { key: "offset", value: 0 }
      , { key: "approved", value: value.approved }
      , { key: "confirmed", value: value.confirmed }
      , { key: "unApproved", value: value.unApproved }
      , { key: "remand", value: value.remand }
      , { key: "noSubmitted", value: value.noSubmitted }
      , { key: "hasWorkTime", value: value.hasWorkTime }
    ]);
  }

  /**
   * チェックボックス変更時イベント
   * @param e 
   * @param row 
   */
  const onChangeCheck = (e: CheckboxChangeEvent, row: Model.WorkRecordDetailRow) => {
    let transfer: Module.changeCheckTransfer = {
      targetDataRow: row,
      value: e.target.checked
    }

    dispatch(Module.onChangeRowSelect(transfer));
  }

  /**
   * テーブル検索条件変更時イベント（ページ、表示件数）
   * @param pagination 
   * @param filters 
   */
  const handleTableChange = (pagination: any, filters: any) => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let offsetReset: boolean = false;

    if (criteria.limit !== pagination.pageSize) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
    ])
  }

  /**
   * 条件付き承認チェックボックス変更時イベント
   * @param e 
   */
  const onChecked = (e: any) => {
    dispatch(Module.setForceApprovalChecked(e.target.checked));
  }


  /**
   * 戻るボタン押下
   */
  const onClickBack = () => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    history.push(CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY);
  }

  /**
   * 各種処理ボタン押下
   * @param processType 処理種別 
   */
  const onClickProcess = (processType: CONSTANTS.ATTENDANCE_PROCESS_TYPE, comment: string) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let args = "";

    // 処理タイプ毎にメッセージを取得
    switch (processType) {
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.APPROVAL:
        args = "承認";
        break;
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.CONFIRM:
        args = "確認";
        break;
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.REMAND:
        args = "差戻";
        break;
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.APPROVAL_CANCEL:
        args = "承認取消";
        break;
      case CONSTANTS.ATTENDANCE_PROCESS_TYPE.FORCE_APPROVAL:
        args = "条件付き承認";
        break;
      default:
        return;
    }

    // 処理タイプが差戻 かつ コメントが制限文字数を超えている場合はエラーメッセージを表示
    if (processType == CONSTANTS.ATTENDANCE_PROCESS_TYPE.REMAND
      && comment.length > CONSTANTS.COMMENT_MAX_LENGTH) {
      return;
    }

    // 確認ダイアログの表示
    // 条件付き承認は別途確認ダイアログを表示済みなので、ここでは表示しない
    if (processType !== CONSTANTS.ATTENDANCE_PROCESS_TYPE.FORCE_APPROVAL) {
      if (!window.confirm(messagesUtils.getMessage("CONFIRM_ATTENDANCE_PROCESS", [args]))) {
        return;
      }
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());

    // リクエストデータの作成
    let destList: Array<Model.putStatus.Dest> = [];
    dataList.forEach((data: Model.WorkRecordDetailRow) => {
      if (data.selectFlag) {
        // 選択されている
        let dest: Model.putStatus.Dest = {
          workRecordSubmissionHistoryId: data.workRecordSubmissionHistoryId,
          attendanceYearMonth: criteria.attendanceYearMonth,
          belongCompanyId: data.belongCompanyId,
          workerId: data.workerId,
          projectId: projectId,
          firstCompanyId: data.firstPartnerCompanyList[0].firstPartnerCompanyId,
          updateDatetime: data.updateDatetime
        }

        destList.push(dest);
      }
    });

    // メッセージをセッションストレージに保管
    let message = messagesUtils.getMessage("SUCCESS_ATTENDANCE_PROCESS", [args]);
    messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_SUCCESS);

    let putData: Model.putStatus.Request = {
      processType: processType,
      destList: destList,
      comment: comment
    }

    dispatch(Module.putAttendanceStatusAsync(putData));
    if (modalVisibleState) {
      hideCommentModal();
    }
    if (modalForceApprovalVisibleState) {
      hideForceApprovalModal();
    }
  }

  // コメントモーダル表示アクション
  const showCommentModal = () => {

    // 提出期限を過ぎている場合は差戻期限切れダイアログを表示
    if (editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_NOT_AVAILABLE) {
      showRemandExpiredModal();
      return;
    }

    dispatch(Module.reflectModalState(true));
  }

  // コメントモーダル非表示アクション
  const hideCommentModal = () => {
    // コメント初期化
    dispatch(CommentModule.initComment());
    // モーダル非表示
    dispatch(Module.reflectModalState(false));
  }

  // 差戻期限切れモーダル表示アクション
  const showRemandExpiredModal = () => {
    dispatch(Module.reflectModalRemandExpiredState(true));
  }

  // 差戻期限切れモーダル非表示アクション
  const hideRemandExpiredModal = () => {
    // コメント初期化
    dispatch(CommentModule.initComment());
    // モーダル非表示
    dispatch(Module.reflectModalRemandExpiredState(false));
  }

  // 条件付き承認確認モーダル表示アクション
  const showForceApprovalModal = () => {
    dispatch(Module.reflectModalForceApprovalState(true));
  }

  // 条件付き承認確認モーダル非表示アクション
  const hideForceApprovalModal = () => {
    // コメント初期化
    dispatch(CommentModule.initComment());
    // チェック状態を初期化
    dispatch(Module.setForceApprovalChecked(false));
    // モーダル非表示
    dispatch(Module.reflectModalForceApprovalState(false));
  }

  // 検索項目の次数のチェック 正常：true
  const validateCriteriaHierarchy = (value: string): boolean => {
    if (!value) {
      // 未入力はOK
      return true;
    }

    let level = parseInt(value, 10);
    if (level < CONSTANTS.TOP_HIERARCHY_LEVEL_MIN_RANGE || level > CONSTANTS.TOP_HIERARCHY_LEVEL_MAX_RANGE) {
      return false;
    }
    return true;
  }

  /** 検索部 */
  const Criteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={searchDataList}
        initialValues={{
          "belongCompanyName": criteria.belongCompanyName
          , "belongCompanyNameKana": criteria.belongCompanyNameKana
          , "firstPartnerCompanyName": criteria.firstPartnerCompanyName
          , "firstPartnerCompanyNameKana": criteria.firstPartnerCompanyNameKana
          , "primeCertificationNo": criteria.primeCertificationNo
          , "workerLastName": criteria.workerLastName
          , "workerFirstName": criteria.workerFirstName
          , "workerLastNameKana": criteria.workerLastNameKana
          , "workerFirstNameKana": criteria.workerFirstNameKana
          , "fromTopHierarchyLevel": criteria.fromTopHierarchyLevel
          , "toTopHierarchyLevel": criteria.toTopHierarchyLevel
          , "approved": criteria.approved
          , "confirmed": criteria.confirmed
          , "unApproved": criteria.unApproved
          , "remand": criteria.remand
          , "noSubmitted": criteria.noSubmitted
          , "hasWorkTime": criteria.hasWorkTime
        }}>
        <div style={{ float: "right" }}>
          <div className={styles.criteriaInputField}>
            <CriteriaForm.Item name="belongCompanyName" id="belongCompanyName">
              <Input
                type="text"
                placeholder="所属会社名"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="belongCompanyNameKana" id="belongCompanyNameKana">
              <Input
                type="text"
                placeholder="所属会社名カナ"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="firstPartnerCompanyName" id="firstPartnerCompanyName">
              <Input
                type="text"
                placeholder="一次協力会社名"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="firstPartnerCompanyNameKana" id="firstPartnerCompanyNameKana">
              <Input
                type="text"
                placeholder="一次協力会社名カナ"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="primeCertificationNo" id="primeCertificationNo">
              <Input
                type="text"
                placeholder="元請認定番号"
                maxLength={CONSTANTS.PRIME_CERTIFICATION_NO_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="workerLastName" id="workerLastName">
              <Input
                type="text"
                placeholder="氏名（姓）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="workerFirstName" id="workerFirstName">
              <Input
                type="text"
                placeholder="氏名（名）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="workerLastNameKana" id="workerLastNameKana">
              <Input
                type="text"
                placeholder="氏名カナ（姓）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="workerFirstNameKana" id="workerFirstNameKana">
              <Input
                type="text"
                placeholder="氏名カナ（名）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
              />
            </CriteriaForm.Item>

            <div className={styles.duration}>
              <div >
                <CriteriaForm.Item name="fromTopHierarchyLevel" id="fromTopHierarchyLevel"
                  rules={[
                    {
                      pattern: validationRules.seisu,
                      message: messagesUtils.getMessage("ERROR_TOP_HIERARCHY_LEVEL_RANGE")
                    },
                    ({ getFieldValue, validateFields }) => ({
                      validator(rule, value) {
                        if (!validateCriteriaHierarchy(value)) {
                          return Promise.reject(messagesUtils.getMessage("ERROR_TOP_HIERARCHY_LEVEL_RANGE"));
                        }

                        // From、To のチェック（To側のバリデーターでチェック）
                        if (getFieldValue("toTopHierarchyLevel") !== null) {
                          validateFields(["toTopHierarchyLevel"]);
                        }

                        return Promise.resolve();
                      },
                    })
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="次数（From）"
                    maxLength={CONSTANTS.TOP_HIERARCHY_LEVEL_MAX_LENGTH}
                    className={styles.topHierarchyLevelDuration}
                  />
                </CriteriaForm.Item>
                <div className={styles.durationSpan}>～</div>
                <CriteriaForm.Item name="toTopHierarchyLevel" id="toTopHierarchyLevel"
                  rules={[
                    {
                      pattern: validationRules.seisu,
                      message: messagesUtils.getMessage("ERROR_TOP_HIERARCHY_LEVEL_RANGE")
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!validateCriteriaHierarchy(value)) {
                          return Promise.reject(messagesUtils.getMessage("ERROR_TOP_HIERARCHY_LEVEL_RANGE"));
                        }

                        // From > To の場合、エラー
                        // From、Toがともに正常の場合、チェックする
                        let fromValue = getFieldValue("fromTopHierarchyLevel");
                        if (validateCriteriaHierarchy(fromValue)) {
                          let fromLevel = parseInt(fromValue, 10);
                          let toLevel = parseInt(value, 10);
                          if (fromLevel > toLevel) {
                            return Promise.reject(messagesUtils.getMessage("ERROR_FROM_TO_NUMBER_RANGE", ["次数（From）", "次数（To）"]));
                          }
                        }

                        return Promise.resolve();
                      },
                    })
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="次数（To）"
                    maxLength={CONSTANTS.TOP_HIERARCHY_LEVEL_MAX_LENGTH}
                    className={styles.topHierarchyLevelDuration}
                  />
                </CriteriaForm.Item>
              </div>
            </div>
          </div>

          <div className={styles.statusWorkTimeGrid}>
            <div className={`${styles.statusWorkTimeTopLeft} ${styles.statusWorkTimeLeft}`}>
              <span>提出-承認ステータス：</span>
            </div>
            <div className={`${styles.statusWorkTimeTopRight} ${styles.statusWorkTimeRight}`}>
              <CriteriaForm.Item name="approved" id="approved" valuePropName="checked">
                <Checkbox className={styles.checkBox}>
                  承認済
                </Checkbox>
              </CriteriaForm.Item>
              <CriteriaForm.Item name="confirmed" id="confirmed" valuePropName="checked">
                <Checkbox className={styles.checkBox}>
                  確認済
                </Checkbox>
              </CriteriaForm.Item>
              <CriteriaForm.Item name="unApproved" id="unApproved" valuePropName="checked">
                <Checkbox className={styles.checkBox}>
                  未承認
                </Checkbox>
              </CriteriaForm.Item>
              <CriteriaForm.Item name="remand" id="remand" valuePropName="checked">
                <Checkbox className={styles.checkBox}>
                  差戻
                </Checkbox>
              </CriteriaForm.Item>
              <CriteriaForm.Item name="noSubmitted" id="noSubmitted" valuePropName="checked">
                <Checkbox className={styles.checkBox}>
                  未提出
                </Checkbox>
              </CriteriaForm.Item>
            </div>
            <div className={`${styles.statusWorkTimeBottomLeft} ${styles.statusWorkTimeLeft}`}>
              <span>労働時間：</span>
            </div>
            <div className={`${styles.statusWorkTimeBottomRight} ${styles.statusWorkTimeRight}`}>
              <CriteriaForm.Item name="hasWorkTime" valuePropName="checked">
                <Checkbox>
                  対象月の合計労働時間が0時間の作業員を除く
                </Checkbox>
              </CriteriaForm.Item>
            </div>
          </div>

          <AuthButton
            name={"検索"}
            shape={"round"}
            htmlType="submit"
          />
        </div>

      </CriteriaForm>
    )
  }

  /** 一覧ヘッダー部 */
  const AttendanceDetailHeader = () => {
    return (

      <div style={{ whiteSpace: "nowrap" }} className={styles.searchGroup}>
        <div className={styles.headerTitle}>勤怠年月：</div>
        <div>
          <label style={{ color: "#006a5d", margin: "4px" }} onClick={(e) => onChangeMonth(-1)}><LeftSquareFilled /></label>
          {criteria.attendanceYearMonth.substring(0, 4)}年{parseInt(criteria.attendanceYearMonth.substring(4, 6))}月
          <label style={{ color: "#006a5d", margin: "4px" }} onClick={(e) => onChangeMonth(1)}><RightSquareFilled /></label>
        </div>

        <div className={styles.headerTitle} style={{ marginLeft: "20px" }}>プロジェクト：</div>
        <div>{projectName}</div>

      </div>


    )
  }

  /** ボタン部 */
  const AttendanceButton = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"戻る"}
          size={"large"}
          shape={"round"}
          onClick={onClickBack}
          className={AuthButtonStyles.backButtonFixed}
          style={{ margin: "10px" }}
        />

        {approvalRoleFlag === 1 || approvalFlag === 1 ?
          <AuthButton
            name={"承認"}
            authorities={[
              AuthButtonAuthorities.primeAttendanceApprovalAuth
              , AuthButtonAuthorities.primeAttendanceApprovalRoleAuth
            ]}
            size={"large"}
            shape={"round"}
            onClick={() => onClickProcess(CONSTANTS.ATTENDANCE_PROCESS_TYPE.APPROVAL, "")}
            className={AuthButtonStyles.authButtonFixed}
            disabled={!isEnableSubmitButton()}
            style={{ margin: "10px" }}
          /> : <></>
        }

        {approvalRoleFlag === 0 && refferenceRoleFlag === 1 ?
          <AuthButton
            name={"確認"}
            authorities={[
              AuthButtonAuthorities.primeAttendanceReffenceRoleAuth
            ]}
            size={"large"}
            shape={"round"}
            onClick={() => onClickProcess(CONSTANTS.ATTENDANCE_PROCESS_TYPE.CONFIRM, "")}
            className={AuthButtonStyles.authButtonFixed}
            disabled={!isEnableConfirmButton()}
            style={{ margin: "10px" }}
          /> : <></>
        }

        {approvalRoleFlag === 1 || approvalFlag === 1 || refferenceRoleFlag === 1 ?
          <AuthButton
            name={"差戻"}
            authorities={[
              AuthButtonAuthorities.primeAttendanceApprovalAuth
              , AuthButtonAuthorities.primeAttendanceApprovalRoleAuth
              , AuthButtonAuthorities.primeAttendanceReffenceRoleAuth
            ]}
            size={"large"}
            shape={"round"}
            onClick={showCommentModal}
            className={AuthButtonStyles.authButtonFixed}
            disabled={!isEnableRemandButton()}
            style={{ margin: "10px" }}
          /> : <></>
        }

        {approvalRoleFlag === 1 || approvalFlag === 1 ?
          <AuthButton
            name={"承認取消"}
            authorities={[
              AuthButtonAuthorities.primeAttendanceApprovalAuth
              , AuthButtonAuthorities.primeAttendanceApprovalRoleAuth
            ]}
            size={"large"}
            shape={"round"}
            onClick={() => onClickProcess(CONSTANTS.ATTENDANCE_PROCESS_TYPE.APPROVAL_CANCEL, "")}
            className={AuthButtonStyles.authButtonFixed}
            disabled={!isEnableApproveCancelButton()}
            style={{ margin: "10px" }}
          /> : <></>
        }


        {approvalRoleFlag === 1 || approvalFlag === 1 ?
          <AuthButton
            name={"条件付き承認"}
            authorities={[
              AuthButtonAuthorities.primeAttendanceApprovalAuth
              , AuthButtonAuthorities.primeAttendanceApprovalRoleAuth
            ]}
            size={"large"}
            shape={"round"}
            onClick={showForceApprovalModal}
            className={AuthButtonStyles.authButtonFixed}
            disabled={!isEnableForceApproveButton()}
            style={{ display: `${!isDisplayForceApproveButton() ? "none" : ""}`, margin: "10px 10px 10px 15px" }}
          /> : <></>
        }
      </FooterButtonArea>
    );
  }

  /**
   * ページングボタンを描画
   * @param page 
   * @param type 
   * @param originalElement 
   */
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <AuthButton name='＜' size='small' />;
    }
    if (type === 'next') {
      return <AuthButton name='＞' size='small' />;
    }
    return originalElement
  }

  return (

    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <Criteria />
        <CommonMessage searchNoDataName="作業員" />
        <AttendanceDetailHeader />
        {0 < total && 0 < dataList.length ?
          <>
            {approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_NOT_AVAILABLE && !existUnapprovedRow && !existConfirmedRow && existApprovalRow ?
              <>
                <div style={{ float: "left" }}>承認済の勤怠情報の操作が必要な場合は、貴社のスキルマップサイトの管理担当者にご連絡ください。</div>
                <br />
              </>
              : <></>}
            <div style={{ float: "left" }}>「一次協力会社」の下で異なる複数の編成（次数）に入っている場合、「作業次数」欄にはもっとも上位の次数が表示されます。</div>
            <div style={{ float: "right" }}><span className={styles.explainGray}>　　　</span>...該当の日付において資格条件に合致せず、認定対象外となる勤怠です。</div>
            <Table
              size="small"
              className="hoverNotBackground"
              columns={columns}
              dataSource={dataList}
              pagination={{
                total: total
                , defaultPageSize: 10
                , current: criteria.offset / criteria.limit + 1
                , pageSize: criteria.limit
                , pageSizeOptions: ['5', '10', '20', '50', '100']
                , showSizeChanger: true
                , showTotal: (total, range) => `${total}件中、${criteria.offset + 1}件目から${total <= criteria.offset + criteria.limit ? total : criteria.offset + criteria.limit}件目を表示`
                , itemRender: pageItemRender
                , locale: { items_per_page: '件/ページ' }
              }}
              onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters)}
            >
            </Table>
          </>
          : <></>}
        <MergeCompanyAnnotations
          attendanceList={dataList}
          mergeCompanyAttendanceFlgName={"companyMergedAttendanceFlag"}
        />
      </Content>
      <AttendanceButton />
      <Footer />

      {/* 差戻確認ダイアログ */}
      <Modal
        visible={modalVisibleState}
        bodyStyle={{ height: "410px" }}
        footer={
          <>
            <AuthButton
              name={"戻る"}
              size={"large"}
              shape={"round"}
              onClick={hideCommentModal}
              className={AuthButtonStyles.backButtonFixed}
              style={{ margin: "10px" }}
            />
            <AuthButton
              name={"差戻"}
              size={"large"}
              shape={"round"}
              onClick={() => onClickProcess(CONSTANTS.ATTENDANCE_PROCESS_TYPE.REMAND, comment)}
              className={AuthButtonStyles.authButtonFixed}
              style={{ margin: "10px" }}
            />
          </>
        }
        destroyOnClose
        closable={false}
        width={1000}
      >
        <Comment
          title="差戻確認"
          message="差戻します。よろしいでしょうか？"
        />
      </Modal>

      {/* 差戻期限切れダイアログ */}
      <Modal
        visible={modalRemandExpiredVisibleState}
        bodyStyle={{ height: "160px" }}
        footer={
          <>
            <AuthButton
              name={"閉じる"}
              size={"large"}
              shape={"round"}
              onClick={hideRemandExpiredModal}
              className={AuthButtonStyles.backButtonFixed}
              style={{ margin: "10px" }}
            />
          </>
        }
        destroyOnClose
        closable={false}
        width={620}
      >
        <Layout className={styles.layout}>
          <Content>
            <Title level={4} className={styles.functionTitle}>
              差戻不可
            </Title>
            <h1>
              協力会社側での勤怠提出期限を過ぎており、差し戻しても再提出ができないため、差し戻しできません。
            </h1>
          </Content>
        </Layout>
      </Modal>

      {/* 条件付き承認確認ダイアログ */}
      <Modal
        visible={modalForceApprovalVisibleState}
        bodyStyle={{ height: "200px" }}
        footer={
          <>
            <AuthButton
              name={"いいえ"}
              size={"large"}
              shape={"round"}
              onClick={hideForceApprovalModal}
              className={AuthButtonStyles.backButtonFixed}
              style={{ margin: "10px" }}
            />
            <AuthButton
              name={"はい"}
              size={"large"}
              shape={"round"}
              onClick={() => onClickProcess(CONSTANTS.ATTENDANCE_PROCESS_TYPE.FORCE_APPROVAL, "")}
              className={AuthButtonStyles.authButtonFixed}
              style={{ margin: "10px" }}
              disabled={!selectForceApprovalChecked}
            />
          </>
        }
        destroyOnClose
        closable={false}
        width={450}
      >
        <Layout className={styles.layout}>
          <Content>
            <Title level={4} className={styles.functionTitle}>
              条件付き承認確認
            </Title>
            処理期限が切れた勤怠を承認しようとしています。<br />
            承認してもよろしいですか？<br />
            <div className={styles.forceCheckBoxArea}>
              <Checkbox
                className={styles.checkBox}
                onChange={(e) => { onChecked(e) }}
              >
                はい、期限切れ勤怠を承認することを了承します。
              </Checkbox>
            </div>
          </Content>
        </Layout>
      </Modal>
    </Layout>
  );
}


