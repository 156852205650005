import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Layout, Spin } from 'antd';
import { AuthButton, Checkbox } from '../core/CoreForm';
import { Header, Footer, FunctionTitle, ContentEx } from '../core/CorePageContents';
import { getLoginUserInfoAsync } from "../../modules/core/authModule";
import * as Module from "../../modules/core/termOfServiceModule";
import styles from './css/TermsOfService.module.css'
import { isPrimeMenu, storedCurrentMenuItemId } from "../../modules/core/menuModule";
import * as CONSTANTS from '../../constants/constants';
import { storedApiResult, initApiResult } from '../../modules/core/bffApiModule';

const { Content } = Layout;

interface titleInterface {
  title: string;
}

// チェックボックス
const CHECKBOX_CHECK = "1";
const CHECKBOX_NONE = "0";

export const TermsOfService = (props: titleInterface) => {

  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  const htmlString = useSelector(Module.serviceTermsHtmlState);
  const termsVersion = useSelector(Module.serviceTermsVersionState);
  const termsCheck = useSelector(Module.serviceTermsCheckState);
  const isPrime = useSelector(isPrimeMenu);
  const currentMenuItemId = useSelector(storedCurrentMenuItemId);
  const apiResult = useSelector(storedApiResult);

  useEffect(() => {
    if (currentMenuItemId !== "") {
      // 約款画面の初期化
      dispatch(Module.initTermOfService());
      const param = {
        // 契約種別
        contractKind: isPrime ? CONSTANTS.CONTRACT_TYPE_PRIME : CONSTANTS.CONTRACT_TYPE_PARTNER
      }
      // 約款情報取得API呼び出し
      dispatch(Module.getTermOfServiceAsync(param));
    }
  }, [currentMenuItemId]);

  // 同意ボタン押下時処理
  const onClickAgree = () => {

    // API結果情報を初期化します。
    dispatch(initApiResult());

    const param: Module.putRequestData = {
      // 契約種別
      contractType: isPrime ? CONSTANTS.CONTRACT_TYPE_PRIME : CONSTANTS.CONTRACT_TYPE_PARTNER
      // サービス利用約款バージョン
      , serviceTermsVersion: termsVersion
    }
    // 約款同意API呼び出し
    dispatch(Module.putTermOfServiceAsync(param));
  }

  // 約款同意API実行後、APIエラーメッセージが設定されていない場合、ログインユーザ情報を再取得し、画面遷移を行います。
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod) {
    // ログインユーザ情報を再取得します。
    dispatch(getLoginUserInfoAsync());
    dispatch(initApiResult());
    history.push(location.pathname);
  }

  // CheckBox変更時処理
  const onChangeCheck = (value: any) => {
    // チェックボックスのステートを変更します。
    dispatch(Module.setTermOfServiceCheck(value.target.checked ? CHECKBOX_CHECK : CHECKBOX_NONE));
  }

  // 約款本文HTMLを生成します。
  const generateTermsOfService = () => {
    return { __html: htmlString };
  }

  let className = "";
  if (isPrime) {
    className = styles.loadingPrimeMessage;
  } else {
    className = styles.loadingPartnerMessage;
  }

  // 同意ボタンを生成します。
  const AgreeButton = () => {
    if (htmlString === "") {
      return (
        <Spin indicator={<></>}>
          <div className={styles.buttonArea}>
            <div className={styles.checkbox}>
              <Checkbox checked={termsCheck !== CHECKBOX_NONE} onChange={onChangeCheck}>
                「スキルマップサイトサービス 利用約款」に同意します。
              </Checkbox>
            </div>

            <div>
              <AuthButton
                name="確認"
                size={"large"}
                shape={"round"}
                disabled={termsCheck === CHECKBOX_NONE}
                onClick={onClickAgree}
              />
            </div>
          </div>
        </Spin>
      );
    }
    return (
      <div className={styles.buttonArea}>
        <div className={styles.checkbox}>
          <Checkbox checked={termsCheck !== CHECKBOX_NONE} onChange={onChangeCheck}>
            「スキルマップサイトサービス 利用約款」に同意します。
          </Checkbox>
        </div>

        <div>
          <AuthButton
            name="確認"
            size={"large"}
            shape={"round"}
            disabled={termsCheck === CHECKBOX_NONE}
            onClick={onClickAgree}
          />
        </div>
      </div>
    );
  }

  // 同意ボタンを生成します。
  const SpinContent = () => {
    if (htmlString === "") {
      return (
        <Spin tip="Now Loading..." className={className} wrapperClassName={styles.loading}>
          <FunctionTitle title={props.title} />
        </Spin>
      );
    }
    return (
      <>
        <FunctionTitle title={props.title} />
        <div className={styles.innerHTML}>
          <div dangerouslySetInnerHTML={generateTermsOfService()} />
        </div>
      </>
    );
  }

  return (
    <Layout>
      <Header />
      <ContentEx>
        <SpinContent />
      </ContentEx>
      <AgreeButton />
      <Footer />
    </Layout>
  );
}
