import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRequest, apiSuccessHandler, apiErrorHandler, setApiResult } from '../../core/bffApiModule'
import { AppThunk, RootState } from '../../../store/store';
import * as CONSTANTS from '../../../constants/constants';
import * as Model from './workerAttendanceModel';
import moment, { Moment } from 'moment';
import * as formatUtils from '../../../utils/formatUtils';

// 初期設定の日付を計算
const setDate = (num: number) => {
  const nowMoment: Moment | undefined = moment(new Date(), moment.ISO_8601);
  return nowMoment === undefined ? "" : nowMoment.add(num, "month").format("YYYYMM");
}

// 元請勤怠実績データ初期化
const initialState: Model.WorkRecordPrimeState = {
  dataList: []
  , managementGroupList: []
  , total: 0
  , workRecordWorkerSummaryCriteria: {
    managementGroupId: ""
    , belongCompanyName: ""
    , belongCompanyNameKana: ""
    , firstPartnerCompanyName: ""
    , firstPartnerCompanyNameKana: ""
    , lastName: ""
    , firstName: ""
    , lastNameKana: ""
    , firstNameKana: ""
    , fromYYYYMM: setDate(-1)
    , toYYYYMM: setDate(0)
    , hasWorkTime: "0"
    , sortItem: ""
    , sortOrder: ""
    , offset: 0
    , limit: 10
  }
  , detailDataList: []
  , detailTotal: 0
  , projectId: ""
  , projectName: ""
  , belongCompanyId: ""
  , belongCompanyName: ""
  , workerId: 0
  , name: ""
  , primeCertificationNo: ""
  , managementGroupId: ""
  , managementGroupName: ""
  , workRecordWorkerDetailCriteria: {
    processType: ""
    , attendanceYearMonth: ""
    , projectId: ""
    , workerId: 0
    , belongCompanyId: ""
    , belongCompanyName: ""
    , belongCompanyNameKana: ""
    , primeCertificationNo: ""
    , lastName: ""
    , firstName: ""
    , lastNameKana: ""
    , firstNameKana: ""
    , firstPartnerCompanyName: ""
    , firstPartnerCompanyNameKana: ""
    , workTime: ""
    , status: ""
    , offset: 0
    , limit: 100
  }
  , qualificationListTotal: 0
  , qualificationListSubmit: []
  , qualificationModalVisibleState: false
};

export const workerAttendanceSlice = createSlice({
  name: 'workerAttendanceSlice',
  initialState,
  reducers: {
    // -------------
    // 作業者別勤怠一覧
    // -------------

    prepareMoveToDetail: (state, action: PayloadAction<Model.WorkRecordWorkerSummaryRow>) => {
      state.workRecordWorkerDetailCriteria.attendanceYearMonth = formatUtils.formatMonthNonSlash(action.payload.attendanceYearMonth)
      state.belongCompanyId = action.payload.belongCompanyId
      state.workerId = Number.parseInt(action.payload.workerId);
      state.managementGroupId = action.payload.managementGroupName
      state.workRecordWorkerDetailCriteria.belongCompanyId = action.payload.belongCompanyId
      state.workRecordWorkerDetailCriteria.workerId = Number.parseInt(action.payload.workerId);
    },

    initDataList: (state) => {
      state.dataList = initialState.dataList;
    },

    /**
     * 検索条件の初期化
     */
    initCriteria: (state) => {
      let workRecordWorkerSummaryCriteria = {
        managementGroupId: ""
        , belongCompanyName: ""
        , belongCompanyNameKana: ""
        , firstPartnerCompanyName: ""
        , firstPartnerCompanyNameKana: ""
        , lastName: ""
        , firstName: ""
        , lastNameKana: ""
        , firstNameKana: ""
        , fromYYYYMM: setDate(-1)
        , toYYYYMM: setDate(0)
        , hasWorkTime: "0"
        , submitted: ""
        , confirmed: ""
        , approved: ""
        , sortItem: ""
        , sortOrder: ""
        , offset: 0
        , limit: 10
      }

      let workRecordWorkerDetailCriteria = {
        processType: ""
        , attendanceYearMonth: ""
        , projectId: ""
        , workerId: ""
        , belongCompanyId: ""
        , belongCompanyName: ""
        , belongCompanyNameKana: ""
        , primeCertificationNo: ""
        , lastName: ""
        , firstName: ""
        , lastNameKana: ""
        , firstNameKana: ""
        , firstPartnerCompanyName: ""
        , firstPartnerCompanyNameKana: ""
        , hasWorkTime: "0"
        , status: ""
        , offset: 0
        , limit: 100
      }
      return Object.assign({}, state, { workRecordWorkerSummaryCriteria: workRecordWorkerSummaryCriteria }, { workRecordWorkerDetailCriteria: workRecordWorkerDetailCriteria });
    },


    // Use the PayloadAction type to declare the contents of `action.payload`
    setDataListOfWorkRecordWorkerSummary: (state, action: PayloadAction<Model.AttendanceWorkerRecordApiData>) => {

      state.dataList = initialState.dataList;

      const responseAttendanceWorkersSummaryList: Model.WorkRecordWorkerSummaryRow[] = action.payload.attendanceWorkersSummaryList || [];
      let attendanceRecordList: Array<Model.WorkRecordWorkerSummaryRow> = [];
      responseAttendanceWorkersSummaryList.forEach((attendanceRecord: Model.WorkRecordWorkerSummaryRow) => {

        const workRec: Model.WorkRecordWorkerSummaryRow = {
          key: attendanceRecord.workerId.toString()
          , managementGroupName: attendanceRecord.managementGroupName
          , belongCompanyId: attendanceRecord.belongCompanyId
          , belongCompanyName: attendanceRecord.belongCompanyName
          , workerId: attendanceRecord.workerId
          , primeCertificationNo: attendanceRecord.primeCertificationNo
          , name: attendanceRecord.name
          , attendanceYearMonth: attendanceRecord.attendanceYearMonth
          , firstPartnerCompanyList: attendanceRecord.firstPartnerCompanyList
        };
        attendanceRecordList.push(workRec);
      });

      state.dataList = attendanceRecordList;

      const responseManagementGroupList: Model.ManagementGroupListRow[] = action.payload.managementGroupList || [];
      let managementRecordList: Array<Model.ManagementGroupListRow> = [];
      responseManagementGroupList.forEach((managementRecord: Model.ManagementGroupListRow) => {

        const manageRec: Model.ManagementGroupListRow = {
          managementGroupId: managementRecord.managementGroupId
          , managementGroupName: managementRecord.managementGroupName
        };
        managementRecordList.push(manageRec);
      });

      state.managementGroupList = managementRecordList;
      state.total = action.payload.total
    },

    /**
 * 検索条件のstate更新
 */
    setCriteria: (state, action: PayloadAction<Model.WorkRecordWorkerSummaryCriteria>) => {
      let criteria: Model.WorkRecordWorkerSummaryCriteria = {
        managementGroupId: action.payload.managementGroupId,
        belongCompanyName: action.payload.belongCompanyName,
        belongCompanyNameKana: action.payload.belongCompanyNameKana,
        firstPartnerCompanyName: action.payload.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana,
        lastName: action.payload.lastName,
        firstName: action.payload.firstName,
        lastNameKana: action.payload.lastNameKana,
        firstNameKana: action.payload.firstNameKana,
        fromYYYYMM: action.payload.fromYYYYMM,
        toYYYYMM: action.payload.toYYYYMM,
        hasWorkTime: action.payload.hasWorkTime,
        sortItem: action.payload.sortItem,
        sortOrder: action.payload.sortOrder,
        offset: action.payload.offset,
        limit: action.payload.limit,
      }
      return Object.assign({}, state, { workRecordWorkerSummaryCriteria: criteria })
    },

    /**
       * 一覧レコードのセット
       */
    setDetailDataListOfWorkRecordPrime: (state, action: PayloadAction<Model.getDetailDataList.Response>) => {
      let userWorkStatusList: Array<Model.WorkRecordWorkerDetailRow> = [];

      let total = action.payload.total;

      let belongCompanyId = "";
      let belongCompanyName = "";
      let workerId = 0;
      let name = "";
      let primeCertificationNo = "";
      let managementGroupId = "";
      let managementGroupName = "";
      let summaryTotalWorkingTime = 0;
      let originalQualificationList: Model.OriginalQualificationListRow[] = [];

      managementGroupId = action.payload.managementGroupId;
      managementGroupName = action.payload.managementGroupName;
      belongCompanyId = action.payload.belongCompanyId;
      belongCompanyName = action.payload.belongCompanyName;
      workerId = Number.parseInt(action.payload.workerId);
      name = action.payload.name;
      primeCertificationNo = action.payload.primeCertificationNo;

      // 作業者データが取得できなかった場合はreturn
      if (action.payload.userWorkStatusList.length === 0) {
        return Object.assign(
          {}, state,
          { detailTotal: total },
          { detailDataList: userWorkStatusList },
          { belongCompanyId: belongCompanyId },
          { belongCompanyName: belongCompanyName },
          { workerId: workerId },
          { name: name },
          { primeCertificationNo: primeCertificationNo },
          { managementGroupId: managementGroupId },
          { managementGroupName: managementGroupName },
        )
      }

      action.payload.userWorkStatusList.forEach((userWorkStatus: Model.getDetailDataList.UserWorkStatusList, index: number) => {
        if (index == 0) {
          originalQualificationList = userWorkStatus.originalQualificationList;
        }

        userWorkStatus.projectWorkStatusList.forEach((projectWorkStatus: Model.getDetailDataList.ProjectWorkStatusList) => {

          // 労働日数、労働時間の算出
          let days = 0;
          let totalWorkingTime = 0;
          let totalWorkingTimeStr = "";
          if (projectWorkStatus.approvalStatus !== CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED && projectWorkStatus.approvalStatus !== CONSTANTS.ATTENDANCE_STATUS_REMAND) {
            projectWorkStatus.dailyWorkStatusList.forEach(dailyWorkStatus => {
              let workingTime = formatUtils.formatTime(dailyWorkStatus.workingTime);

              if (workingTime === "" || workingTime === "0:00") {
                // 労働時間が設定されていない場合は処理しない
                return;
              }

              // 労働日数を加算
              days++;

              // 労働時間を加算
              let hour = workingTime.split(":")[0];
              let minutes = workingTime.split(":")[1];

              totalWorkingTime += dailyWorkStatus.meetQualificationRequirementType !== CONSTANTS.QUALIFICATION_REQUIREMENT_TYPE.OUTSIDE_VALID_PERIOD.code ? ((parseInt(hour) * 60 * 60) + (parseInt(minutes) * 60)) : 0;
            });
          }

          // 時
          let hourStr = Math.floor(totalWorkingTime / 3600);
          // 分
          let minutesStr = Math.ceil((totalWorkingTime - hourStr * 3600) / 60);

          totalWorkingTimeStr = hourStr + ":" + ("0" + minutesStr).slice(-2);

          const rec: Model.WorkRecordWorkerDetailRow = {
            key: projectWorkStatus.workRecordSubmissionHistoryId
            , projectId: projectWorkStatus.workRecordSubmissionHistoryId
            , projectName: projectWorkStatus.projectName
            , workRecordSubmissionHistoryId: projectWorkStatus.workRecordSubmissionHistoryId
            , approvalStatus: projectWorkStatus.approvalStatus
            , forceApprovalFlag: projectWorkStatus.forceApprovalFlag
            , submissionCompanyName: projectWorkStatus.submissionCompanyName
            , submissionMcdpId: projectWorkStatus.submissionMcdpId
            , submissionName: projectWorkStatus.submissionName
            , confirmDate: projectWorkStatus.confirmDate
            , confirmMcdpId: projectWorkStatus.confirmMcdpId
            , confirmName: projectWorkStatus.confirmName
            , approvalDate: projectWorkStatus.approvalDate
            , approvalMcdpId: projectWorkStatus.approvalMcdpId
            , approvalName: projectWorkStatus.approvalName
            , belongCompanyId: projectWorkStatus.belongCompanyId
            , belongCompanyName: projectWorkStatus.belongCompanyName
            , firstPartnerCompanyList: projectWorkStatus.firstPartnerCompanyList
            , topHierarchyLevel: projectWorkStatus.topHierarchyLevel
            , dailyWorkStatusList: projectWorkStatus.dailyWorkStatusList
            , updateDatetime: projectWorkStatus.updateDatetime
            , days: days
            , totalWorkingTime: totalWorkingTimeStr
            , summaryTotalWorkingTime: ""
            , originalQualificationName: ""
            , dailyQualificationRequirementList: []
            , displayDivision: "0"
            , companyMergedAttendanceFlag: projectWorkStatus.companyMergedAttendanceFlag
          };

          userWorkStatusList.push(rec);

          summaryTotalWorkingTime += totalWorkingTime;
        });
      });

      // 時
      let hourStr = Math.floor(summaryTotalWorkingTime / 3600);
      // 分
      let minutesStr = Math.ceil((summaryTotalWorkingTime - hourStr * 3600) / 60);

      // 労働時間の集計結果をリストに設定
      const summaryRec: Model.WorkRecordWorkerDetailRow = {
        key: ""
        , projectId: ""
        , projectName: ""
        , workRecordSubmissionHistoryId: ""
        , approvalStatus: ""
        , forceApprovalFlag: ""
        , submissionCompanyName: ""
        , submissionMcdpId: ""
        , submissionName: ""
        , confirmDate: ""
        , confirmMcdpId: ""
        , confirmName: ""
        , approvalDate: ""
        , approvalMcdpId: ""
        , approvalName: ""
        , belongCompanyId: ""
        , belongCompanyName: ""
        , firstPartnerCompanyList: []
        , topHierarchyLevel: 0
        , dailyWorkStatusList: []
        , updateDatetime: ""
        , days: 0
        , totalWorkingTime: ""
        , summaryTotalWorkingTime: hourStr + ":" + ("0" + minutesStr).slice(-2)
        , originalQualificationName: ""
        , dailyQualificationRequirementList: []
        , displayDivision: "1"
        , companyMergedAttendanceFlag: "0"
      };

      userWorkStatusList.push(summaryRec);

      // 資格情報をリストに設定
      originalQualificationList.forEach((originalQualification: Model.OriginalQualificationListRow) => {
        const qualificationRec: Model.WorkRecordWorkerDetailRow = {
          key: ""
          , projectId: ""
          , projectName: ""
          , workRecordSubmissionHistoryId: ""
          , approvalStatus: ""
          , forceApprovalFlag: ""
          , submissionCompanyName: ""
          , submissionMcdpId: ""
          , submissionName: ""
          , confirmDate: ""
          , confirmMcdpId: ""
          , confirmName: ""
          , approvalDate: ""
          , approvalMcdpId: ""
          , approvalName: ""
          , belongCompanyId: ""
          , belongCompanyName: ""
          , firstPartnerCompanyList: []
          , topHierarchyLevel: 0
          , dailyWorkStatusList: []
          , updateDatetime: ""
          , days: 0
          , totalWorkingTime: ""
          , summaryTotalWorkingTime: ""
          , originalQualificationName: originalQualification.originalQualificationName
          , dailyQualificationRequirementList: originalQualification.dailyQualificationRequirementList
          , displayDivision: "2"
          , companyMergedAttendanceFlag: "0"
        };
        userWorkStatusList.push(qualificationRec);
      })

      let qualificationListTotal: number = originalQualificationList.length;

      return Object.assign(
        {}, state,
        { detailTotal: total },
        { detailDataList: userWorkStatusList },
        { belongCompanyId: belongCompanyId },
        { belongCompanyName: belongCompanyName },
        { workerId: workerId },
        { name: name },
        { primeCertificationNo: primeCertificationNo },
        { managementGroupId: managementGroupId },
        { managementGroupName: managementGroupName },
        { qualificationListTotal: qualificationListTotal },
      )
    },

    /**
    * 検索条件のstate更新
    */
    setDetailCriteria: (state, action: PayloadAction<Model.WorkRecordWorkerDetailCriteria>) => {
      let criteria: Model.WorkRecordWorkerDetailCriteria = {
        processType: action.payload.processType,
        attendanceYearMonth: action.payload.attendanceYearMonth,
        projectId: action.payload.projectId,
        workerId: action.payload.workerId,
        belongCompanyId: action.payload.belongCompanyId,
        belongCompanyName: action.payload.belongCompanyName,
        belongCompanyNameKana: action.payload.belongCompanyNameKana,
        primeCertificationNo: action.payload.primeCertificationNo,
        lastName: action.payload.lastName,
        firstName: action.payload.firstName,
        lastNameKana: action.payload.lastNameKana,
        firstNameKana: action.payload.firstNameKana,
        firstPartnerCompanyName: action.payload.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana,
        status: action.payload.status,
        hasWorkTime: action.payload.hasWorkTime,
        offset: action.payload.offset,
        limit: action.payload.limit,
      }
      return Object.assign({}, state, { workRecordWorkerDetailCriteria: criteria })
    },


    /**
     * URLに設定されているパラメータをstateに反映
     */
    setUrlParameterValue: (state, action: PayloadAction<Model.UrlParameter>) => {
      let criteria: Model.WorkRecordWorkerDetailCriteria = {
        processType: state.workRecordWorkerDetailCriteria.processType,
        attendanceYearMonth: action.payload.attendanceYearMonth,
        projectId: state.workRecordWorkerDetailCriteria.projectId,
        workerId: Number.parseInt(action.payload.workerId),
        belongCompanyId: action.payload.belongCompanyId,
        belongCompanyName: state.workRecordWorkerDetailCriteria.belongCompanyName,
        belongCompanyNameKana: state.workRecordWorkerDetailCriteria.belongCompanyNameKana,
        primeCertificationNo: state.workRecordWorkerDetailCriteria.primeCertificationNo,
        lastName: state.workRecordWorkerDetailCriteria.lastName,
        firstName: state.workRecordWorkerDetailCriteria.firstName,
        lastNameKana: state.workRecordWorkerDetailCriteria.lastNameKana,
        firstNameKana: state.workRecordWorkerDetailCriteria.firstNameKana,
        firstPartnerCompanyName: state.workRecordWorkerDetailCriteria.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: state.workRecordWorkerDetailCriteria.firstPartnerCompanyNameKana,
        hasWorkTime: state.workRecordWorkerDetailCriteria.hasWorkTime,
        status: state.workRecordWorkerDetailCriteria.status,
        offset: state.workRecordWorkerDetailCriteria.offset,
        limit: state.workRecordWorkerDetailCriteria.limit,
      }

      return Object.assign(
        {}, state,
        { workRecordWorkerDetailCriteria: criteria },
        { workerId: action.payload.workerId },
        { belongCompanyId: action.payload.belongCompanyId }
      );
    },

    /**
     * 独自資格一覧レコードのセット
     */
    setQualificationsHoldersPrimeQualificationListSubmit: (state, action: PayloadAction<Model.getOriginalQualificationListSubmit.Response>) => {
      // レスポンスデータ
      const data: Model.getOriginalQualificationListSubmit.Response = action.payload;

      // 資格一覧
      const qualificationList: Array<Model.QualificationRow>= new Array<Model.QualificationRow>();

      data.qualificationList.forEach((rowData:Model.QualificationRow) => {
        qualificationList.push({
          originalQualificationName: rowData.originalQualificationName,
          certificationDate: rowData.certificationDate,
          deadlineStartDate: rowData.deadlineStartDate,
          deadlineEndDate: rowData.deadlineEndDate,
          levelRangeTo: rowData.levelRangeTo,
        });
      });

      return Object.assign({}, state, {
        qualificationListSubmit: qualificationList
      });
    },

    /**
     * 独自資格一覧レコードのセット
     */
    setQualificationsHoldersPrimeQualificationSubmitList: (state, action: PayloadAction<Model.getOriginalQualificationListSubmit.Response>) => {
      // レスポンスデータ
      const data: Model.getOriginalQualificationListSubmit.Response = action.payload;

      // 資格一覧
      const qualificationList: Array<Model.QualificationRow>= new Array<Model.QualificationRow>();

      data.qualificationList.forEach((rowData:Model.QualificationRow) => {
        qualificationList.push({
          originalQualificationName: rowData.originalQualificationName,
          certificationDate: rowData.certificationDate,
          deadlineStartDate: rowData.deadlineStartDate,
          deadlineEndDate: rowData.deadlineEndDate,
          levelRangeTo: rowData.levelRangeTo,
        });
      });

      return Object.assign({}, state, {
        qualificationList: qualificationList
      });
    },

    /**
     * 独自資格一覧モーダルの表示/非表示切り替え
     */
    reflectQualificationModalState: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { qualificationModalVisibleState: action.payload });
    },

  },
});

export const {
  prepareMoveToDetail
  , initDataList
  , initCriteria
  , setCriteria
  , setDataListOfWorkRecordWorkerSummary
  , setDetailDataListOfWorkRecordPrime
  , setDetailCriteria
  , setUrlParameterValue
  , setQualificationsHoldersPrimeQualificationListSubmit
  , reflectQualificationModalState
} = workerAttendanceSlice.actions;

/**
 * 作業者別勤怠実績一覧取得_元請API呼び出し
 * @param params
 */
export const getDataListAsync = (params: Model.WorkRecordWorkerSummaryCriteria): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PRIME_WORKER_SUMMARY, {
      params: params
    });
    // 結果をstateに設定します。
    dispatch(setDataListOfWorkRecordWorkerSummary(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};


/**
 * 勤怠明細取得_元請API呼び出し
 * @param params 
 */
export const getDetailDataListAsync = (params: Model.getDetailDataList.Request): AppThunk => async dispatch => {
  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PRIME_DETAIL, {
      params: params
    });
    // 結果をstateに設定します。
    dispatch(setDetailDataListOfWorkRecordPrime(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}

/**
 * 個人の有効独自資格取得API呼び出し
 * @param params 
 * @returns 
 */
export const getQualificationsHoldersPrimeQualificationListSubmitAsync = (params: Model.getOriginalQualificationListSubmit.Request): AppThunk => async dispatch => {
  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_QUALIFICATION_LIST_SUBMIT, {
      params: params
    });
    // 結果を確認
    // 権限なし
    if (response.data.cmn.errorCode === "EA2006001") {
      dispatch(setApiResult({ status: response.status, errorCode: response.data.cmn.errorCode, requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
      return;
    }

    // 結果をstateに設定します。
    dispatch(setQualificationsHoldersPrimeQualificationListSubmit(response.data.data));

    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.workerAttendancePrime.dataList;
export const storedCriteria = (state: RootState) => state.workerAttendancePrime.workRecordWorkerSummaryCriteria;
export const storedTotal = (state: RootState) => state.workerAttendancePrime.total;
export const storedManagementGroupList = (state: RootState) => state.workerAttendancePrime.managementGroupList;
export const storedDetailDataList = (state: RootState) => state.workerAttendancePrime.detailDataList;
export const storedDetailTotal = (state: RootState) => state.workerAttendancePrime.detailTotal;
export const storedCompanyId = (state: RootState) => state.workerAttendancePrime.belongCompanyId;
export const storedCompanyName = (state: RootState) => state.workerAttendancePrime.belongCompanyName;
export const storedWorkerId = (state: RootState) => state.workerAttendancePrime.workerId;
export const storedName = (state: RootState) => state.workerAttendancePrime.name;
export const storedPrimeCertificationNo = (state: RootState) => state.workerAttendancePrime.primeCertificationNo;
export const storedManagementGroupName = (state: RootState) => state.workerAttendancePrime.managementGroupName;
export const storedDetailCriteria = (state: RootState) => state.workerAttendancePrime.workRecordWorkerDetailCriteria;
export const storedQualificationListTotal = (state: RootState) => state.workerAttendancePrime.qualificationListTotal;
export const storedQualificationListSubmit = (state: RootState) => state.workerAttendancePrime.qualificationListSubmit;
export const storedQualificationModalVisibleState = (state: RootState) => state.workerAttendancePrime.qualificationModalVisibleState;

export default workerAttendanceSlice.reducer;
