import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router"
import styles from './CommonSettingList.module.css';
import { Layout, Table, Select, Form } from "antd";
import { AuthButton, Checkbox, Radio } from "../../core/CoreForm"
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from "../../core/CorePageContents"
import { storedApiResult } from '../../../modules/core/bffApiModule';
import { setMessages, message } from '../../../modules/core/commonMessageModule';
import * as Module from "../../../modules/prime/commonSetting/commonSettingModule";
import * as Model from "../../../modules/prime/commonSetting/commonSettingModel";
import * as CONSTANTS from "../../../constants/constants";
import * as constantsUtils from '../../../utils/constantsUtils';
import { initMessages } from "../../../modules/core/commonMessageModule";
import * as messagesUtils from "../../../utils/messagesUtils";
import AuthButtonStyles from "../../core/css/AuthButton.module.css";
import * as Utils from '../../../utils/utils';


interface titleInterface {
  title: string;
}

enum MailSendUserKbn {
  PrimeSkillmapAuthorityApproveUser,
  PrimePositionAuthorityApproveUser,
  PrimePositionAuthorityConfirmUser,
  PartnerSubmitUser,
}


export const CommonSettingEdit = (props: titleInterface) => {

  const dispatch = useDispatch();
  let closingDateList = useSelector(Module.storedClosingClosingDateList);
  let workflowFlagList = useSelector(Module.storedWorkflowFlagList);
  let apportionmentTypeList = useSelector(Module.storedApportionmentTypeList);
  let calcWorkTimeTypeList = useSelector(Module.storedCalcWorkTimeTypeList);
  let editabilityWorkTimeTypeList = useSelector(Module.storedEditabilityWorkTimeTypeList);
  let positionAuthorityList = useSelector(Module.storedPositionAuthorityList);
  let certificateFlowTypeList = useSelector(Module.storedCertificateFlowTypeList);
  let mailTypeSendFlagToPrimePositionAuthorityApproveUserList = useSelector(Module.storedMailTypeSendFlagToPrimePositionAuthorityApproveUserList);
  let mailTypeSendFlagToPrimePositionAuthorityConfirmUserList = useSelector(Module.storedMailTypeSendFlagToPrimePositionAuthorityConfirmUserList);
  let mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList = useSelector(Module.storedMailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList);
  let mailTypeSendFlagToPartnerSubmitUserList = useSelector(Module.storedMailTypeSendFlagToPartnerSubmitUserList);


  const apiResult = useSelector(storedApiResult);
  let loadingFlg = useSelector(Module.storedLoadingFlg);
  let history = useHistory();
  const [tableForm] = Form.useForm();

  useEffect(() => {

    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod) {
      // 更新処理に成功したら一覧画面へ移動
      const setMessage: message = {
        message: messagesUtils.getMessage("SUCCESS_UPDATE", ["基本設定"]),
        messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS,
      }
      dispatch(setMessages([setMessage]));
      history.push(CONSTANTS.PATH_PRIME_SETTING_LIST);
    }
    if (CONSTANTS.API_STATUS_INITIAL === apiResult.status) {
      // 取得
      dispatch(Module.getPrimeCommonSettingAsync());
    }

  }, [apiResult]);

  /**
   * 役割別権限の変更時処理
   * @param value 
   * @param row 
   */
  const onChangeAuthority = (value: any, row: Model.PositionAuthority) => {
    let authList: Model.PositionAuthority[] = [];
    for (const pa of positionAuthorityList) {
      authList.push({
        positionType: pa.positionType,
        positionAuthority: pa.positionType == row.positionType ? value : pa.positionAuthority,
      });
    }
    dispatch(Module.savePositionAuthorityList(authList));
  }

  /**
   * 締切日の月の変更時処理
   * @param value 
   * @param row 
   */
  const onChangeClosingMonth = (value: any, row: Model.ClosingDateRow) => {
    let closingList: Model.ClosingDateRow[] = [];
    for (const cd of closingDateList) {
      closingList.push({
        closingDateKbn: cd.closingDateKbn,
        closingMonth: cd.closingDateKbn == row.closingDateKbn ? value : cd.closingMonth,
        closingDate: cd.closingDate,
      });
    }
    dispatch(Module.saveClosingDateList(closingList));
  }

  /**
   * 締切日の日の変更時処理
   * @param value 
   * @param row 
   */
  const onChangeClosingDate = (value: any, row: Model.ClosingDateRow) => {
    let closingList: Model.ClosingDateRow[] = [];
    for (const cd of closingDateList) {
      closingList.push({
        closingDateKbn: cd.closingDateKbn,
        closingMonth: cd.closingMonth,
        closingDate: cd.closingDateKbn == row.closingDateKbn ? value : cd.closingDate,
      });
    }
    dispatch(Module.saveClosingDateList(closingList));
  }

  /**
   * ワークフローのチェックボックスの変更時処理
   * @param e 
   */
  const onChangeWorkflow = (e: any) => {
    let workflowFlagList: Model.WorkflowRow[] = [];
    workflowFlagList.push({
      label: "「確認」を必要とする",
      flag: e.target.checked ? CONSTANTS.WORKFLOW_FLAG.ON.code : CONSTANTS.WORKFLOW_FLAG.OFF.code,
    });
    dispatch(Module.saveWorkflowFlagList(workflowFlagList));
  }

  /**
   * 労働時間の自動入力設定変更処理
   * @param e
   */
  const onChangeCalcWorkTime = (e: any) => {
    let calcWorkTimeTypeList: Model.CalcWorkTimeRow[] = [];
    calcWorkTimeTypeList.push({
      label: "労働時間の自動入力",
      value: e.target.value,
    });
    dispatch(Module.saveCalcWorkTimeTypeList(calcWorkTimeTypeList));
  }

  /**
   * 労働時間の編集可否設定変更処理
   * @param e
   */
  const onChangeEditabilityWorkTime = (e: any) => {
    let editabilityWorkTimeTypeList: Model.EditabilityWorkTimeRow[] = [];
    editabilityWorkTimeTypeList.push({
      label: "勤怠編集の可否",
      value: e.target.value,
    });
    dispatch(Module.saveEditabilityWorkTimeTypeList(editabilityWorkTimeTypeList));
  }

  /**
   * 手当金額の按分方法の設定変更処理
   * @param value 
   */
  const onChangeApportionmentType = (value: any) => {
    let apportionmentTypeList: Model.CommonSettingRow[] = [];
    apportionmentTypeList.push({
      label: "算出方法",
      value: value,
    });
    dispatch(Module.saveApportionmentTypeList(apportionmentTypeList))
  }

  /**
   * 資格認定フロー利用設定のスキップ設定の変更
   * @param value 
   */
  const onChangeCertificateFlowtype = (value: any) => {
    let certificateFlowTypeList: Model.CertificateFlowTypeRow[] = [];
    certificateFlowTypeList.push({
      label: "利用設定",
      value: value,
    });
    dispatch(Module.saveCertificateFlowTypeList(certificateFlowTypeList))
  }


  /**
   * メール送信フラグの変更
   * @param value 
   * @param row 
   * @param mailSendUserKbn
   */
  const onChangeMailSendFlag = (value: any, row: Model.MailTypeSendFlagRow, mailSendUserKbn: MailSendUserKbn) => {
    let modifyMailTypeSeldFlagList: Array<Model.MailTypeSendFlagRow>;
    const createChangeMailTypeSeldFlagList = (list: Array<Model.MailTypeSendFlagRow>, changeRow: Model.MailTypeSendFlagRow): Array<Model.MailTypeSendFlagRow> => {
      let mailTypeSeldFlagList: Array<Model.MailTypeSendFlagRow> = [];
      for (const e of list) {
        mailTypeSeldFlagList.push({
          mailTypeCode: e.mailTypeCode,
          flag: e.mailTypeCode == changeRow.mailTypeCode ? value : e.flag,
        });
      }
      return mailTypeSeldFlagList;
    }

    switch (mailSendUserKbn) {
      case MailSendUserKbn.PrimePositionAuthorityApproveUser:
        modifyMailTypeSeldFlagList = createChangeMailTypeSeldFlagList(mailTypeSendFlagToPrimePositionAuthorityApproveUserList, row);
        dispatch(Module.saveMailTypeSendFlagToPrimePositionAuthorityApproveUserList(modifyMailTypeSeldFlagList));
        break;

      case MailSendUserKbn.PrimePositionAuthorityConfirmUser:
        modifyMailTypeSeldFlagList = createChangeMailTypeSeldFlagList(mailTypeSendFlagToPrimePositionAuthorityConfirmUserList, row);
        dispatch(Module.saveMailTypeSendFlagToPrimePositionAuthorityConfirmUserList(modifyMailTypeSeldFlagList));
        break;

      case MailSendUserKbn.PrimeSkillmapAuthorityApproveUser:
        modifyMailTypeSeldFlagList = createChangeMailTypeSeldFlagList(mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList, row);
        dispatch(Module.saveMailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList(modifyMailTypeSeldFlagList));
        break;

      case MailSendUserKbn.PartnerSubmitUser:
        modifyMailTypeSeldFlagList = createChangeMailTypeSeldFlagList(mailTypeSendFlagToPartnerSubmitUserList, row);
        dispatch(Module.saveMailTypeSendFlagToPartnerSubmitUserList(modifyMailTypeSeldFlagList));
        break;
    }
  }




  /**
   * 戻るボタン押下時アクション
   * @returns 
   */
  const backClicked = () => {
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_BACK"))) {
      return;
    }
    dispatch(initMessages());
    history.push(CONSTANTS.PATH_PRIME_SETTING_LIST);
  }

  /**
   * 確定ボタン押下時アクション
   */
  const confirmClicked = () => {
    tableForm.submit();
  }


  // submit時アクション
  const submitForm = (values: any) => {

    const validateResult: boolean = validate();
    if (!validateResult) {
      scrollToTop();
      return;
    }

    if (!window.confirm(messagesUtils.getMessage("CONFIRM_UPDATE", ["基本設定"]))) {
      return;
    }

    // API結果情報を初期化します。
    dispatch(initMessages());
    dispatch(Module.setLoadingFlg(true));

    // 文字列から数値へ変換する処理。数値でない場合はNaNとする
    const str2Num = (str: string): number => {
      let rtn: number = NaN;
      if (str != "") {
        try {
          rtn = parseInt(str);
        } catch (e) {
        }
      }
      return rtn;
    }

    let sendClosingDataList: Model.ClosingDate[] = new Array<Model.ClosingDate>();
    for (const row of closingDateList) {
      sendClosingDataList.push({
        closingDateKbn: row.closingDateKbn,
        month: str2Num(row.closingMonth),
        date: str2Num(row.closingDate),
      })
    }

    // メール送信可否の設定
    // （ありえないが）もし設定値が見つからない場合は「通知する」で登録する
    const getFlag = (list: Array<Model.MailTypeSendFlagRow>, targetMailTypeCode: string): string => {
      let rtn: string = CONSTANTS.MAIL_SEND_FLAG.SEND.code;
      for (const e of list) {
        if (e.mailTypeCode == targetMailTypeCode) {
          rtn = e.flag;
          break;
        }
      }
      return rtn;
    }

    let mailSendFlags: Model.MailSendFlags = {
      toPrimePositionAuthorityApproveUserRequestConfirmAttendance: getFlag(mailTypeSendFlagToPrimePositionAuthorityApproveUserList, CONSTANTS.MAIL_TYPE.REQUEST_CONFIRM_ATTENCANCE.code),
      toPrimePositionAuthorityApproveUserRequestApproveAttendance: getFlag(mailTypeSendFlagToPrimePositionAuthorityApproveUserList, CONSTANTS.MAIL_TYPE.REQUEST_APPROVE_ATTENCANCE.code),
      toPrimePositionAuthorityApproveUserNoticeCancelSubmitAttendance: getFlag(mailTypeSendFlagToPrimePositionAuthorityApproveUserList, CONSTANTS.MAIL_TYPE.CANCEL_SUBMIT_ATTENDANCE.code),
      toPrimePositionAuthorityApproveUserNoticeCancelApproveAttendance: getFlag(mailTypeSendFlagToPrimePositionAuthorityApproveUserList, CONSTANTS.MAIL_TYPE.CANCEL_APPROVED_ATTENCANCE.code),

      toPrimePositionAuthorityConfirmUserRequestConfirmAttendance: getFlag(mailTypeSendFlagToPrimePositionAuthorityConfirmUserList, CONSTANTS.MAIL_TYPE.REQUEST_CONFIRM_ATTENCANCE.code),
      toPrimePositionAuthorityConfirmUserNoticeCancelSubmitAttendance: getFlag(mailTypeSendFlagToPrimePositionAuthorityConfirmUserList, CONSTANTS.MAIL_TYPE.CANCEL_SUBMIT_ATTENDANCE.code),
      toPrimePositionAuthorityConfirmUserNoticeCancelApproveAttendance: getFlag(mailTypeSendFlagToPrimePositionAuthorityConfirmUserList, CONSTANTS.MAIL_TYPE.CANCEL_APPROVED_ATTENCANCE.code),

      toPrimeSkillmapAuthorityApproveUserRequestConfirmAttendance: getFlag(mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList, CONSTANTS.MAIL_TYPE.REQUEST_CONFIRM_ATTENCANCE.code),
      toPrimeSkillmapAuthorityApproveUserRequestApproveAttendance: getFlag(mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList, CONSTANTS.MAIL_TYPE.REQUEST_APPROVE_ATTENCANCE.code),
      toPrimeSkillmapAuthorityApproveUserNoticeCancelSubmitAttendance: getFlag(mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList, CONSTANTS.MAIL_TYPE.CANCEL_SUBMIT_ATTENDANCE.code),
      toPrimeSkillmapAuthorityApproveUserNoticeCancelApproveAttendance: getFlag(mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList, CONSTANTS.MAIL_TYPE.CANCEL_APPROVED_ATTENCANCE.code),

      toPartnerSubmitUserNoticeRemandAttendance: getFlag(mailTypeSendFlagToPartnerSubmitUserList, CONSTANTS.MAIL_TYPE.REMAND_ATTENCANCE.code),
      toPartnerSubmitUserNoticeApproveAttendance: getFlag(mailTypeSendFlagToPartnerSubmitUserList, CONSTANTS.MAIL_TYPE.APPROVED_ATTENCANCE.code),
    }

    const workflowFlag: string = workflowFlagList.length > 0 ? workflowFlagList[0].flag : CONSTANTS.WORKFLOW_FLAG.OFF.code;
    const calcWorkTimeType: string = calcWorkTimeTypeList.length > 0 ? calcWorkTimeTypeList[0].value : CONSTANTS.CALC_WORK_TIME_TYPE.NOT_USE.code;
    const editabilityWorkTimeType: string = editabilityWorkTimeTypeList.length > 0 ? editabilityWorkTimeTypeList[0].value : CONSTANTS.EDITABILITY_WORK_TIME_TYPE.NOT_EDITABLE.code;
    const apportionmentType: string = apportionmentTypeList.length > 0 ? apportionmentTypeList[0].value : CONSTANTS.APPORTIONMENT_TYPE.DIVIDE_BY_WORKTIME.code;
    const certificateFlowType: string = certificateFlowTypeList.length > 0 ? certificateFlowTypeList[0].value : CONSTANTS.CERTIFICATE_FLOW_TYPE.NOT_SKIP.code;

    // 更新処理
    const sendRequest: Model.putCommonSetting.Request = {
      cloingDateList: sendClosingDataList,
      workflowFlag: workflowFlag,
      calcWorkTimeType: calcWorkTimeType,
      editabilityWorkTimeType: editabilityWorkTimeType,
      apportionmentType: apportionmentType,
      positionAuthorityList: positionAuthorityList,
      mailSendFlags: mailSendFlags,
      certificateFlowType: certificateFlowType,
    };

    dispatch(Module.putPrimeCommonSettingAsync(sendRequest));
  }

  /**
   * submit時にエラーがある場合の処理
   * @param errorInfo 
   */
  const submitFormFailed = (errorInfo: any) => {
    dispatch(initMessages());
    scrollToTop();
    if (errorInfo.errorFields.length > 0) {
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_VALIDATION"),
        messageType: CONSTANTS.MESSAGE_TYPE_ERROR,
      }
      dispatch(setMessages([setMessage]));
    }
  }

  /**
   * 入力値チェック（相関関係）
   * @returns 
   */
  const validate = (): boolean => {
    let checked: boolean = true;
    let messages: message[] = [];
    let okSubmission = false;
    let okApprove = false;

    for (const closingDate of closingDateList) {
      if ((Utils.isNullOrEmpty(closingDate.closingMonth) && !Utils.isNullOrEmpty(closingDate.closingDate))
        || (!Utils.isNullOrEmpty(closingDate.closingMonth) && Utils.isNullOrEmpty(closingDate.closingDate))) {

        messages.push({
          message: messagesUtils.getMessage("ERROR_CLOSING_DATE_SETTING", [
            constantsUtils.getLabelOf(CONSTANTS.CLOSING_DATE_KBN, closingDate.closingDateKbn)
          ]),
          messageType: CONSTANTS.MESSAGE_TYPE_ERROR,
        });

        checked = false;

      } else {
        // 提出期限、承認期限のチェック結果を取っておく
        if (closingDate.closingDateKbn === CONSTANTS.CLOSING_DATE_KBN.SUBMISSION.code) {
          okSubmission = true;
        }
        if (closingDate.closingDateKbn === CONSTANTS.CLOSING_DATE_KBN.APPROVE.code) {
          okApprove = true;
        }
      }
    }

    // 提出期限、承認期限のチェック
    if (okSubmission && okApprove) {
      let submissionClosingDate = getClosingDate(CONSTANTS.CLOSING_DATE_KBN.SUBMISSION.code);
      let approveClosingDate = getClosingDate(CONSTANTS.CLOSING_DATE_KBN.APPROVE.code);

      if (submissionClosingDate != null && approveClosingDate != null) {
        // 提出期限、承認期限の入力が正常だった場合、大小チェックを実施

        if (!isEmptyClosingDate(approveClosingDate)) {
          // 承認期限：あり

          if (isEmptyClosingDate(submissionClosingDate)) {
            // 提出期限：なし→エラー
            pushRelativeErrorMeesage(messages);
            checked = false;

          } else {
            // 提出期限：あり

            if (isSubmissionAfterApprove(submissionClosingDate, approveClosingDate)) {
              // 提出期限＞承認期限→エラー
              pushRelativeErrorMeesage(messages);
              checked = false;
            }
          }
        }
      }
    }

    if (!checked) {
      dispatch(setMessages(messages));
    }

    return checked;
  }

  const pushRelativeErrorMeesage = (messages: message[]) => {
    messages.push({
      message: messagesUtils.getMessage("ERROR_CLOSING_DATE_RELATIVE", [
        CONSTANTS.CLOSING_DATE_KBN.SUBMISSION.label,
        CONSTANTS.CLOSING_DATE_KBN.APPROVE.label,
        CONSTANTS.CLOSING_DATE_KBN.APPROVE.label,
      ]),
      messageType: CONSTANTS.MESSAGE_TYPE_ERROR,
    });
  }

  /**
   * 提出期限＞承認期限かどうか
   * どちらも空でないこと
   * @param submission 
   * @param approve 
   * @returns 
   */
  const isSubmissionAfterApprove = (submission: Model.ClosingDateRow, approve: Model.ClosingDateRow) => {
    if (parseInt(submission.closingMonth) > parseInt(approve.closingMonth)) {
      return true;
    }
    if (parseInt(submission.closingMonth) === parseInt(approve.closingMonth)) {
      if (parseInt(submission.closingDate) > parseInt(approve.closingDate)) {
        return true;
      }
    }
    return false;
  }

  /**
   * 指定された締切日を取得
   * @param kbn 
   * @returns 
   */
  const getClosingDate = (kbn: string) => {
    for (const closingDate of closingDateList) {
      if (closingDate.closingDateKbn === kbn) {
        return closingDate;
      }
    }
    return null;
  }

  /**
   * 締切日の指定なしを判定
   * @param closingDate 
   * @returns 
   */
  const isEmptyClosingDate = (closingDate: Model.ClosingDateRow) => {
    return Utils.isNullOrEmpty(closingDate.closingMonth) && Utils.isNullOrEmpty(closingDate.closingDate);
  }

  /**
   * 上部へスクロールする（エラーメッセージを見せるため）
   */
  const scrollToTop = () => {
    const target = document.getElementById("contenttop");
    if (target !== null) {
      target.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  const monthSelectList: Array<string> = (() => {
    const list: Array<string> = new Array<string>();
    for (let i = 1; i <= 12; i++) {
      list.push(String(i));
    }

    return list;
  })();

  const dateSelectList: Array<string> = (() => {
    const list: Array<string> = new Array<string>();
    for (let i = 1; i <= 31; i++) {
      list.push(String(i));
    }

    return list;
  })();




  /**
   * 締切設定の表示
   */
  const closingDateTableColumns = [
    {
      title: '締切',
      width: '250px',
      align: 'center' as 'center',
      render: (value: any, row: Model.ClosingDateRow) => {
        return (
          <>
            <span>{constantsUtils.getLabelOf(CONSTANTS.CLOSING_DATE_KBN, row.closingDateKbn)}</span>
          </>
        );
      },
    },
    {
      title: '期限',
      align: 'left' as 'left',
      render: (value: any, row: Model.ClosingDateRow) => {
        return (
          <>
            <Form.Item>
              <Select className={styles.closingDateSelect}
                value={row.closingMonth}
                style={{ width: 120 }}
                onChange={(value) => onChangeClosingMonth(value, row)}
              >
                <Select.Option value={""}>-</Select.Option>
                {monthSelectList.map((i: string) => (
                  <Select.Option key={i} value={i}>{i}</Select.Option>
                ))}
              </Select>
              <span>ヶ月後の</span>

              <Select className={styles.closingDateSelect}
                value={row.closingDate}
                style={{ width: 120 }}
                onChange={(value) => onChangeClosingDate(value, row)}
              >
                <Select.Option value={""}>-</Select.Option>
                {dateSelectList.map((i: string) => (
                  <Select.Option key={i} value={i}>{i}</Select.Option>
                ))}
              </Select>
              <span>日まで</span>
            </Form.Item>
          </>
        );
      },
    },
  ];


  /**
   * ワークフローの表示
   * @returns 
   */
  const workflowColumns = [
    {
      title: '項目',
      width: '250px',
      dataIndex: 'label',
      align: 'center' as 'center',
      render: (value: any, row: Model.WorkflowRow) => {
        return (
          <>
            <span>{row.label}</span>
          </>
        );
      },
    },
    {
      title: '設定値',
      dataIndex: 'flag',
      align: 'left' as 'left',
      render: (value: string, row: Model.WorkflowRow) => {
        return (
          <>
            <Form.Item>
              <Checkbox
                checked={row.flag == CONSTANTS.WORKFLOW_FLAG.ON.code}
                onChange={(e) => onChangeWorkflow(e)}
              />
            </Form.Item>
          </>
        );
      },
    },
  ];

  /**
   * 労働時間の自動入力設定の表示
   * @returns
   */
  const calcWorkTimeColumns = [
    {
      title: '項目',
      width: '250px',
      dataIndex: 'label',
      align: 'center' as 'center',
      render: (value: any, row: Model.CalcWorkTimeRow) => {
        return (
          <>
            <span>{row.label}</span>
          </>
        );
      },
    },
    {
      title: '設定値',
      dataIndex: 'value',
      align: 'left' as 'left',
      render: (value: string, row: Model.CalcWorkTimeRow) => {
        return (
          <>
            <Form.Item>
              <Radio.Group buttonStyle={"solid"} defaultValue={value}>
                {constantsUtils.getCodeLabelListOf(CONSTANTS.CALC_WORK_TIME_TYPE).map((rec: any) => (
                  <Radio
                    key={rec.code}
                    value={rec.code}
                    onChange={(e) => onChangeCalcWorkTime(e)}>{rec.label}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </>
        );
      },
    },
  ];

  /**
   * 労働時間の編集可否設定の表示
   * @returns
   */
  const editabilityWorkTimeColumns = [
    {
      title: '項目',
      width: '250px',
      dataIndex: 'label',
      align: 'center' as 'center',
      render: (value: any, row: Model.EditabilityWorkTimeRow) => {
        return (
          <>
            <span>{row.label}</span>
          </>
        );
      },
    },
    {
      title: '設定値',
      dataIndex: 'value',
      align: 'left' as 'left',
      render: (value: string, row: Model.EditabilityWorkTimeRow) => {
        return (
          <>
            <Form.Item>
              <Radio.Group buttonStyle={"solid"} defaultValue={value}>
                {constantsUtils.getCodeLabelListOf(CONSTANTS.EDITABILITY_WORK_TIME_TYPE).map((rec: any) => (
                  <Radio
                    key={rec.code}
                    value={rec.code}
                    onChange={(e) => onChangeEditabilityWorkTime(e)}>{rec.label}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </>
        );
      },
    },
  ];

  const apportionmentTypeTableColums = [
    {
      title: '項目',
      width: '250px',
      dataIndex: 'label',
      align: 'center' as 'center',
      render: (value: any, row: Model.CommonSettingRow) => {
        return (
          <>
            <span>{row.label}</span>
          </>
        );
      },
    },
    {
      title: '設定値',
      dataIndex: 'value',
      align: 'left' as 'left',
      render: (value: string, row: Model.CommonSettingRow) => {
        return (
          <>
            <Form.Item>
              <Select className={styles.authoritySelect}
                value={row.value}
                onChange={(value) => onChangeApportionmentType(value)}
              >
                {constantsUtils.getCodeLabelListOf(CONSTANTS.APPORTIONMENT_TYPE).map((codelabel) => (
                  <Select.Option key={codelabel.code} value={codelabel.code}>{codelabel.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        );
      },
    },
  ];


  /**
   * 役割別権限の表示
   */
  const positionAuthorityTableColumns = [
    {
      title: '役割',
      dataIndex: 'positioin',
      width: '250px',
      align: 'center' as 'center',
      render: (value: string, row: Model.PositionAuthority) => {
        return (
          <>
            <span>{constantsUtils.getLabelOf(CONSTANTS.POSITION_TYPE, row.positionType)}</span>
          </>
        );
      },
    },
    {
      title: '権限',
      dataIndex: 'authority',
      align: 'left' as 'left',
      render: (value: string, row: Model.PositionAuthority) => {
        return (
          <>
            <Form.Item>
              <Select className={styles.authoritySelect}
                value={row.positionAuthority}
                onChange={(value) => onChangeAuthority(value, row)}
              >
                {constantsUtils.getCodeLabelListOf(CONSTANTS.POSITION_AUTHORITY_TYPE).map((codelabel) => (
                  <Select.Option key={codelabel.code} value={codelabel.code}>{codelabel.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        );
      },
    },
  ];

  /**
   * 資格認定フロー利用設定のスキップ設定
   */
  const certificateFlowTypeTableColums = [
    {
      title: '項目',
      width: '250px',
      dataIndex: 'label',
      align: 'center' as 'center',
      render: (value: any, row: Model.CertificateFlowTypeRow) => {
        return (
          <>
            <span>{row.label}</span>
          </>
        );
      },
    },
    {
      title: '設定値',
      dataIndex: 'value',
      align: 'left' as 'left',
      render: (value: string, row: Model.CertificateFlowTypeRow) => {
        return (
          <>
            <Form.Item>
              <Select className={styles.authoritySelect}
                value={row.value}
                onChange={(value) => onChangeCertificateFlowtype(value)}
              >
                {constantsUtils.getCodeLabelListOf(CONSTANTS.CERTIFICATE_FLOW_TYPE).map((codelabel) => (
                  <Select.Option key={codelabel.code} value={codelabel.code}>{codelabel.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        );
      },
    },
  ];

  /**
   * メール送信可否設定
   */
  const getMailTypeSendFlagTableColumns = (mailSendUserKbn: MailSendUserKbn) => {
    return [
      {
        title: 'メール送信タイミング',
        dataIndex: 'positioin',
        width: '250px',
        align: 'left' as 'left',
        render: (value: string, row: Model.MailTypeSendFlagRow) => {
          return (
            <>
              <span>{constantsUtils.getLabelOf(CONSTANTS.MAIL_TYPE, row.mailTypeCode)}</span>
            </>
          );
        },
      },
      {
        title: '通知設定',
        dataIndex: 'authority',
        align: 'left' as 'left',
        render: (value: string, row: Model.MailTypeSendFlagRow) => {
          return (
            <>
              <Form.Item>
                <Select className={styles.authoritySelect}
                  value={row.flag}
                  onChange={(value) => onChangeMailSendFlag(value, row, mailSendUserKbn)}
                >
                  {constantsUtils.getCodeLabelListOf(CONSTANTS.MAIL_SEND_FLAG).map((codelabel) => (
                    <Select.Option key={codelabel.code} value={codelabel.code}>{codelabel.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          );
        },
      },
    ];
  }

  // 更新を描画します。
  const ButtonArea = () => {
    return (
      <div className={styles.fixed}>
        <div className={styles.float}>
          <AuthButton
            name="戻る"
            size={"large"}
            shape={"round"}
            className={AuthButtonStyles.backButtonFixed}
            onClick={backClicked}
          />
          <AuthButton
            name={"確定"}
            size={"large"}
            shape={"round"}
            className={AuthButtonStyles.authButtonFixed}
            onClick={confirmClicked}
          />
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <Header />
      <Content hasapicall={loadingFlg ? "true" : "false"}>
        <FunctionTitle title={props.title} />
        <div id="contenttop" />
        <div className={styles.settings}>
          <CommonMessage />
          <Form
            form={tableForm}
            onFinish={submitForm}
            onFinishFailed={submitFormFailed}
            className={styles.contentArea}
          >
            <div className={styles.headline}>
              <span>締切設定</span>
            </div>
            <Table
              columns={closingDateTableColumns}
              size='small'
              dataSource={closingDateList}
              pagination={false}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

            <div className={styles.headline}>
              <span>【協力会社】協力会社側での勤怠編集可否の設定</span>
            </div>
            <div className={styles.description}>
            不可を選択すると、協力会社側で労働時間の編集ができなくなります。
            </div>
            <Table
              columns={editabilityWorkTimeColumns}
              size='small'
              dataSource={editabilityWorkTimeTypeList}
              pagination={false}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

            <div className={styles.headline}>
              <span>協力会社からの提出勤怠の「確認」要否設定</span>
            </div>
            <div className={styles.description}>
            協力会社から提出された勤怠の「承認」前に「確認」を必要とするかの設定です。設定した場合、「承認」前に「確認」が必要になります。
            </div>
            <Table
              columns={workflowColumns}
              size='small'
              dataSource={workflowFlagList}
              pagination={false}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

            <div className={styles.headline}>
              <span>【協力会社】入退場いずれかの記録のみの場合に労働時間の自動入力の利用設定</span>
            </div>
            <div className={styles.description}>
            「利用する」を選択の場合、協力会社側で認定技能者の勤怠を確認する際、入退場いずれかの記録がある作業日に労働時間８時間が自動入力されます。
            </div>
            <Table
              columns={calcWorkTimeColumns}
              size='small'
              dataSource={calcWorkTimeTypeList}
              pagination={false}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

            <div className={styles.headline}>
              <span>プロジェクト毎の手当金額の算出方法</span>
            </div>
            <Table
              columns={apportionmentTypeTableColums}
              size='small'
              dataSource={apportionmentTypeList}
              pagination={false}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

            <div className={styles.headline}>
              <span>役割別権限</span>
            </div>
            <Table
              columns={positionAuthorityTableColumns}
              size='small'
              dataSource={positionAuthorityList}
              pagination={false}
              scroll={{ x: 1000 }}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

            <div className={styles.headline}>
              <span>資格認定フロー利用設定</span>
            </div>
            <div className={styles.description}>
            作業員の資格登録の際、「申請」「確認」「承認」の手続きフローをスキルマップサイト内で処理する際の設定です。
            </div>
            <Table
              columns={certificateFlowTypeTableColums}
              size='small'
              dataSource={certificateFlowTypeList}
              pagination={false}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

            <div className={styles.headline}>
              <span>【元請会社】勤怠承認権限を持つ本社/支店担当者へのメール通知設定</span>
            </div>
            <div className={styles.description}>
              元請会社の本社/支店担当者で勤怠承認者の権限を持つユーザーを対象に、担当下の協力会社から提出された勤怠が処理された際のメール通知の設定です。
            </div>
            <Table
              columns={getMailTypeSendFlagTableColumns(MailSendUserKbn.PrimeSkillmapAuthorityApproveUser)}
              size='small'
              dataSource={mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList}
              pagination={false}
              scroll={{ x: 1000 }}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />
            <div className={styles.headline}>
              <span>【元請会社】勤怠確認権限を持つ現場担当者へのメール通知設定</span>
            </div>
            <div className={styles.description}>
              元請会社の現場担当者で勤怠の確認権限を持つユーザーを対象に、担当下の協力会社から提出された勤怠が処理された際のメール通知の設定です。
            </div>
            <Table
              columns={getMailTypeSendFlagTableColumns(MailSendUserKbn.PrimePositionAuthorityConfirmUser)}
              size='small'
              dataSource={mailTypeSendFlagToPrimePositionAuthorityConfirmUserList}
              pagination={false}
              scroll={{ x: 1000 }}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

            <div className={styles.headline}>
              <span>【元請会社】勤怠承認権限を持つ現場担当者へのメール通知設定</span>
            </div>
            <div className={styles.description}>
              元請会社の現場担当者で勤怠の承認権限を持つユーザーを対象に、担当下の協力会社から提出された勤怠が処理された際のメール通知の設定です。
            </div>
            <Table
              columns={getMailTypeSendFlagTableColumns(MailSendUserKbn.PrimePositionAuthorityApproveUser)}
              size='small'
              dataSource={mailTypeSendFlagToPrimePositionAuthorityApproveUserList}
              pagination={false}
              scroll={{ x: 1000 }}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

            <div className={styles.headline}>
              <span>【協力会社】勤怠を提出したユーザーへのメール通知設定</span>
            </div>
            <div className={styles.description}>
              協力会社の勤怠を提出したユーザーを対象に、元請会社へ提出した勤怠が元請会社側で処理された際のメール通知の設定です。
            </div>
            <Table
              columns={getMailTypeSendFlagTableColumns(MailSendUserKbn.PartnerSubmitUser)}
              size='small'
              dataSource={mailTypeSendFlagToPartnerSubmitUserList}
              pagination={false}
              scroll={{ x: 1000 }}
              className="hoverNotBackground"
              style={{ marginLeft: "20px" }}
              bordered
            />

          </Form>
        </div>
      </Content>
      <FooterButtonArea hasapicall={"false"}>
        <ButtonArea />
      </FooterButtonArea>
      <Footer />
    </Layout >
  );

}