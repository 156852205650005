import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler } from '../core/bffApiModule'
import * as CONSTANTS from '../../constants/constants';

interface baseInterface {
  [propertyName: string]: any
}

/** 
 *  権限情報定義
 */
interface IAuthorityInfo extends baseInterface {
  // 元請アクセス権限
  primeAccessAuth: string
  // 11:元請利用者権限
  primeAvailableAuth: string
  // 12:元請勤怠承認者
  primeAttendanceApprovalAuth: string
  // 14:元請資格管理者
  primeQualificationMngAuth: string
  // 15:元請勤怠参照役割保持者
  primeAttendanceReffenceRoleAuth: string
  // 16:元請勤怠承認役割保持者
  primeAttendanceApprovalRoleAuth: string
  // 19:元請システム管理者
  primeSystemMngAuth: string
  // 31:元請_資格承認フロー_申請権限
  primeCertificationFlowRequestAuth: string
  // 32:元請_資格承認フロー_確認権限
  primeCertificationFlowConfirmAuth: string
  // 33:元請_資格承認フロー_承認権限
  primeCertificationFlowApproveAuth: string
  // 協力アクセス権限
  partnerAccessAuth: string
  // 21:協力利用者
  partnerAvailableAuth: string
  // 22:協力勤怠提出者
  partnerAttendanceSubmitAuth: string
  // 29:協力システム管理者
  partnerSystemMngAuth: string
}

/** 
 *  ログインユーザ情報定義
 */
export interface IUserInfo extends baseInterface {
  // MCDP ID
  mcdpId: string
  // 所属企業ID
  belongCompanyId: string
  // 所属企業名
  belongCompanyName: string
  // 所属支店ID
  belongBranchId: string
  // 所属支店名
  belongBranchName: string
  // 氏名
  name: string
  // PRIME契約サービス利用可能フラグ
  primeServiceAvailableFlg: string
  // 協力_利用可能フラグ
  partnerAvailableFlg: string
  // 元請_権限設定済みフラグ
  primeAuthSettingFinishedFlg: string
  // 協力_権限設定済みフラグ
  partnerAuthSettingFinishedFlg: string
  // 元請_サービス利用約款同意済みフラグ
  primeServiceTermsAgreeFlg: string
  // 協力_サービス利用約款同意済みフラグ
  partnerServiceTermsAgreeFlg: string
  // 権限情報
  authorityInfo: IAuthorityInfo
  // 参照範囲
  referenceRange: string
  // 管理グループ一覧
  managementGroupList: IManagementGroup[]
  // ワークフローフラグ
  workflowFlg: string
  // PRIMEサービス契約
  contractPrimeService: string;
  // 資格認定フロー利用設定
  certificateFlowType: string;
  // 労働時間の自動入力設定無用元請企業IDリスト
  calcWorkTimeNotUsePrimeCompanyIdList: string[];
  // 労働時間の編集不可元請企業IDリスト
  workTimeNotEditablePrimeCompanyIdList: string[];
}

// ログインユーザ情報取得APIレスポンス定義
interface UserInfoApiData extends baseInterface {
  // MCDP ID
  mcdpId: string
  // 所属企業ID
  belongCompanyId: string
  // 所属企業名
  belongCompanyName: string
  // 所属支店ID
  belongBranchId: string
  // 所属支店名
  belongBranchName: string
  // 氏名
  name: string
  // PRIME契約サービス利用可能フラグ
  primeServiceAvailableFlg: string
  // 協力_利用可能フラグ
  partnerAvailableFlg: string
  // 元請_権限設定済みフラグ
  primeAuthSettingFinishedFlg: string
  // 協力_権限設定済みフラグ
  partnerAuthSettingFinishedFlg: string
  // 元請_サービス利用約款同意済みフラグ
  primeServiceTermsAgreeFlg: string
  // 協力_サービス利用約款同意済みフラグ
  partnerServiceTermsAgreeFlg: string
  // 権限情報
  authorityInfo: IAuthority[]
  // 参照範囲
  referenceRange: string
  // 管理グループ一覧
  managementGroupList: IManagementGroup[]
  // ワークフローフラグ
  workflowFlg: string
  // PRIMEサービス契約
  contractPrimeService: string
  // 資格認定フロー利用設定
  certificateFlowType: string;
  // 労働時間の自動入力設定無用元請企業IDリスト
  calcWorkTimeNotUsePrimeCompanyIdList: string[];
  // 労働時間の編集不可元請企業IDリスト
  workTimeNotEditablePrimeCompanyIdList: string[];
}

/** 
 *  権限情報APIレスポンス定義
 */
interface IAuthority extends baseInterface {
  // 権限区分
  authKbn: string
  // 権限有無
  authFlg: string
}

/**
 * 管理グループ情報APIレスポンス定義
 */
export interface IManagementGroup extends baseInterface {
  // 管理グループID
  managementGroupId: string
  // 管理グループ名
  managementGroupName: string
}

// state定義
interface UserInfoState {
  loginUserInfo: IUserInfo
  redirectUrl: string
}

// state初期化
const initialState: UserInfoState = {
  loginUserInfo: {
    mcdpId: ""
    , belongCompanyId: ""
    , belongCompanyName: ""
    , belongBranchId: ""
    , belongBranchName: ""
    , name: ""
    , primeServiceAvailableFlg: "0"
    , partnerAvailableFlg: "0"
    , primeAuthSettingFinishedFlg: "0"
    , partnerAuthSettingFinishedFlg: "0"
    , primeServiceTermsAgreeFlg: "0"
    , partnerServiceTermsAgreeFlg: "0"
    , authorityInfo: {
      primeAccessAuth: "1"
      , primeAvailableAuth: ""
      , primeAttendanceReffenceRoleAuth: ""
      , primeAttendanceApprovalAuth: ""
      , primeAttendanceApprovalRoleAuth: ""
      , primeQualificationMngAuth: ""
      , primeSystemMngAuth: ""
      , primeCertificationFlowRequestAuth: ""
      , primeCertificationFlowConfirmAuth: ""
      , primeCertificationFlowApproveAuth: ""
      , partnerAccessAuth: "1"
      , partnerAvailableAuth: ""
      , partnerAttendanceSubmitAuth: ""
      , partnerSystemMngAuth: ""
    }
    , referenceRange: ""
    , managementGroupList: []
    , workflowFlg: "0"
    , contractPrimeService: CONSTANTS.CONTRACT_PRIME_SERVICE.NOT_CONTRACT
    , certificateFlowType: CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_ALL.code
    , calcWorkTimeNotUsePrimeCompanyIdList: []
    , workTimeNotEditablePrimeCompanyIdList: []
  },
  redirectUrl: ""
}

// スライサー
export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    initLoginUserInfo: (state) => {
      state.loginUserInfo = {
        mcdpId: ""
        , belongCompanyId: ""
        , belongCompanyName: ""
        , belongBranchId: ""
        , belongBranchName: ""
        , name: ""
        , primeServiceAvailableFlg: "0"
        , partnerAvailableFlg: "0"
        , primeAuthSettingFinishedFlg: "0"
        , partnerAuthSettingFinishedFlg: "0"
        , primeServiceTermsAgreeFlg: "0"
        , partnerServiceTermsAgreeFlg: "0"
        , authorityInfo: {
          primeAccessAuth: "1"
          , primeAvailableAuth: ""
          , primeAttendanceReffenceRoleAuth: ""
          , primeAttendanceApprovalAuth: ""
          , primeAttendanceApprovalRoleAuth: ""
          , primeQualificationMngAuth: ""
          , primeSystemMngAuth: ""
          , primeCertificationFlowRequestAuth: ""
          , primeCertificationFlowConfirmAuth: ""
          , primeCertificationFlowApproveAuth: ""
          , partnerAccessAuth: "1"
          , partnerAvailableAuth: ""
          , partnerAttendanceSubmitAuth: ""
          , partnerSystemMngAuth: ""
        }
        , referenceRange: ""
        , managementGroupList: []
        , workflowFlg: "0"
        , contractPrimeService: CONSTANTS.CONTRACT_PRIME_SERVICE.NOT_CONTRACT
        , certificateFlowType: CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_ALL.code
        , calcWorkTimeNotUsePrimeCompanyIdList: []
        , workTimeNotEditablePrimeCompanyIdList: []
      }
    }
    , setLoginUserInfo: (state, action: PayloadAction<IUserInfo>) => {
      state.loginUserInfo = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    },
  },
});

// state更新アクションを公開
export const { initLoginUserInfo, setLoginUserInfo, setRedirectUrl } = userInfoSlice.actions;

// ログインユーザ情報取得APIを呼び出します。
export const getLoginUserInfoAsync = (): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest('/cmn/users');
    // APIレスポンスを変換します。
    const userInfoApiData: UserInfoApiData = response.data.data;

    const userInfo: IUserInfo = {
      // MCDP ID
      mcdpId: userInfoApiData.mcdpId,
      // 所属企業ID
      belongCompanyId: userInfoApiData.belongCompanyId,
      // 所属企業名
      belongCompanyName: userInfoApiData.belongCompanyName,
      // 所属支店ID
      belongBranchId: userInfoApiData.belongBranchId,
      // 所属支店名
      belongBranchName: userInfoApiData.belongBranchName,
      // 氏名
      name: userInfoApiData.name,
      // PRIME契約サービス利用可能フラグ
      primeServiceAvailableFlg: userInfoApiData.primeServiceAvailableFlg,
      // 協力_利用可能フラグ
      partnerAvailableFlg: userInfoApiData.partnerAvailableFlg,
      // 元請_権限設定済みフラグ
      primeAuthSettingFinishedFlg: userInfoApiData.primeAuthSettingFinishedFlg,
      // 協力_権限設定済みフラグ
      partnerAuthSettingFinishedFlg: userInfoApiData.partnerAuthSettingFinishedFlg,
      // 元請_サービス利用約款同意済みフラグ
      primeServiceTermsAgreeFlg: userInfoApiData.primeServiceTermsAgreeFlg,
      // 協力_サービス利用約款同意済みフラグ
      partnerServiceTermsAgreeFlg: userInfoApiData.partnerServiceTermsAgreeFlg,
      // 権限情報
      authorityInfo: {
        // 元請アクセス権限
        primeAccessAuth: "1"
        // 元請利用者
        , primeAvailableAuth: getPrimeAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["primeAvailableAuth"].code)
        // 元請勤怠参照役割保持者
        , primeAttendanceReffenceRoleAuth: getPrimeAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["primeAttendanceReffenceRoleAuth"].code)
        // 元請勤怠承認者
        , primeAttendanceApprovalAuth: getPrimeAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["primeAttendanceApprovalAuth"].code)
        // 元請勤怠承認役割保持者
        , primeAttendanceApprovalRoleAuth: getPrimeAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["primeAttendanceApprovalRoleAuth"].code)
        // 元請資格管理者
        , primeQualificationMngAuth: getPrimeAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["primeQualificationMngAuth"].code)
        // 元請システム管理者
        , primeSystemMngAuth: getPrimeAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["primeSystemMngAuth"].code)
        // 元請_資格承認フロー_申請権限
        , primeCertificationFlowRequestAuth: getPrimeAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["primeCertificationFlowRequestAuth"].code)
        // 元請_資格承認フロー_確認権限
        , primeCertificationFlowConfirmAuth: getPrimeAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["primeCertificationFlowConfirmAuth"].code)
        // 元請_資格承認フロー_承認権限
        , primeCertificationFlowApproveAuth: getPrimeAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["primeCertificationFlowApproveAuth"].code)

        // 協力アクセス権限
        , partnerAccessAuth: "1"
        // 協力利用者
        , partnerAvailableAuth: getPartnerAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["partnerAvailableAuth"].code)
        // 協力勤怠提出者
        , partnerAttendanceSubmitAuth: getPartnerAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["partnerAttendanceSubmitAuth"].code)
        // 協力システム管理者
        , partnerSystemMngAuth: getPartnerAuthFlg(userInfoApiData, CONSTANTS.AUTHORIZATION_TYPE["partnerSystemMngAuth"].code)

      }
      , referenceRange: userInfoApiData.referenceRange
      , managementGroupList: userInfoApiData.managementGroupList
      , workflowFlg: userInfoApiData.workflowFlg
      , contractPrimeService: userInfoApiData.contractPrimeService
      , certificateFlowType: userInfoApiData.certificateFlowType
      , calcWorkTimeNotUsePrimeCompanyIdList: userInfoApiData.calcWorkTimeNotUsePrimeCompanyIdList
      , workTimeNotEditablePrimeCompanyIdList: userInfoApiData.workTimeNotEditablePrimeCompanyIdList
    };

    // ステートを更新します。
    dispatch(setLoginUserInfo(userInfo));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

const getPrimeAuthFlg = (useInfo: UserInfoApiData, authKbn: string): string => {
  // PRIME契約サービス利用可能フラグが利用不可の場合、利用権限はすべてなしとします。
  if ("0" === useInfo.primeServiceAvailableFlg) {
    return "0";
  }

  return getAuthFlg(useInfo.authorityInfo, authKbn);
}

const getPartnerAuthFlg = (useInfo: UserInfoApiData, authKbn: string): string => {
  // 協力会社利用可能フラグが利用不可の場合、利用権限はすべてなしとします。
  if ("0" === useInfo.partnerAvailableFlg) {
    return "0";
  }

  return getAuthFlg(useInfo.authorityInfo, authKbn);
}

// APIレスポンス.権限情報から権限フラグを取得します。
const getAuthFlg = (authorities: IAuthority[], authKbn: string): string => {

  let authority = authorities.find((authority: IAuthority) => authority.authKbn === authKbn)

  if (typeof authority !== "undefined") {
    return authority.authFlg
  }

  return "0";
};

// state参照
export const storedLoginUserInfo = (state: RootState) => state.userInfo.loginUserInfo;
export const storedRedirectUrl = (state: RootState) => state.userInfo.redirectUrl

export default userInfoSlice.reducer;
